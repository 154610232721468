var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{ref:"layout",attrs:{"showback":false},on:{"show":function($event){return _vm.$emit('show')},"back":function($event){_vm.$refs.crud.acao = 'list'}}},[_c('template',{slot:"body"},[_c('Crud',{ref:"crud",attrs:{"path":_vm.config.path,"novo":_vm.config.novo,"list":_vm.config.list,"form":_vm.config.form,"datatable":_vm.datatable},on:{"list":function($event){return _vm.$refs.layout.back(false)},"edit":function($event){_vm.nav = 'conta';
        _vm.model = $event;
        _vm.$refs.layout.back(true);},"clone":function($event){_vm.model = $event;
        _vm.model.id = null;},"novo":function($event){_vm.nav = 'conta';
        _vm.model = {};
        _vm.$refs.layout.back(true);},"save":_vm.save,"create":_vm.create,"fail":_vm.setErros}},[_c('template',{slot:"form"},[_c('div',{staticClass:"row"},[_c('Texto',{ref:"nome",attrs:{"labelini":"Nome *","valueini":_vm.model.nome},on:{"change":function($event){_vm.model.nome = $event}}}),_c('Autocomplete',{ref:"cliente_id",attrs:{"labelini":"Cliente *","valueini":_vm.model.cliente_id,"path":"cliente","characteres":1,"campo":['nome', { valor: 'id', numerico: true }, 'apelido'],"btnnovo":true,"cast":function (ob) {
                return (
                  ob.nome +
                  ' - ' +
                  (ob.cpf_cnpj != null ? ob.cpf_cnpj : '...') +
                  ' - ' +
                  (ob.tipo == 'F' ? 'Física' : 'Júridica')
                );
              }},on:{"change":function($event){_vm.model.cliente_id = $event}}},[_c('template',{slot:"novo"},[_c('Cliente',{attrs:{"formonly":true},on:{"closeform":_vm.novoCliente}})],1)],2),_c('TextoArea',{ref:"descricao",attrs:{"labelini":"Descrição","valueini":_vm.model.descricao},on:{"change":function($event){_vm.model.descricao = $event}}}),_c('div',{staticClass:"col-md-6 col-12 mb-3"},[_c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-6 d-flex"},[_c('button',{staticClass:"app-button app-button-secondary m-auto w-75",on:{"click":function($event){return _vm.$refs.conteudo.copy()}}},[_vm._v(" Copiar ")])]),_c('div',{staticClass:"col-6 d-flex"},[_c('button',{staticClass:"app-button app-button-secondary m-auto w-75",on:{"click":function($event){return _vm.$refs.conteudo.pdf('Proposta Manual')}}},[_vm._v(" PDF ")])])])]),_c('Editor',{ref:"conteudo",attrs:{"valueini":_vm.model.conteudo},on:{"change":function($event){_vm.model.conteudo = $event}}})],1)])],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }