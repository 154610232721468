<template>
  <div :class="(css ? css : 'col-12 col-md-6 mb3')">
    <div class="form-group">
      <label v-if="label">{{ label }}</label>
      <textarea
        :cols="cols"
        :rows="rows"
        disabled
        class="form-control"
        style="margin-width: auto;"
        v-model="value"
        @keyup.enter="$event.target.blur()"
      ></textarea>
      <small
        class="form-text text-danger d-block"
        v-for="(e, i) in erro"
        :key="i"
        >{{ e }}</small
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["colsini", "rowsini", "labelini", "valueini", "css"],
  data() {
    return {
      label: this.labelini,
      value: this.valueini,
      erro: null,
      type: this.typeini,
      cols: this.colsini ? this.colsini : null,
      rows: this.rowsini ? this.rowsini : 4
    };
  },
  watch: {
    valueini(v){
      this.value = v;
    },
    value(v) {
      if (v == "") {
        v = null;
      }
      this.$emit("change", v);
    },
  },
};
</script>