<template>
  <Report
    title="Relatório de Faturamento"
    path="/relatorio/faturamento"
    :model="model"
  >
    <template slot="form">
      <Data
        labelini="Data de Aprovação Inicial"
        :valueini="model.dt_aprovacao_inicial"
        @change="model.dt_aprovacao_inicial = $event"
      />

      <Data
        labelini="Data de Aprovação Final"
        :valueini="model.dt_aprovacao_final"
        @change="model.dt_aprovacao_final = $event"
      />

      <Data
        labelini="Data de Faturamento Inicial"
        :valueini="model.dt_faturamento_inicial"
        @change="model.dt_faturamento_inicial = $event"
      />

      <Data
        labelini="Data de Faturamento Final"
        :valueini="model.dt_faturamento_final"
        @change="model.dt_faturamento_final = $event"
      />

      <Select
        css="col-6"
        labelini="Faturado"
        :valueini="model.faturado"
        :valuesini="{ S: 'Sim', N: 'Não' }"
        vazio="Todos"
        @change="model.faturado = $event"
      />
    </template>
  </Report>
</template>

<script>
import Select from "../../components/crud/input/Select.vue";
import Data from "../../components/crud/input/Data.vue";
import Report from "../../components/report/Report.vue";

export default {
  components: {
    Report,
    Select,
    Data,
  },
  data() {
    return {
      model: {},
    };
  },
};
</script>