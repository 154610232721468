var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('h4',[_vm._v("Condições de pagamento")]),_c('div',{staticClass:"col-12 mb-4"},[_c('table',{staticClass:"table table-bordered"},[_c('tbody',[_c('tr',[_vm._m(0),_c('td',{staticStyle:{"width":"50%","text-align":"right"}},[_c('b',[_vm._v("R$ "+_vm._s(_vm.servicos.toFixed(2)))])])]),_c('tr',[_vm._m(1),_c('td',{staticStyle:{"width":"50%","text-align":"right"}},[_c('b',[_vm._v("R$ "+_vm._s(_vm.taxas.toFixed(2)))])])]),_c('tr',[_vm._m(2),_c('td',{staticStyle:{"width":"50%","text-align":"right"}},[_c('b',[_vm._v("R$ "+_vm._s(_vm.total.toFixed(2)))])])])])])]),_c('h4',{staticClass:"mt-4"},[_vm._v("Entrada")]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row mx-0 mb-2"},[_c('Money',{ref:"entrada",staticStyle:{"padding-left":"0px"},attrs:{"css":"col-6","labelini":"Valor da entrada","valueini":_vm.entrada,"changeeventini":true},on:{"change":function($event){_vm.entrada = $event}}}),_c('Select',{ref:"forma_pagamento_id",staticStyle:{"padding-right":"0px"},attrs:{"css":"col-6","labelini":"Forma de pagamento","path":"/formas-pagamento","campo":"nome","vazio":"Selecione"},on:{"change":function($event){_vm.formaPagamento = $event}}})],1)]),_c('h4',{staticClass:"mt-4"},[_vm._v("Condições de parcelamento")]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row mx-0 mb-2"},[_c('Select',{ref:"parcela_sel",staticStyle:{"padding-left":"0px"},attrs:{"css":"col-6","labelini":"Quantidade de parcelas *","valueini":_vm.quantidadeParcelas,"valuesini":{
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
          7: '7',
          8: '8',
          9: '9',
          10: '10',
          11: '11',
          12: '12',
        }},on:{"change":function($event){_vm.quantidadeParcelas = $event}}}),_c('Texto',{staticStyle:{"padding-right":"0px"},attrs:{"css":"col-6","labelini":"Dia do vencimento das parcelas *","typeini":"number","valueini":10},on:{"change":function($event){_vm.dia = $event}}})],1)]),_c('h4',{staticClass:"mt-4"},[_vm._v("Parcelas")]),_c('div',{staticClass:"col-12"},[_c('table',{staticClass:"table table-bordered"},[_vm._m(3),_c('tbody',_vm._l((_vm.parcelas),function(p,i){return _c('tr',{key:i},[_c('td',[_c('Money',{ref:"parcela",refInFor:true,attrs:{"css":"w-100","valueini":p.valor,"changeeventini":true},on:{"change":function($event){p.valor = $event;
                p.vl_taxa = _vm.taxas;
                p.vl_servico = _vm.servicos;
                p.vl_total = _vm.taxas + _vm.servicos;
                _vm.calcularTotal();}}})],1),_c('td',[_c('Data',{ref:"vencimento",refInFor:true,attrs:{"css":"w-100","showsum":true,"valueini":p.vencimento},on:{"change":function($event){p.vencimento = $event}}})],1),_c('td',[_c('Select',{ref:"forma_pagamento",refInFor:true,attrs:{"id":'forma_pagamento_' + i,"css":"w-100","valueini":p.forma_pagamento_id,"path":"/formas-pagamento","campo":"nome","vazio":"Selecione"},on:{"change":function($event){p.forma_pagamento_id = $event},"changeObject":function($event){p.forma_pagamento = $event}}})],1)])}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticStyle:{"width":"50%"}},[_c('span',[_vm._v("Valor dos serviços")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticStyle:{"width":"50%"}},[_c('span',[_vm._v("Valor das taxas")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticStyle:{"width":"50%"}},[_c('span',[_vm._v("Valor total")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('th',[_vm._v(" Valor ")]),_c('th',{staticStyle:{"width":"33.33%"}},[_vm._v(" Vencimento ")]),_c('th',{staticStyle:{"width":"33.33%"}},[_vm._v(" Forma de pagamento ")])])}]

export { render, staticRenderFns }