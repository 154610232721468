<template>
  <div :class="'notification' + (text ? ' show' : '')" :style="style">
    <span v-if="text"><i :class="icon"></i> {{ text }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
      color: null,
      font: null,
      text: null,
      icon: null,
    };
  },
  methods: {
    success(msg, timer = 3000) {
      this.show(msg, "fas fa-check", timer, "#449e3a", "#fff");
    },
    erro(msg, timer = 3000) {
      this.show(msg, "fas fa-times", timer, "#b83737", "#fff");
    },
    alerta(msg, timer = 3000) {
      this.show(msg, "fas fa-exclamation-triangle", timer, "#d6bc38", "#fff");
    },
    show(msg, icon, timer, color, font) {
      setTimeout(() => {
        this.text = msg;
        this.color = color;
        this.font = font;
        this.icon = icon;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.text = null;
          this.timer = null;
        }, timer);
      }, 100);
    },
  },
  computed: {
    style() {
      return (
        (this.color ? "background-color: " + this.color + ";" : "") +
        (this.font ? "color: " + this.font + ";" : "")
      );
    },
  },
  created() {
    // this.alerta("Registro salvo com sucesso!", 3000);
    // setTimeout(() => {
    //   this.success("Registro salvo com sucesso!", 3000);
    // }, 5000);
  },
};
</script>

<style>
.notification {
  position: fixed;
  background-color: #000;
  right: 50px;
  bottom: 15px;
  border-radius: 5px;
  color: #fff;
  display: none;
  padding: 12px;
  opacity: 0;
  z-index: 9999;
}

.notification.show {
  display: block;
  opacity: 1;
  animation: notification 0.3s forwards;
}

@keyframes notification {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.notification span {
  font-size: 18px;
}
@media (max-width: 600px) {
  .notification{
    top: 85px;
    right: 10px;
    bottom: unset;
  }
}
</style>