<template>
  <div :class="(css ? css : 'col-12 col-md-6') + ' mb-3'">
    <div class="form-group">
      <div class="text-center">
        <label>{{ label }}</label>
      </div>
      <div class="text-center" v-show="!value">
        <button class="favorito-button" @click="value = true">
          <i class="far fa-star"></i>
        </button>
      </div>
      <div class="text-center" v-show="value">
        <button class="favorito-button" @click="value = false">
          <i class="fas fa-star"></i>
        </button>
      </div>
      <small
        class="form-text text-danger d-block"
        v-for="(e, i) in erro"
        :key="i"
        >{{ e }}</small
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["typeini", "labelini", "valueini", "css"],
  data() {
    return {
      label: this.labelini,
      value: this.valueini,
      erro: null,
      type: this.typeini,
    };
  },
  watch: {
    value(v) {
      this.$emit("change", v);
    },
  },
};
</script>

<style>
.favorito-button {
  outline: none;
  background-color: transparent;
  border: 0px;
  padding: 0px;
  font-size: 22px;
  color: #ccc90d;
}
</style>