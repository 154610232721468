<template>
  <Layout @show="$emit('show')">
    <template slot="body">
      <div style="display: grid; grid-template-columns: 200px calc(100% - 400px) 200px;">
        <div>
        </div>
        <div>
          <h1 class="m-3 text-center">Notificações</h1>
        </div>
        <div style="display: flex;">
          <button
            type="button"
            class="btn btn-primary my-auto ml-auto"
            @click="showModal()"
          >
            <i class="fas fa-plus"></i>
            Nova Notificação
          </button>
        </div>
      </div>
      <div class="ibox">
        <div class="ibox-content">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th style="width: 200px;">
                  Data
                </th>
                <th>
                  Descrição
                </th>
                <th style="width: 400px;">
                  Cliente
                </th>
                <th style="width: 120px; text-align: center;">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(noti, i) in notificacoes" :key="i">
                <td>
                  {{ (noti.dt_notificacao ? noti.dt_notificacao : "") + (noti.hr_notificacao ? " " + noti.hr_notificacao : "") }}
                </td>
                <td>
                  {{ noti.descricao }}
                </td>
                <td>
                  {{ noti.cliente_id ? noti.cliente_id + " - " + noti.cliente.nome : "" }}
                </td>
                <td>
                  <div style="display: grid; grid-template-columns: 40px 40px 40px;">
                    <div
                      style="cursor: pointer; text-align: center;"
                      @click="concluirNotificacao(noti.id);">
                      <i style="color: green;" class="fas fa-check" title="Concluir"></i>
                    </div>
                    <div
                      style="cursor: pointer; text-align: center;"
                      @click="showModal(noti.id)"
                    >
                      <i class="fas fa-edit edit-button" title="Editar"></i>
                    </div>
                    <div
                      style="cursor: pointer; text-align: center;"
                      @click="
                        notificacao_id_delete = noti.id;
                        confirmarExclusaoNotificacao();
                      ">
                      <i class="fas fa-trash delete-button" title="Excluir"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="notificacoes.length == 0">
            <h4 class="text-center">Nenhum registro encontrado</h4>
          </div>
        </div>
      </div>

      <h1 class="mt-5 mb-3 text-center">Tarefas</h1>

      <div ref="tarefas" class="ibox">
        <div class="ibox-content">
          <Collapse
            ref="aguardando"
            @open="openAguardando()"
          >
            <template slot="head">
              <div class="text-center">
                <h3 style="color: gray; margin-bottom: unset;" class="pt-1 pb-1">Aguardando - {{ getAguardando.length }}</h3>
              </div>
            </template>
            <template slot="body">
              <hr class="mt-3">

              <table class="table table-striped table-aguardando" style="table-layout: fixed">
                <thead>
                  <tr>
                    <th
                      :style="'width: 100px; cursor: pointer; ' + (orderField == 'ticket' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('ticket')"
                    >
                      Ticket
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th
                      :style="'width: calc(34% - 340px); cursor: pointer; ' + (orderField == 'nome_cliente' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('nome_cliente')"
                    >
                      Cliente
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th 
                      :style="'width: calc(34% - 340px); cursor: pointer; ' + (orderField == 'tarefa' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('tarefa')"
                    >
                      Tarefa
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th style="width: calc(33% - 340px)">
                      Observação
                    </th>
                    <th style="width: 100px; text-align: center;">
                      Ações
                    </th>
                    <th style="width: 140px;">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tarefa,i in getAguardando" :key="i">
                    <td>
                      {{ tarefa.ticket }}
                    </td>
                    <td>
                      {{ tarefa.cliente }}
                    </td>
                    <td>
                      {{ tarefa.tarefa }}
                    </td>
                    <td>
                      {{ tarefa.observacao }}
                    </td>
                    <td>
                      <div class="action d-flex justify-content-center">
                        <div v-if="administrador" title="Visualizar" @click="visualizar(tarefa.processo_id)">
                          <i class="fas fa-search"></i>
                        </div>
                        <div :title="tarefa.url" v-if="tarefa.url" @click="openUrl(tarefa)">
                          <i class="fas fa-link"></i>
                        </div>
                        <div :title="tarefa.arquivo" v-if="tarefa.arquivo" @click="openArquivo(tarefa)">
                          <i class="fas fa-file"></i>
                        </div>
                        <div 
                          title="Upload"
                          @click="openModalUpload(tarefa);"
                        >
                          <i class="fas fa-upload"></i>
                        </div>
                      </div>
                    </td>
                    <td>
                      <select
                        v-if="tarefa.processo_servico_id"
                        class="painel-status"
                        v-model="tarefa.status_servico"
                        :style="'color: '+ getColor(tarefa.status_servico)"
                        @change="salvarServico($event.target.value, tarefa)">
                        <option
                          v-for="(st, sti) in status"
                          :key="sti"
                          :value="st.id"
                          :style="'color: ' + st.color">
                          {{ st.nome }}
                        </option> 
                      </select>

                      <select
                        v-if="tarefa.processo_pergunta_id"
                        class="painel-status"
                        v-model="tarefa.status_pergunta"
                        :style="'color: '+ getColor(tarefa.status_pergunta)"
                        @change="salvarPergunta($event.target.value, tarefa)">
                        <option
                          v-for="(st, sti) in status"
                          :key="sti"
                          :value="st.id"
                          :style="'color: ' + st.color">
                          {{ st.nome }}
                        </option> 
                      </select>
                    </td>
                  </tr>
                  <tr v-if="getAguardando.length == 0">
                    <td colspan="6" style="text-align: center">
                      Nenhum registro encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Collapse>
        </div>
      </div>

      <div ref="tarefas" class="ibox">
        <div class="ibox-content">
          <Collapse
            ref="tramitando"
            @open="openTramitando()"
          >
            <template slot="head">
              <div class="text-center">
                <h3 style="color: blue; margin-bottom: unset;" class="pt-1 pb-1">Tramitando - {{ getTramitando.length }}</h3>
              </div>
            </template>
            <template slot="body">
              <hr class="mt-3">

              <table class="table table-striped table-tramitando" style="table-layout: fixed">
                <thead>
                  <tr>
                    <th
                      :style="'width: 100px; cursor: pointer; ' + (orderField == 'ticket' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('ticket')"
                    >
                      Ticket
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th
                      :style="'width: calc(34% - 340px); cursor: pointer; ' + (orderField == 'nome_cliente' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('nome_cliente')"
                    >
                      Cliente
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th 
                      :style="'width: calc(34% - 340px); cursor: pointer; ' + (orderField == 'tarefa' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('tarefa')"
                    >
                      Tarefa
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th style="width: calc(33% - 340px)">
                      Observação
                    </th>
                    <th style="width: 100px; text-align: center;">
                      Ações
                    </th>
                    <th style="width: 140px;">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tarefa,i in getTramitando" :key="i">
                    <td>
                      {{ tarefa.ticket }}
                    </td>
                    <td>
                      {{ tarefa.cliente }}
                    </td>
                    <td>
                      {{ tarefa.tarefa }}
                    </td>
                    <td>
                      {{ tarefa.observacao }}
                    </td>
                    <td>
                      <div class="action d-flex justify-content-center">
                        <div v-if="administrador" title="Visualizar" @click="visualizar(tarefa.processo_id)">
                          <i class="fas fa-search"></i>
                        </div>
                        <div :title="tarefa.url" v-if="tarefa.url" @click="openUrl(tarefa)">
                          <i class="fas fa-link"></i>
                        </div>
                        <div :title="tarefa.arquivo" v-if="tarefa.arquivo" @click="openArquivo(tarefa)">
                          <i class="fas fa-file"></i>
                        </div>
                        <div 
                          title="Upload"
                          @click="openModalUpload(tarefa);"
                        >
                          <i class="fas fa-upload"></i>
                        </div>
                      </div>
                    </td>
                    <td>
                      <select
                        v-if="tarefa.processo_servico_id"
                        class="painel-status"
                        v-model="tarefa.status_servico"
                        :style="'color: '+ getColor(tarefa.status_servico)"
                        @change="salvarServico($event.target.value, tarefa)">
                        <option
                          v-for="(st, sti) in status"
                          :key="sti"
                          :value="st.id"
                          :style="'color: ' + st.color">
                          {{ st.nome }}
                        </option> 
                      </select>

                      <select
                        v-if="tarefa.processo_pergunta_id"
                        class="painel-status"
                        v-model="tarefa.status_pergunta"
                        :style="'color: '+ getColor(tarefa.status_pergunta)"
                        @change="salvarPergunta($event.target.value, tarefa)">
                        <option
                          v-for="(st, sti) in status"
                          :key="sti"
                          :value="st.id"
                          :style="'color: ' + st.color">
                          {{ st.nome }}
                        </option> 
                      </select>
                    </td>
                  </tr>
                  <tr v-if="getTramitando.length == 0">
                    <td colspan="6" style="text-align: center">
                      Nenhum registro encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Collapse>
        </div>
      </div>

      <div ref="tarefas" class="ibox">
        <div class="ibox-content">
          <Collapse
            ref="pendente"
            @open="openPendente()"
          >
            <template slot="head">
              <div class="text-center">
                <h3 style="color: red; margin-bottom: unset;" class="pt-1 pb-1">Pendente - {{ getPendente.length }}</h3>
              </div>
            </template>
            <template slot="body">
              <hr class="mt-3">

              <table class="table table-striped table-pendente" style="table-layout: fixed">
                <thead>
                  <tr>
                    <th
                      :style="'width: 100px; cursor: pointer; ' + (orderField == 'ticket' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('ticket')"
                    >
                      Ticket
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th
                      :style="'width: calc(34% - 340px); cursor: pointer; ' + (orderField == 'nome_cliente' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('nome_cliente')"
                    >
                      Cliente
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th 
                      :style="'width: calc(34% - 340px); cursor: pointer; ' + (orderField == 'tarefa' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('tarefa')"
                    >
                      Tarefa
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th style="width: calc(33% - 340px)">
                      Observação
                    </th>
                    <th style="width: 100px; text-align: center;">
                      Ações
                    </th>
                    <th style="width: 140px;">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tarefa,i in getPendente" :key="i">
                    <td>
                      {{ tarefa.ticket }}
                    </td>
                    <td>
                      {{ tarefa.cliente }}
                    </td>
                    <td>
                      {{ tarefa.tarefa }}
                    </td>
                    <td>
                      {{ tarefa.observacao }}
                    </td>
                    <td>
                      <div class="action d-flex justify-content-center">
                        <div v-if="administrador" title="Visualizar" @click="visualizar(tarefa.processo_id)">
                          <i class="fas fa-search"></i>
                        </div>
                        <div :title="tarefa.url" v-if="tarefa.url" @click="openUrl(tarefa)">
                          <i class="fas fa-link"></i>
                        </div>
                        <div :title="tarefa.arquivo" v-if="tarefa.arquivo" @click="openArquivo(tarefa)">
                          <i class="fas fa-file"></i>
                        </div>
                        <div 
                          title="Upload"
                          @click="openModalUpload(tarefa);"
                        >
                          <i class="fas fa-upload"></i>
                        </div>
                      </div>
                    </td>
                    <td>
                      <select
                        v-if="tarefa.processo_servico_id"
                        class="painel-status"
                        v-model="tarefa.status_servico"
                        :style="'color: '+ getColor(tarefa.status_servico)"
                        @change="salvarServico($event.target.value, tarefa)">
                        <option
                          v-for="(st, sti) in status"
                          :key="sti"
                          :value="st.id"
                          :style="'color: ' + st.color">
                          {{ st.nome }}
                        </option> 
                      </select>

                      <select
                        v-if="tarefa.processo_pergunta_id"
                        class="painel-status"
                        v-model="tarefa.status_pergunta"
                        :style="'color: '+ getColor(tarefa.status_pergunta)"
                        @change="salvarPergunta($event.target.value, tarefa)">
                        <option
                          v-for="(st, sti) in status"
                          :key="sti"
                          :value="st.id"
                          :style="'color: ' + st.color">
                          {{ st.nome }}
                        </option> 
                      </select>
                    </td>
                  </tr>
                  <tr v-if="getPendente.length == 0">
                    <td colspan="6" style="text-align: center">
                      Nenhum registro encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Collapse>
        </div>
      </div>

      <div ref="tarefas" class="ibox">
        <div class="ibox-content">
          <Collapse
            ref="concluida"
            @open="openConcluida()"
          >
            <template slot="head">
              <div class="text-center">
                <h3 style="color: green; margin-bottom: unset;" class="pt-1 pb-1">Concluída - {{ getConcluida.length }}</h3>
              </div>
            </template>
            <template slot="body">
              <hr class="mt-3">

              <table class="table table-striped table-concluida" style="table-layout: fixed">
                <thead>
                  <tr>
                    <th
                      :style="'width: 100px; cursor: pointer; ' + (orderField == 'ticket' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('ticket')"
                    >
                      Ticket
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th
                      :style="'width: calc(34% - 340px); cursor: pointer; ' + (orderField == 'nome_cliente' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('nome_cliente')"
                    >
                      Cliente
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th 
                      :style="'width: calc(34% - 340px); cursor: pointer; ' + (orderField == 'tarefa' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('tarefa')"
                    >
                      Tarefa
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th style="width: calc(33% - 340px)">
                      Observação
                    </th>
                    <th style="width: 100px; text-align: center;">
                      Ações
                    </th>
                    <th style="width: 140px;">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tarefa,i in getConcluida" :key="i">
                    <td>
                      {{ tarefa.ticket }}
                    </td>
                    <td>
                      {{ tarefa.cliente }}
                    </td>
                    <td>
                      {{ tarefa.tarefa }}
                    </td>
                    <td>
                      {{ tarefa.observacao }}
                    </td>
                    <td>
                      <div class="action d-flex justify-content-center">
                        <div v-if="administrador" title="Visualizar" @click="visualizar(tarefa.processo_id)">
                          <i class="fas fa-search"></i>
                        </div>
                        <div :title="tarefa.url" v-if="tarefa.url" @click="openUrl(tarefa)">
                          <i class="fas fa-link"></i>
                        </div>
                        <div :title="tarefa.arquivo" v-if="tarefa.arquivo" @click="openArquivo(tarefa)">
                          <i class="fas fa-file"></i>
                        </div>
                        <div 
                          title="Upload"
                          @click="openModalUpload(tarefa);"
                        >
                          <i class="fas fa-upload"></i>
                        </div>
                      </div>
                    </td>
                    <td>
                      <select
                        v-if="tarefa.processo_servico_id"
                        class="painel-status"
                        v-model="tarefa.status_servico"
                        :style="'color: '+ getColor(tarefa.status_servico)"
                        @change="salvarServico($event.target.value, tarefa)">
                        <option
                          v-for="(st, sti) in status"
                          :key="sti"
                          :value="st.id"
                          :style="'color: ' + st.color">
                          {{ st.nome }}
                        </option> 
                      </select>

                      <select
                        v-if="tarefa.processo_pergunta_id"
                        class="painel-status"
                        v-model="tarefa.status_pergunta"
                        :style="'color: '+ getColor(tarefa.status_pergunta)"
                        @change="salvarPergunta($event.target.value, tarefa)">
                        <option
                          v-for="(st, sti) in status"
                          :key="sti"
                          :value="st.id"
                          :style="'color: ' + st.color">
                          {{ st.nome }}
                        </option> 
                      </select>
                    </td>
                  </tr>
                  <tr v-if="getConcluida.length == 0">
                    <td colspan="6" style="text-align: center">
                      Nenhum registro encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Collapse>
        </div>
      </div>

      <div
        class="modal inmodal fade"
        tabindex="-1"
        role="dialog"
        ref="modal_notificacao"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content" style="max-height: 90vh;">
            <div class="modal-header">
              <h3 class="modal-title">Notificação</h3>
            </div>
            <div class="modal-body overflow-auto">
              <Texto
                :columnGroup="1"
                ref="descricao"
                labelini="Descrição *"
                :valueini="notificacao.descricao"
                @change="notificacao.descricao = $event"
              />

              <Data
                :columnGroup="1"
                ref="dt_notificacao"
                labelini="Data de Início"
                :valueini="notificacao.dt_notificacao"
                @change="notificacao.dt_notificacao = $event"
              />

              <Hora
                :columnGroup="1"
                ref="hr_notificacao"
                labelini="Hora de Início"
                :valueini="notificacao.hr_notificacao"
                @change="notificacao.hr_notificacao = $event"
              />

              <TextoArea
                css="col-12 mb-2"
                rows="1"
                ref="observacao"
                labelini="Observação"
                :valueini="notificacao.observacao"
                @change="notificacao.observacao = $event"
              />

              <Autocomplete
                id="cliente_id"
                ref="cliente_id"
                labelini="Cliente"
                :valueini="notificacao.cliente_id"
                path="/clientes"
                :characteres="1"
                :campo="[
                  'nome',
                  { valor: 'id', numerico: true }
                ]"
                @change="notificacao.cliente_id = $event"
                :cast="
                  (ob) => {
                    return (
                      ob.nome +
                      ' - ' +
                      (ob.cpf_cnpj != null ? ob.cpf_cnpj : '...') +
                      ' - Pessoa ' +
                      (ob.tipo == 'F' ? 'Física' : 'Júridica')
                    );
                  }
                "
              >
              </Autocomplete>

              <Select
                columnGroup="1"
                v-if="notificacao.id"
                ref="status_notificacao"
                labelini="Status"
                :valueini="notificacao.status"
                :valuesini="statusNotificacao"
                @change="notificacao.status = $event"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                v-if="notificacao.id"
                class="btn btn-danger"
                data-dismiss="modal"
                @click="
                  notificacao_id_delete = notificacao.id;
                  confirmarExclusaoNotificacao();
                "
              >
                Excluir
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                @click="hideModal()"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="salvarNotificacao(notificacao)"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        ref="modal_visualizar"
        aria-hidden="true"
      >
        <div style="width: 80%; max-width: none;" class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">Visualizar</h3>
            </div>
            <div class="modal-body" v-if="modelView.id">
              <ResumoProcesso :processo="modelView" />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="app-button app-button-secondary"
                data-dismiss="modal"
                @click="fecharVisualizacao"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        ref="modalUpload"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Upload de Arquivos
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="closeModalUpload"
              >
                <span aria-hidden="true" style="font-size: 30px">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <Texto
                :columnGroup="1"
                ref="linkServico"
                labelini="Link"
                :valueini="servicoPerguntaUpload.url"
                @change="servicoPerguntaUpload.url = $event"
              />

              <div class="row col-12">
                <input id="input-arquivo" ref="file" type="file" @change="uploadFile" class="col-12 mb-3">
              </div>
              <div class="row col-12" v-if="servicoPerguntaUpload.arquivo">
                <button @click="removerArquivoServicoPergunta()" type="button" style="margin-left: 15px;" class="btn btn-danger">
                  Excluir arquivo
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeModalUpload()">
                Cancelar
              </button>
              <button type="button" class="btn btn-primary" @click="uploadArquivoServicoPergunta()">
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Texto from "../../components/crud/input/Text.vue";
import TextoArea from "../../components/crud/input/TextArea.vue";
import Select from "../../components/crud/input/Select.vue";
import Data from "../../components/crud/input/Data.vue";
import Hora from "../../components/crud/input/Hora.vue";
import Layout from "../../components/layout/Layout.vue";
import Card from "../../components/painel/card/Simples.vue";
import Autocomplete from "../../components/crud/input/Autocomplete.vue";
import Cliente from "../../components/cadastro/Cliente.vue";
import Collapse from "../../components/crud/input/Collapse.vue";
import ResumoProcesso from "../../components/custom/ResumoProcesso.vue";

export default {
  components: {
    Card,
    Layout,
    Texto,
    TextoArea,
    Select,
    Data,
    Hora,
    Autocomplete,
    Cliente,
    Collapse,
    ResumoProcesso
  },
  data() {
    return {
      administrador: false,
      dados: {},
      novo:false,
      notificacoes: [],
      tarefas: [],
      status: [],
      modelView: {},
      servicoPerguntaUpload: {},
      notificacao_id_delete: null,
      pendente_key: 1,
      orderField: "nome_cliente",
      orderType: "ASC",
      statusNotificacao: {
        PENDENTE: "Pendente",
        CONCLUIDA: "Concluída"
      },
      notificacao:{
        id: null,
        descricao: null, 
        dt_notificacao: null, 
        hr_notificacao: null, 
        status: null, 
        obsercacao: null,
        cliente_id: null
      },
    };
  },
  computed: {
    getAguardando() {
      var result = [];

      for (const tarefa of this.tarefas) {
        if (tarefa.status_servico == 1 || tarefa.status_pergunta == 1) {
          result.push(tarefa);
        }
      }

      result.sort(this.ordenarTarefas);

      return result;
    },
    getTramitando() {
      var result = [];

      for (const tarefa of this.tarefas) {
        if (tarefa.status_servico == 2 || tarefa.status_pergunta == 2) {
          result.push(tarefa);
        }
      }

      result.sort(this.ordenarTarefas);

      return result;
    },
    getPendente() {
      var result = [];
    
      for (const tarefa of this.tarefas) {
        if (tarefa.status_servico == 3 || tarefa.status_pergunta == 3) {
          result.push(tarefa);
        }
      }

      result.sort(this.ordenarTarefas);
    
      return result;
    },
    getConcluida() {
      var result = [];

      for (const tarefa of this.tarefas) {
        if (tarefa.status_servico == 4 || tarefa.status_pergunta == 4) {
          result.push(tarefa);
        }
      }

      result.sort(this.ordenarTarefas);

      return result;
    }
  },
  created () {
    this.buscarPermissao();
    this.buscarNotificacoes();
    this.buscarStatus();
    this.buscarTarefas();
  },
  methods: {
    buscarPermissao() {
      callAPI(
        '/has-role',
        'GET',
        { role: 'administrador'},
        (e) => {
          this.administrador = e.hasRole;
        }
      );
    },
    openModalUpload(servicoPergunta) {
      this.servicoPerguntaUpload = servicoPergunta;
      $(this.$refs.modalUpload).modal("show");
    },
    closeModalUpload() {
      this.servicoPerguntaUpload = {};
      this.$refs.file.value = '';
      $(this.$refs.modalUpload).modal("hide");
    },
    uploadFile() {
      this.servicoPerguntaUpload.file = this.$refs.file.files[0];
    },
    uploadArquivoServicoPergunta() {
      const formData = new FormData();

      if (this.servicoPerguntaUpload.file != null) {
        formData.append('file', this.servicoPerguntaUpload.file);
      }

      formData.append('params', JSON.stringify({ url: this.servicoPerguntaUpload.url }));
      
      formData.append(
        'id',
        this.servicoPerguntaUpload.processo_servico_id ?
        this.servicoPerguntaUpload.processo_servico_id :
        this.servicoPerguntaUpload.processo_pergunta_id
      );

      fileUpload(
        this.servicoPerguntaUpload.processo_servico_id ? '/processo-servico' : '/processo-pergunta',
        'POST',
        formData,
        (e) => {
          this.buscarTarefas();
          this.closeModalUpload();
        },
      );
    },
    removerArquivoServicoPergunta() {
      callAPI(
        this.servicoPerguntaUpload.processo_servico_id ?
        '/processo-servico-remover-arquivo/' + this.servicoPerguntaUpload.processo_servico_id :
        '/processo-pergunta-remover-arquivo/' + this.servicoPerguntaUpload.processo_pergunta_id,
        'PUT',
        null,
        (data)=>{
          this.buscarTarefas();
          this.closeModalUpload();
        }
      );
    },
    openUrl(tarefa) {
      window.open(tarefa.url, '_blank');
    },
    openArquivo(tarefa) {
      window.open(
        loadRecurso((tarefa.processo_servico_id ? "/storage/servicos/" : "/storage/perguntas/") + tarefa.processo_id + "/" + tarefa.arquivo),
        '_blank'
      );
    },
    ordenar(orderField) {
      if (this.orderField == orderField) {
        this.orderType = this.orderType == 'ASC' ? 'DESC' : 'ASC';
      }

      this.orderField = orderField;
    },
    ordenarTarefas(a,b) {
      let x = a[this.orderField].toLowerCase();
      let y = b[this.orderField].toLowerCase();

      if (this.orderType == "ASC") {
        if (x < y) {return -1;}
        if (x > y) {return 1;}
      } else {
        if (x < y) {return 1;}
        if (x > y) {return -1;}
      }

      return 0;
    },
    openPendente() {
      this.$refs.tramitando.fechar();
      this.$refs.aguardando.fechar();
      this.$refs.concluida.fechar();
    },
    openTramitando() {
      this.$refs.pendente.fechar();
      this.$refs.aguardando.fechar();
      this.$refs.concluida.fechar();
    },
    openAguardando() {
      this.$refs.pendente.fechar();
      this.$refs.tramitando.fechar();
      this.$refs.concluida.fechar();
    },
    openConcluida() {
      this.$refs.pendente.fechar();
      this.$refs.tramitando.fechar();
      this.$refs.aguardando.fechar();
    },
    showModal(id){
      if (id != null) {
        this.buscarNotificacao(id);
      } else {
        this.notificacao.id = null;
        this.notificacao.descricao = null;
        this.notificacao.dt_notificacao = null;
        this.notificacao.hr_notificacao = null;
        this.notificacao.observacao = null;
        this.notificacao.cliente_id = null;

        $(this.$refs.modal_notificacao).modal('show');
      }
    },
    hideModal(){
      $(this.$refs.modal_notificacao).modal('hide');
    },
    getColor(s) {
      for (const st of this.status) {
        if (st.id == s) {
          return st.color;
        }
      }
    },
    confirmarExclusaoNotificacao() {
      var _this = this;
      swal({
        title: "Deseja realmente deletar a notificação?",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#ED5565",
        confirmButtonText: "Deletar",
      }, function () {
        _this.excluirNotificacao();
      });

      $('.sweet-alert button.cancel').css('background-color','#5a6268');
    },
    excluirNotificacao() {
      callAPI(
        '/notificacoes/' + this.notificacao_id_delete,
        'DELETE',
        null,
        (e) => {
          this.buscarNotificacoes();
        }
      );
    },
    concluirNotificacao(notificacao_id) {
      callAPI(
        '/concluir-notificacao/' + notificacao_id,
        'PUT',
        null,
        (e) => {
          this.buscarNotificacoes();
          swal({
            title: "Notificação concluída!",
            type: "success",
            confirmButtonColor: "#1ab394",
          });
        }
      );
    },
    salvarNotificacao(data) {
      if (data.id) {
        callAPI(
          '/notificacoes/' + data.id,
          'PUT',
          data,
          (e) => {
            this.hideModal();
            this.buscarNotificacoes();
          }
        );
      } else {
        callAPI(
          '/notificacoes',
          'POST',
          data,
          (e) => {
            this.hideModal();
            this.buscarNotificacoes();
          }
        );
      }
    },
    buscarNotificacao(id){
      callAPI(
        '/notificacoes/' + id,
        'GET',
        null,
        (e) => {
          this.notificacao = e;
          $(this.$refs.modal_notificacao).modal('show');
        }
      );
    },
    buscarNotificacoes() {
      callAPI(
        '/notificacoes-painel-geral',
        'GET',
        null,
        (e) => {
          this.notificacoes = e;
        }
      );
    },
    getNotificacoes(status) {
      if ( this.notificacoes.length > 0 ) {
        return this.notificacoes.filter(n => n.status == status);
      }
      
      return [];
    },
    buscarStatus() {
      callAPI(
        '/status',
        'GET',
        null,
        (e) => {
          this.status = e
        }
      );
    },
    buscarTarefas() {
      callAPI(
        '/buscar-tarefas',
        'GET',
        null,
        (e) => {
          this.tarefas = e;
        }
      );
    },
    salvarServico(status, servico) {
      console.log(servico)
      callAPI(
        '/processo-servico/atualizar-status/' + servico.processo_servico_id,
        'PUT',
        {
          processo_id: servico.processo_id,
          status: status
        },
        e => {
          this.buscarTarefas();
        }
      );
    },
    salvarPergunta(status, pergunta) {
      callAPI(
        '/processo-pergunta/atualizar-status/' + pergunta.processo_pergunta_id,
        'PUT',
        {
          processo_id: pergunta.processo_id,
          status: status
        },
        e => {
          this.buscarTarefas();
        }
      );
    },
    visualizar(processo_id) {
      callAPI(
        '/resumo-processo/' + processo_id,
        'GET',
        null,
        (data)=>{
          this.modelView = data;
          $(this.$refs.modal_visualizar).modal('show');
        }
      );
    },
    fecharVisualizacao() {
      this.modelView = {};
      $(this.$refs.modal_visualizar).modal('hide');
    },
    formatMoney(value) {
      return formatMoney(value);
    },
    formatDate(date) {
      return formatDate(date);
    },
  },
};
</script>

<style scoped>
  .action > div {
    cursor: pointer;
    padding: 5px;
  }

  table td {
    vertical-align: middle !important;
  }

  .table-pendente * {
    color: red;
  }

  .table-tramitando * {
    color: blue;
  }

  .table-aguardando * {
    color: gray;
  }
  
  .table-concluida * {
    color: green;
  }

  .painel-status {
    outline: none;
    margin: auto;
    padding: 3px;
    width: 100%;
    border: 1px solid #aaaaaaaa;
    border-radius: 3px;
    background-color: #fff;
  }
</style>