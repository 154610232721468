<template>
  <Layout ref="layout" @show="$emit('show')" @back="list" :showback="false">
    <template slot="body">
      <FormaPagamento ref="model" @back="$refs.layout.back($event)" />
    </template>
  </Layout>
</template>

<script>
import Layout from "../../../components/layout/Layout.vue";
import FormaPagamento from "../../../components/cadastro/FormaPagamento.vue";
export default {
  components: {
    Layout,
    FormaPagamento,
  },
  methods: {
    list() {
      if (this.$refs.model) {
        this.$refs.model.list();
      }
    },
  },
};
</script>