<template>
  <div>
    <div class="table-responsive">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <select class="form-control h-100" v-model="inputFiltroCampo">
            <option v-for="(v, k) in getCampo()" :key="k" :value="v">
              {{ v.label }}
            </option>
          </select>
        </div>
        <input
          v-if="inputFiltroCampo == null || !inputFiltroCampo.select"
          :type="
            inputFiltroCampo != null && inputFiltroCampo.numeric
              ? 'number'
              : 'text'
          "
          class="form-control"
          v-model="inputFiltro"
          @change="filtrar"
          @keyup.enter="$event.target.blur()"
        />
        <select
          v-if="inputFiltroCampo != null && inputFiltroCampo.select"
          class="form-control h-100"
          v-model="inputFiltro"
          @change="filtrar">
          <option :value="''"></option>
          <option v-for="(v, k) in inputFiltroCampo.values" :key="k" :value="v.value">
            {{ v.label }}
          </option>
        </select>
      </div>
      <slot name="customFilter"></slot>
      <div style="float: left;">
        <span v-if="list.itens" style="line-height: 30px">
          Exibindo {{ offset + 1 }} a {{ total }} de {{ pagination.max }} registros
        </span>
      </div>
      <div class="d-flex float-right" v-if="pagination.max">
        <div style="margin: 0; white-space: nowrap; text-align: right;">
          <ul class="pagination">
            <li :class="'paginate_button page-item first ' + (pagination.atual == 0 ? 'disabled' : '')">
              <a
                href="#"
                data-dt-idx="2"
                tabindex="0"
                class="page-link"
                @click="pagination.atual = 0"
                v-text="'|<'"
              >
              </a>
            </li>
            <li :class="'paginate_button page-item first ' + (pagination.atual == 0 ? 'disabled' : '')">
              <a
                href="#"
                data-dt-idx="2"
                tabindex="0"
                class="page-link"
                @click="pagination.atual--"
                v-text="'<<'"
              >
              </a>
            </li>
            <li
              v-for="(v, i) in pagination.valores"
              :key="i"
              :class="
                'paginate_button page-item ' +
                (pagination.atual + 1 == v ? ' active' : '')
              "
            >
              <a
                href="#"
                data-dt-idx="2"
                tabindex="0"
                class="page-link"
                @click="pagination.atual = v - 1"
              >
                {{ v }}
              </a>
            </li>
            <li :class="'paginate_button page-item first ' + (pagination.atual == pagination.valores.length - 1 ? 'disabled' : '')">
              <a
                href="#"
                data-dt-idx="2"
                tabindex="0"
                class="page-link"
                @click="pagination.atual++"
                v-text="'>>'"
              >
              </a>
            </li>
            <li :class="'paginate_button page-item first ' + (pagination.atual == pagination.valores.length - 1 ? 'disabled' : '')">
              <a
                href="#"
                data-dt-idx="2"
                tabindex="0"
                class="page-link"
                @click="pagination.atual = pagination.valores.length - 1"
                v-text="'>|'"
              >
              </a>
            </li>
          </ul>
        </div>
      </div>
      <table class="table table-striped table-bordered table-hover" data-page-size="8" data-filter=#filter>
        <thead>
          <tr>
            <th
              v-for="(v, k) in config.campos"
              :key="k"
              :style="v.head ? style(v.head.style) : ''"
              :class="
                ((v.objeto != null && orderby.col == v.objeto) ||
                 (v.orderField != null && orderby.col == v.orderField)) ? 'table-order' : ''"
              @click="!v.click ? order(v) : null"
            >
              {{ v.label }}
              <i v-if="v.objeto || v.orderField" class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
            </th>
            <th scope="col" class="text-center" style="width: 150px;">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(e, i) in list.itens" :key="i">
            <td
              v-for="(v, k) in config.campos"
              :key="k"
              @click="v.click ? $emit(v.click, e) : (v.eventCast ? $emit(eventCast(e, v), e) : '')"
              :style="v.body ? style(v.body.style) : ''"
              v-html="cast(e, v)"
            ></td>
            <td>
              <div class="action d-flex justify-content-center">
                <div title="Visualizar" @click="$emit('view', e)" v-if="showView">
                  <i class="fas fa-search"></i>
                </div>
                <div title="Clonar" @click="$emit('clone', e)" v-if="showClone">
                  <i class="fas fa-copy"></i>
                </div>
                <div title="Editar" @click="$emit('edit', e)" v-if="showEdit" style="color: var(--edit)">
                  <i class="fas fa-edit"></i>
                </div>
                <div title="Excluir" @click="$emit('delete', e)" v-if="showDelete" style="color: var(--delete)">
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="list.itens == null || list.itens.length == 0">
            <td :colspan="config.campos.length+1">
              <h5 class="text-center mt-3">
                Nenhum registro foi encontrado
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex float-right" v-if="pagination.max">
        <div style="margin: 0; white-space: nowrap; text-align: right;">
          <ul class="pagination">
            <li :class="'paginate_button page-item first ' + (pagination.atual == 0 ? 'disabled' : '')">
              <a
                href="#"
                data-dt-idx="2"
                tabindex="0"
                class="page-link"
                @click="pagination.atual = 0"
                v-text="'|<'"
              >
              </a>
            </li>
            <li :class="'paginate_button page-item first ' + (pagination.atual == 0 ? 'disabled' : '')">
              <a
                href="#"
                data-dt-idx="2"
                tabindex="0"
                class="page-link"
                @click="pagination.atual--"
                v-text="'<<'"
              >
              </a>
            </li>
            <li
              v-for="(v, i) in pagination.valores"
              :key="i"
              :class="
                'paginate_button page-item ' +
                (pagination.atual + 1 == v ? ' active' : '')
              "
            >
              <a
                href="#"
                data-dt-idx="2"
                tabindex="0"
                class="page-link"
                @click="pagination.atual = v - 1"
              >
                {{ v }}
              </a>
            </li>
            <li :class="'paginate_button page-item first ' + (pagination.atual == pagination.valores.length - 1 ? 'disabled' : '')">
              <a
                href="#"
                data-dt-idx="2"
                tabindex="0"
                class="page-link"
                @click="pagination.atual++"
                v-text="'>>'"
              >
              </a>
            </li>
            <li :class="'paginate_button page-item first ' + (pagination.atual == pagination.valores.length - 1 ? 'disabled' : '')">
              <a
                href="#"
                data-dt-idx="2"
                tabindex="0"
                class="page-link"
                @click="pagination.atual = pagination.valores.length - 1"
                v-text="'>|'"
              >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    config: Object,
    filtrosini: Object,
    showView: {
      type: Boolean,
      default: false
    },
    showClone: {
      type: Boolean,
      default: true
    },
    showEdit: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      busc: true,
      inputFiltro: "",
      inputFiltroCampo: null,
      list: [],
      max: null,
      orderby: {
        col: null,
        dir: "ASC",
      },
      where: {},
      pagination: {
        max: null,
        size: 50,
        anterior: true,
        proxima: true,
        atual: 0,
        valores: [],
      },
    };
  },
  computed: {
    offset() {
      return this.pagination.atual * this.pagination.size;
    },
    total() {
      return this.offset + this.list.itens.length;
    },
  },
  methods: {
    buscando(filtros) {
      this.busc = true;
    },
    mobileLabel(v) {
      if (window.innerWidth < 600) {
        if (v.labelMobile == null || v.labelMobile) {
          return v.label;
        } else {
          return "";
        }
      }
      return v.label;
    },
    getCampo() {
      var c = [];
      for (const camp of this.config.campos) {
        if (camp.objeto && (typeof camp.showFilter == 'undefined' || camp.showFilter)) {
          c.push(camp);
        }
      }

      c.sort(this.ordenarCampos);

      return c;
    },
    ordenarCampos(a,b) {
      let x = a.ordem;
      let y = b.ordem;

      if (!x) {
        x = 9999;
      }

      if (!y) {
        y = 9999;
      }

      if (x < y) {return -1;}
      if (x > y) {return 1;}

      return 0;
    },
    filtrar() {
      this.busc = true;
      var filtro = {
        where: {},
        orderby: {},
        limit: this.pagination.size,
        offset: this.pagination.size * this.pagination.atual,
      };
      if (this.inputFiltro != "") {
        var operator = "like";
        var value = "%" + this.inputFiltro + "%";
        if (this.inputFiltroCampo.numeric) {
          var operator = "=";
          var value = Number(this.inputFiltro);
        }
        filtro.where[this.inputFiltroCampo.objeto] = {
          value: value,
          operator: operator,
        };
      }
      if (this.orderby.col != null) {
        filtro.orderby[this.orderby.col] = this.orderby.dir;
      }
      if (this.filtrosini) {
        if (this.filtrosini.where) {
          for (const key in this.filtrosini.where) {
            filtro.where[key] = this.filtrosini.where[key];
          }
        }
      }
      this.$emit("filtrar", filtro);
    },
    order(k) {
      if (this.orderby.col == k.objeto || (k.orderField != null && this.orderby.col == k.orderField)) {
        if (this.orderby.dir == "ASC") {
          this.orderby.dir = "DESC";
        } else {
          this.orderby.dir = "ASC";
        }
      } else {
        this.orderby.dir = "ASC";
        if (k.orderField != null) {
          this.orderby.col = k.orderField;
        } else {
          this.orderby.col = k.objeto;
        }
      }
      this.filtrar();
    },
    style(s) {
      var st = "";
      if (!s) {
        return "";
      }
      for (const key in s) {
        st += key + ":" + s[key] + ";";
      }
      return st;
    },
    cast(e, k) {
      var valor = "";
      if (k.objeto) {
        valor = e[k.objeto];
      }
      if (k.icone) {
        valor = `<i class="${k.icone}"></i>`;
      }
      if (k.cast) {
        valor = k.cast(valor);
      }
      return valor;
    },
    eventCast(item, campo) {
      return campo.eventCast(item);
    },
  },
  mounted() {
    var camps = this.getCampo();
    if (camps.length > 0) {
      this.inputFiltroCampo = camps[0];
    }
    this.filtrar();
  },
  watch: {
    inputFiltroCampo() {
      if (this.inputFiltro != "") {
        this.filtrar();
      }
    },
    list(e) {
      /*var columns = [];
      var dados = [];
      
      for (const campo of this.config.campos) {
        columns.push({
          title: campo.label,
          orderable: campo.orderable == undefined ? true : campo.orderable,
          searchable: campo.searchable == undefined ? true : campo.searchable,
          width: campo.width == undefined ? null : campo.width
        });
      }
      
      for (const item of this.list.itens) {
        var row = [];
        for (const campo of this.config.campos) {
          row.push(this.cast(item, campo));
        }

        dados.push(row);
      }

      $.fn.dataTable.Buttons.defaults.dom.button.className = 'btn btn-white btn-sm';

      $('.datatable-net').DataTable({
        pageLength: 50,
        responsive: false,
        dom: '<"html5buttons"B>lfg<"html5buttons"p>itp',
        pagingType: "full_numbers",
        columns: columns,
        data: dados,
        buttons: [
          {extend: 'copy'},
          {extend: 'csv'},
          {extend: 'excel', title: 'ExampleFile'},
          {extend: 'pdf', title: 'ExampleFile'},
          {
            extend: 'print',
            customize: function (win) {
              $(win.document.body).addClass('white-bg');
              $(win.document.body).css('font-size', '10px');
              $(win.document.body).find('table')
                                  .addClass('compact')
                                  .css('font-size', 'inherit');
            }
          }
        ],
        language: {
          decimal:        ",",
          emptyTable:     "Sem dados disponíveis",
          info:           "Exibindo _START_ até _END_ de _TOTAL_ registros",
          infoEmpty:      "Exibindo 0 até 0 de 0 registros",
          infoFiltered:   "filtrado de _MAX_ registros totais",
          infoPostFix:    "", // default
          thousands:      ".", 
          lengthMenu:     "Exibir _MENU_ registros",
          loadingRecords: "Carregando...",
          processing:     "", // default
          search:         "Buscar:",
          zeroRecords:    "Nenhum registro correspondente encontrado",
          paginate: {
            first:        "|<",
            last:         ">|",
            previous:     "<<",
            next:         ">>"
          },
          aria: {
            sortAscending:  ": ative para ordenar a coluna em ordem crescente",
            sortDescending: ": ative para ordenar a coluna em ordem decrescente"
          }
        }
      });

      /*$('.datatable-net').on('click', 'td', function () {
        var table = $('.datatable-net').DataTable();
        var data = table.cell( this ).data();
        console.log(data);
        this["teste"]();
      })*/

      this.busc = false;
      this.pagination.max = e.max;
      var v = Math.ceil(e.max / this.pagination.size);
      if (this.pagination.atual > v) {
        this.pagination.atual = 0;
      }
      this.pagination.valores = [];
      for (let index = 0; index < v; index++) {
        this.pagination.valores.push(index + 1);
      }
      if (this.pagination.valores.length > 7) {
        var atual = this.pagination.valores;
        this.pagination.valores = [];
        this.pagination.valores.push(atual[0]);
        if (this.pagination.atual < 3) {
          for (let index = 1; index < 6; index++) {
            this.pagination.valores.push(atual[index]);
          }
        } else if (this.pagination.atual > atual.length - 4) {
          for (
            let index = atual.length - 6;
            index < atual.length - 1;
            index++
          ) {
            this.pagination.valores.push(atual[index]);
          }
        } else {
          for (
            let index = this.pagination.atual - 2;
            index < this.pagination.atual + 3;
            index++
          ) {
            this.pagination.valores.push(atual[index]);
          }
        }
        this.pagination.valores.push(atual[atual.length - 1]);
        // this.pagination.valores.splice(4, this.pagination.valores.length - 8);
      }
      var p = this.offset + this.pagination.size < e.max;
      this.pagination.proxima = p;
      this.pagination.anterior = this.pagination.atual > 0;
    },
    "pagination.atual"() {
      this.filtrar();
    },
  },
};
</script>

<style scoped>
  .table-order {
    background-color: #dfdfdf;
  }

  .action > div {
    cursor: pointer;
    padding: 5px 7px;
  }

  .table > tbody > tr > td {
    vertical-align: middle !important;
  }
</style>