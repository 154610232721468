<template>
  <div :class="(css ? css : 'col-12 col-md-6') + ' mb-3'">
    <label v-if="label">{{ label }}</label>
    <ul class="list-group mt-1">
      <div v-for="(c, i) in list" :key="i" class="d-flex">
        <input
          @click="change(c)"
          class="mr-2"
          style="width: 22px; height: 22px;"
          type="checkbox"
          :checked="has(c)"
          :id="_uid + 'filtro-campo-' + i"
        />
        <label :for="_uid + 'filtro-campo-' + i">
          {{ c[campo] }}
        </label>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["labelini", "valueini", "path", "campo", "valuesini", "css"],
  data() {
    return {
      label: this.labelini,
      value: this.valueini ? this.valueini : [],
      erro: null,
      list: this.valuesini ? this.valuesini : [],
    };
  },
  watch: {
    value(v) {
      this.$emit("change", v);
    },
    valueini() {
      this.value = this.valueini;
    }
  },
  methods: {
    getIds(v) {
      var ids = [];
      for (const va of v) {
        ids.push(va.id);
      }
      return ids;
    },
    has(i) {
      for (const v of this.value) {
        if (v.id == i.id) {
          return true;
        }
      }
      return false;
    },
    change(i) {
      console.log(this.value);
      for (const k in this.value) {
        var v = this.value[k];
        if (v.id == i.id) {
          this.value.splice(k, 1);
          console.log(this.value);
          return;
        }
      }
      this.value.push(i);
      console.log(this.value);
    },
    buscar() {
      callAPI(
        this.path,
        'GET',
        null,
        (e) => {
          this.list = e.itens;
        }
      )
    },
  },
  created() {
    if (this.path != null) {
      this.buscar();
    }
  },
};
</script>