<template>
  <div class="gray-bg h-100">
    <div class="passwordBox animated fadeInDown h-100" style="max-width: 800px; padding-top: 0px;">
      <div class="row d-flex flex-wrap align-items-center h-100">
        <div class="col-md-12">
          <div class="ibox-content">
            <div class="row" style="height: 350px;">
              <div class="col-4 b-r">
                <div class="row d-flex flex-wrap align-items-center h-100">
                  <img
                    src="/img/logo.jpeg"
                    alt="Malcon Contabilidade"
                    class="m-auto w-100"
                  />
                </div>
              </div>
              <div class="col-8">
                <div class="row" style="height: calc(100% - 40px)">
                  <div class="col-lg-12">
                    <h2 class="font-bold">Esqueceu a senha?</h2>

                    <p>
                      Informe seu usuário ou endereço de email para redefinir sua senha
                    </p>

                    <div class="form-group">
                      <input v-model="formData.login" id="input-login" type="email" class="form-control" placeholder="Endereço de email" required>
                    </div>
                    <div class="alert alert-success" v-if="enviouEmail">
                      Foi enviado um email com o link para recuperação da senha!
                    </div>
                    <div class="alert alert-danger" v-if="temErro">
                      Erro ao recuperar senha! Usuário ou email inválido!
                    </div>
                  </div>
                </div>
                <div class="row" style="height: 40px">
                  <div class="col-6">
                    <button @click="enviarEmail" class="btn btn-primary btn-block">Recuperar senha</button>
                  </div>
                  <div class="col-6">
                    <button @click="$router.push('/login')" class="btn btn-secondary btn-block">Cancelar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      enviouEmail: false,
      temErro: false,
      formData: {
        login: "",
      }
    };
  },
  methods: {
    enviarEmail() {
      if (this.formData.login == null || this.formData.login == '') {
        document.getElementById('input-login').setCustomValidity('Informe o usuário ou email');
        document.getElementById('input-login').reportValidity();
        document.getElementById('input-login').focus();
        return false;
      } else {
        document.getElementById('input-login').setCustomValidity('');
      }

      callAPI(
        "/esqueceu-senha",
        "POST",
        this.formData,
        this.sucesso,
        this.fail,
        this.erro
      );
    },
    sucesso(dados) {
      this.enviouEmail = true;
      this.temErro = false;
    },
    fail(dados) {
      this.enviouEmail = false;
      this.temErro = true;
    },
    erro(dados) {
      this.enviouEmail = false;
      this.temErro = true;
    },
  },
};
</script>