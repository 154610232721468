<template>
  <div ref="colapse">
    <div
      :class="'input-collapse' + (open ? ' active' : '')"
      @click="open = !open"
    >
      <slot name="head"></slot>
    </div>
    <div class="input-collapse-body" ref="body" style="max-height: 0px">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  methods: {
    abrir() {
      this.open = true;
    },
    fechar() {
      this.open = false;
    }
  },
  watch: {
    open(open) {
      if (open) {
        this.$refs.body.style.maxHeight = this.$refs.body.scrollHeight + 'px';
        this.$emit('open');
      } else {
        this.$refs.body.style.maxHeight = '0px';
      }
    },
  },
};
</script>

<style>
.input-collapse-body {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.input-collapse {
  position: relative;
  cursor: pointer;
}
.input-collapse::after {
  position: absolute;
  content: "";
  width: 0.5em;
  height: 0.5em;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: initial;
  right: 5px;
  transform: rotate(135deg) translateY(-50%);
  transform-origin: top;
  top: 50%;
  transition: all 0.3s ease-out;
}

.input-collapse.active::after {
  transform: rotate(225deg) translateY(-50%);
}
</style>