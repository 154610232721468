<template>
  <Crud
    ref="crud"
    :path="config.path"
    :novo="config.novo"
    :list="config.list"
    :form="config.form"
    :datatable="datatable"
    :modal="true"
    @list="$emit('back', false)"
    @edit="
      model = $event;
      $emit('back', true);
    "
    @novo="
      model = {
        ordem: 1
      };
      $emit('back', true);
    "
    @clone="clone"
    @save="save"
    @create="create"
    @errors="errors = $event"
  >
    <template slot="form">
      <div>
        <div class="row m-0">
          <Texto
            ref="nome"
            labelini="Nome *"
            :valueini="model.nome"
            @change="model.nome = $event"
            :columnGroup="1"
            :errors="getErrors('nome')"
          />

          <Select
            ref="tarefa_id"
            labelini="Tarefa *"
            :valueini="model.tarefa_id"
            path="/tarefas"
            campo="nome"
            vazio="Selecione"
            @change="model.tarefa_id = $event"
            :btnnovo="true"
            :errors="getErrors('tarefa_id')"
          >
            <template slot="novo">
              <Tarefa :formonly="true" @closeform="$refs.tarefa_id.novo = false" />
            </template>
          </Select>

          <Texto
            ref="ordem"
            typeini="number"
            labelini="Ordem de exibição *"
            :valueini="model.ordem"
            @change="model.ordem = $event"
            :columnGroup="1"
            :errors="getErrors('ordem')"
          />
        </div>
      </div>
    </template>
  </Crud>
</template>

<script>
import Tarefa from "../cadastro/Tarefa.vue";
import Crud from "../crud/Crud.vue";
import Texto from "../crud/input/Text.vue";
import Money from "../crud/input/Money.vue";
import Select from "../crud/input/Select.vue";
export default {
  data() {
    return {
      config: {
        novo: "Nova",
        list: "Perguntas",
        form: "Pergunta",
        path: "/perguntas",
      },
      list: [],
      campos: ["nome"],
      model: {},
      errors: null,
      datatable: {
        campos: [
          {
            label: "Cód.",
            objeto: "id",
            head: {
              style: {
                "width": "80px",
              },
            },
          },
          {
            label: "Descrição",
            objeto: "nome",
          },
        ],
      },
    };
  },
  methods: {
    getErrors(campo) {
      if (this.errors) {
        return this.errors[campo];
      } else {
        return null;
      }
    },
    save() {
      this.$refs.crud.save(this.model);
    },
    create() {
      this.$refs.crud.create(this.model);
    },
    clone(model) {
      this.model = structuredClone(model);
      this.model.id = null;
    }
  },
  components: {
    Crud,
    Texto,
    Money,
    Select,
    Tarefa
  },
};
</script>