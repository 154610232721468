<template>
  <div :class="(css ? css : 'col-12 col-md-6') + ' mb-3'">
    <div class="form-group">
      <label v-if="label">{{ label }}</label>
      <input
        type="text"
        class="form-control"
        v-model="value"
        @keyup.enter="$event.target.blur()"
      />

      <small
        class="form-text text-danger d-block"
        v-for="(e, i) in erro"
        :key="i"
        >{{ e }}</small
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["typeini", "labelini", "valueini", "css"],
  data() {
    return {
      label: this.labelini,
      value: this.valueini,
      erro: null,
      type: this.typeini,
    };
  },
  watch: {
    value(v) {
      if (v == "") {
        v = null;
      }

      if (v != null) {
        var tamanho = v.length;
        v = v.replace(/\D/g, "");
        if (v.length > 8) {
          v = v.substring(0, 8);
        } else {
          if (v.length == 8) {
            var cep = v;
            v = v.substring(0, 5) + "-" + v.substring(5, v.length);

            if (tamanho == 8) {
              $.ajax({
                url: "https://viacep.com.br/ws/" + cep + "/json/",
                type: "GET",
                data: null,
                crossDomain: true,
                dataType: "json",
                contentType: "application/json;",
                headers: {
                  "Accept": "application/json"
                },
                success: (e) => {
                  this.$emit("local", e);
                },
              }).catch((e) => {
                console.log(e);
              });
            }
          }
        }
      }

      this.value = v;
      this.$emit("change", v);
    },
  },
};
</script>