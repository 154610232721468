<template>
  <div class="footer">
    <div class="copyright">
      <p>
        Copyright &copy; Designed & Developed by
        <a href="https://themeforest.net/user/quixlab">Quixlab</a> 2018
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

