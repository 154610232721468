<template>
  <div v-if="dadosServico!=[]">
    <AddList
      ref="servicos"
      labelini="Selecione os serviços"
      validation_id="servicos_validate"
      :valuesini="dadosServico"
      path="/servicos"
      :btnnovo="true"
      cssright="servico-add-grid"
      cssblock="servico-grid-block col-12 col-md-6 mt-3"
      @change="change">

      <template slot="tablehead">
        <th style="text-align: center">
          Nome
        </th>
        <th style="width: 80px; text-align: center;">
          Qtd.
        </th>
        <th style="width: 120px; text-align: center;">
          Valor (R$)
        </th>
        <th style="width: 120px; text-align: center;">
          Total (R$)
        </th>
      </template>

      <template slot="tablebody" slot-scope="{ item }">
        <td style="padding-left: 5px; vertical-align: middle;">
          {{ item.nome }}
        </td>
        <td>
          <Texto
            css="form-group w-100 m-auto"
            typeini="number"
            :valueini="
              item.quantidade ? item.quantidade : (item.quantidade = 1)
            "
            @change="
              item.quantidade = parseInt($event);
              $refs.servicos.$forceUpdate();
              calculaTotal();
            "
          />
        </td>
        <td>
          <Money
            css="w-100 m-auto"
            :changeeventini="true"
            :valueini="item.valor ? item.valor : '0.00'"
            @change="item.valor = $event;
              $refs.servicos.$forceUpdate();
              calculaTotal();"
          />
        </td>
        <td style="padding-right: 5px; text-align: right; vertical-align: middle;">
          <b class="m-auto">
            {{ parseFloat(item.valor_total).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}
          </b>
        </td>
      </template>

      <template slot="tablefoot">
        <td style="padding-left: 5px;" colspan="3">
          <b>Total</b>
        </td>
        <td style="padding-right: 5px; text-align: right;">
          <b>{{ parseFloat(total).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}</b>
        </td>
      </template>

      <template slot="item" slot-scope="{ item }">
        <div class="p-1">
          {{ item.nome }}
        </div>
      </template>

      <template slot="novo" slot-scope="{ call }">
        <Servico :formonly="true" @closeform="call"></Servico>
      </template>
    </AddList>
  </div>
</template>

<script>
import AddList from "../crud/input/AddList2.vue";
import Money from "../crud/input/Money.vue";
import Texto from "../crud/input/Text.vue";
import Servico from "../cadastro/Servico.vue";

export default {
  props: ["processo", "cloneprocesso"],
  components: {
    AddList,
    Money,
    Texto,
    Servico,
  },
  data() {
    return {
      total: 0,
      dadosServico: [],
      quantidade: 0,
    };
  },
  created() {
    this.buscarServicos();
  },
  watch: {
    'processo.id' () {
      this.buscarServicos();
    }
  },
  methods: {
    validacao() {
      if (this.$refs.servicos.objects.length == 0) {
        document.getElementById('servicos_validate').setCustomValidity('Selecione um serviço');
        document.getElementById('servicos_validate').reportValidity();
        document.getElementById('servicos_validate').focus();
        return true;
      } else {
        document.getElementById('servicos_validate').setCustomValidity('');
        return false;
      }
    },
    buscarServicos() {
      if (this.processo.id) {
        callAPI(
          '/buscar-processo-servico',
          'GET',
          {
            processo_id: this.processo.id
          },
          (data)=>{
            this.dadosServico = data;
          }
        );
      } else if (this.cloneprocesso) {
        callAPI(
          '/buscar-processo-servico',
          'GET',
          {
            processo_id: this.cloneprocesso
          },
          (data)=>{
            this.dadosServico = [];

            for (let servico of data) {
              this.dadosServico.push({
                id: servico.id,
                nome: servico.nome,
                quantidade: servico.quantidade,
                tarefa_id: servico.tarefa_id,
                valor: servico.valor,
                valor_total: servico.valor_total
              });
            }
          }
        );
      }
    },
    limparServicos() {
      if (this.$refs.servicos) {
        this.$refs.servicos.value = [];
        this.$refs.servicos.objects = [];
        this.calculaTotal();
      }
    },
    change() {
      this.$emit('change',this.$refs.servicos.objects);
    },
    calculaTotal() {
      var total = 0;
      var quantidade = 0;
      for (const servico of this.$refs.servicos.objects) {
        if (servico.valor != null) {
          total += servico.valor * servico.quantidade;
        }
        if (servico.quantidade != null) {
          quantidade += servico.quantidade;
        }

        if (servico.valor != null && servico.quantidade != null) {
          servico.valor_total = servico.valor * servico.quantidade;
        }
      }
      this.total = total;
      this.quantidade = quantidade;
      this.change();
    },
  },
};
</script>