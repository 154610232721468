
import FaturamentoReport from '../views/relatorio/FaturamentoReport.vue';

const routes = [
  {
    path: '/relatorio/faturamento',
    name: 'FaturamentoReport',
    component: FaturamentoReport,
  },
]

export default routes