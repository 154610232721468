<template>
  <div class="w-100">
    <h4>Condições de pagamento</h4>
    <div class="col-12 mb-4">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td style="width: 50%;">
              <span>Valor dos serviços</span>
            </td>
            <td style="width: 50%; text-align: right;">
              <b>R$ {{ servicos.toFixed(2) }}</b>
            </td>
          </tr>
          <tr>
            <td style="width: 50%;">
              <span>Valor das taxas</span>
            </td>
            <td style="width: 50%; text-align: right;">
              <b>R$ {{ taxas.toFixed(2) }}</b>
            </td>
          </tr>
          <tr>
            <td style="width: 50%;">
              <span>Valor total</span>
            </td>
            <td style="width: 50%; text-align: right;">
              <b>R$ {{ total.toFixed(2) }}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h4 class="mt-4">Entrada</h4>
    <div class="col-12">
      <div class="row mx-0 mb-2">
        <Money
          css="col-6"
          style="padding-left: 0px;"
          ref="entrada"
          labelini="Valor da entrada"
          :valueini="entrada"
          :changeeventini="true"
          @change="entrada = $event"
        ></Money>
        
        <Select
          css="col-6"
          style="padding-right: 0px;"
          ref="forma_pagamento_id"
          labelini="Forma de pagamento"
          path="/formas-pagamento"
          campo="nome"
          vazio="Selecione"
          @change="formaPagamento = $event"
        >
        </Select>
      </div>
    </div>

    <h4 class="mt-4">Condições de parcelamento</h4>
    <div class="col-12">
      <div class="row mx-0 mb-2">
        <Select
          css="col-6"
          style="padding-left: 0px"
          ref="parcela_sel"
          labelini="Quantidade de parcelas *"
          :valueini="quantidadeParcelas"
          :valuesini="{
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            10: '10',
            11: '11',
            12: '12',
          }"
          @change="quantidadeParcelas = $event"
        >
        </Select>
        <Texto
          css="col-6"
          style="padding-right: 0px;"
          labelini="Dia do vencimento das parcelas *"
          typeini="number"
          :valueini="10"
          @change="dia = $event"
        ></Texto>
      </div>
    </div>

    <h4 class="mt-4">Parcelas</h4>
    <div class="col-12">
      <table class="table table-bordered">
        <thead>
          <th>
            Valor
          </th>
          <th style="width: 33.33%">
            Vencimento
          </th>
          <th style="width: 33.33%">
            Forma de pagamento
          </th>
        </thead>
        <tbody>
          <tr
            v-for="(p, i) in parcelas"
            :key="i"
          >
            <td>
              <Money
                ref="parcela"
                css="w-100"
                :valueini="p.valor"
                :changeeventini="true"
                @change="
                  p.valor = $event;
                  p.vl_taxa = taxas;
                  p.vl_servico = servicos;
                  p.vl_total = taxas + servicos;
                  calcularTotal();
                "
              ></Money>
            </td>
            <td>
              <Data
                ref="vencimento"
                css="w-100"
                :showsum="true"
                :valueini="p.vencimento"
                @change="p.vencimento = $event"
              />
            </td>
            <td>
              <Select
                :id="'forma_pagamento_' + i"
                ref="forma_pagamento"
                css="w-100"
                :valueini="p.forma_pagamento_id"
                path="/formas-pagamento"
                campo="nome"
                vazio="Selecione"
                @change="p.forma_pagamento_id = $event"
                @changeObject="p.forma_pagamento = $event"
              >
              </Select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FormaPagamento from "../cadastro/FormaPagamento.vue";
import Select from "../crud/input/Select.vue";
import Money from "../crud/input/Money.vue";
import Data from "../crud/input/Data.vue";
import Texto from "../crud/input/Text.vue";
export default {
  props: ["processo"],
  components: {
    FormaPagamento,
    Select,
    Money,
    Data,
    Texto,
  },
  data() {
    return {
      taxas: 0,
      servicos: 0,
      entrada: 0,
      model: {},
      quantidadeParcelas: 1,
      dia: 10,
      formaPagamento: null,
      parcelas: [],
      total: 0,
    };
  },
  mounted() {
    this.buscarParcelas();
  },
  methods: {
    buscarParcelas() {
      if (this.processo.id) {
        callAPI(
          '/buscar-parcelas',
          'GET',
          {
            processo_id: this.processo.id
          },
          (data)=>{
            this.parcelas = data;
            this.quantidadeParcelas = this.parcelas.length;
            this.entrada = parseFloat(this.processo.valor_entrada);
          }
        );
      } else {
        this.gerarParcelas();
      }
    },
    validacao() {
      for (const [i, parcela] of this.parcelas.entries()) {
        if (parcela.forma_pagamento_id == null || parcela.forma_pagamento_id == '') {
          document.getElementById('forma_pagamento_' + i).setCustomValidity('Escolha a forma de pagamento');
          document.getElementById('forma_pagamento_' + i).reportValidity();
          document.getElementById('forma_pagamento_' + i).focus();
          return true;
        } else {
          document.getElementById('forma_pagamento_' + i).setCustomValidity('');
        }
      }

      return false;
    },
    gerarParcelas() {
      while (this.parcelas.length < this.quantidadeParcelas) {
        this.parcelas.push(this.novaParcela());
      }

      if (this.parcelas.length > this.quantidadeParcelas) {
        this.parcelas.splice(this.quantidadeParcelas, this.parcelas.length - this.quantidadeParcelas);
      }

      setTimeout(() => {
        this.calcularParcelas();
      }, 50);
    },
    atualizaValores(taxas, servicos) {
      var t = 0;
      var s = 0;

      if (taxas) {
        for (const taxa of taxas) {
          t += taxa.valor * taxa.quantidade;
        }
      }

      if (servicos) {
        for (const servico of servicos) {
          s += servico.valor * servico.quantidade;
        }
      }

      this.taxas = t;
      this.servicos = s;
      this.calcularParcelas();
    },
    novaParcela() {
      var m = this.entrada > 0 ? this.parcelas.length : this.parcelas.length + 1;
      var data = new Date();
      data.setMonth(data.getMonth() + m);
      data.setDate(this.dia == null ? 10 : this.dia);

      var parcela = {
        valor: 0,
        vencimento: data.getFullYear() + '-' + this.dig(data.getMonth() + 1) + '-' + this.dig(data.getDate()),
        forma_pagamento_id: this.formaPagamento
      };

      return parcela;
    },
    calcularTotal() {
      this.total = this.servicos + this.taxas;
    },
    getDataAtual(e){
      var data = new Date();
      return data.getFullYear() + '-' + this.dig(data.getMonth() + 1) + '-' + this.dig(data.getDate());
    },
    dig(v) {
      return v < 10 ? "0" + v : v;
    },
    calcularParcelas() {
      if (this.parcelas.length == 0) {
        return;
      }
      var total = Math.round((this.taxas + this.servicos - this.entrada) * 100);
      if (this.entrada > 0 && this.parcelas.length == 1) {
        this.entrada = 0;
        return;
      }
      
      if (this.entrada > 0) {
        if (this.$refs.parcela) {
          if (this.parcelas.length > 1) {
            var valor = Math.floor(total / (this.parcelas.length - 1)) / 100;
            var resto = (total % (this.parcelas.length - 1)) / 100;
            
            for (const parcela of this.$refs.parcela) {
              parcela.setValue(valor);
            }

            if (resto > 0) {
              this.$refs.parcela[1].setValue(valor + resto);
            }
          }

          this.$refs.parcela[0].setValue(this.entrada);
          if (!this.processo.id || !this.parcelas[0].vencimento) {
            this.parcelas[0].vencimento = this.getDataAtual();
          }
        }
      } else {
        var valor = Math.floor(total / this.parcelas.length) / 100;
        var resto = (total % this.parcelas.length) / 100;
        for (const parcela of this.$refs.parcela) {
          parcela.setValue(valor);
        }
        if (resto) {
          this.$refs.parcela[0].setValue(valor + resto);
          
        }
      }
      this.$emit("change", this.parcelas);
    },
  },
  watch: {
    entrada(e) {
      if (e > 0 && e < this.taxas + this.servicos && this.parcelas.length < 2) {
        this.$refs.parcela_sel.value = 2;
      } else {
        setTimeout(() => {
          this.calcularParcelas();
        }, 50);
      }
    },
    dia(d) {
      if (d == null) {
        return;
      }

      for (const [i, parcela] of this.$refs.vencimento.entries()) {
        if (this.entrada == 0 || i != 0) {
          var data = parcela.getDate();
          data.setDate(d);
          parcela.setDate(data);
        }
      }
    },
    formaPagamento(f) {
      for (const forma of this.$refs.forma_pagamento) {
        forma.value = f;
      }
    },
    quantidadeParcelas(value) {
      this.gerarParcelas();
    },
    'processo.id' () {
      this.buscarParcelas();
    }
  },
};
</script>