import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Page404 from '../views/sistema/404.vue'
import Sistema from '../views/sistema/Sistem.vue'

import Login from './login.js';
import Cadastro from './cadastro';

import Admin from './admin.js';
import Painel from './painel.js';
import Relatorio from './relatorio.js';

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/sistema*',
        name: 'Sistema',
        component: Sistema
    },
    {
        path: "*",
        component: Page404,
        meta: { public: true, layout: false }
    }
]

routes.push(...Login);
routes.push(...Cadastro);
routes.push(...Admin);
routes.push(...Painel);
routes.push(...Relatorio);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router