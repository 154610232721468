<template>
  <div class="h-100">
    <div class="container d-flex h-100">
      <div class="card m-auto col-md-8 col-12">
        <div class="card-body text-center pt-5">
          <img src="/img/accessup2.png" alt="" class="w-100" />
          <h1 class="error-text text-primary">404</h1>
          <h4 class="mt-4">
            <i class="fa fa-thumbs-down text-danger"></i> Bad Request
          </h4>

          <p>A pagina que tentou acessar não existe.</p>
          <form class="mt-5 mb-5">
            <div class="text-center mb-4 mt-4">
              <a @click="$router.push('/')" class="btn btn-primary">
                <i class="fa fa-arrow-left"></i> Voltar para o inicio</a
              >
            </div>
          </form>
          <div class="text-center">
            <p>
              Copyright © Desenvolvido por
              <b
                ><a target="_blank" href="https://ideatos.com.br">Ideatos</a></b
              >

              {{ new Date().getFullYear() }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>