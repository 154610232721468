<template>
  <div>
    <h2 class="pl-2"><b>Resumo</b></h2>

    <div class="d-flex w-100">
      <div class="col-8 pl-2">
        <div style="display: grid; grid-template-columns: 50% 50%;">
          <div class="mb-2">
            <b>Ticket:</b> {{ processo.ticket }}
          </div>
          <div class="mb-2">
            <b>Cliente:</b> {{ processo.cliente_id + " - " + processo.cliente.nome }}
          </div>
          <div class="mb-2">
            <b>Metragem do Imóvel:</b> {{ processo.metragem }}
          </div>
          <div class="mb-2">
            <b>Data de Validade:</b> {{ formatDate(processo.dt_validade) }}
          </div>
          <div class="mb-2">
            <b>Inscrição do Imóvel:</b> {{ processo.inscricao_imovel }}
          </div>
          <div class="mb-2">
            <b>Previsão de Conclusão:</b> {{ formatDate(processo.dt_conclusao) }}
          </div>
          <div class="mb-2">
            <b>Valor Total:</b> {{ formatMoney(processo.valor_total) }}
          </div>
        </div>
        <div class="mb-2">
          <b>Síntese:</b> {{ processo.sintese }}
        </div>
        <div>
          <b>Observação:</b> {{ processo.observacao }}
        </div>
      </div>
      <div class="col-4" v-if="showButtons">
        <button
          :disabled="processo.id == null"
          class="app-button app-button-primary col-12 mb-3"
          @click="gerarOrcamentoCompleto"
        >
          Orçamento Completo
        </button>
        <button
          :disabled="processo.id == null"
          class="app-button app-button-primary col-12 mb-3"
          @click="gerarOrcamentoResumido"
        >
          Orçamento Resumido
        </button>
        <button
          :disabled="processo.id == null"
          class="app-button app-button-primary col-12 mb-3"
          @click="openModalPropostaManual"
        >
          Proposta Manual
        </button>
        <button
          :disabled="processo.id == null"
          class="app-button app-button-primary col-12 mb-3"
          @click="imprimirResumoProcesso"
        >
          Imprimir Resumo
        </button>
      </div>
    </div>
    <div class="mt-4">
      <h4 style="padding-left: 0.5rem;"><b>Serviços</b></h4>

      <table class="table table-striped datatable-table">
        <thead>
          <tr>
            <th>
              Descrição
            </th>
            <th style="width: 120px; text-align: right;">
              Quantidade
            </th>
            <th style="width: 100px; text-align: right;">
              Valor
            </th>
            <th style="width: 100px; text-align: right;">
              Total
            </th>
          </tr>
        </thead>
        <tbody class="table-body-scroll">
          <tr v-for="item,i in processo.processo_servicos" :key="i">
            <td>
              {{ item.servico.nome }}
            </td>
            <td style="text-align: right;">
              {{ item.quantidade }}
            </td>
            <td style="text-align: right;">
              {{ formatMoney(item.valor) }}
            </td>
            <td style="text-align: right;">
              {{ formatMoney(item.valor_total) }}
            </td>
          </tr>
          <tr class="font-weight-bold">
            <td>
              Subtotal
            </td>
            <td style="text-align: right">
              {{ totalQuantidadeServicos }}
            </td>
            <td style="text-align: right">
              {{ formatMoney(totalValorUnitarioServicos) }}
            </td>
            <td style="text-align: right">
              {{ formatMoney(totalValorServicos) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-4">
      <h4 style="padding-left: 0.5rem;"><b>Taxas</b></h4>

      <table class="table table-striped datatable-table">
        <thead>
          <tr>
            <th>
              Descrição
            </th>
            <th style="width: 120px; text-align: right;">
              Quantidade
            </th>
            <th style="width: 100px; text-align: right;">
              Valor
            </th>
            <th style="width: 100px; text-align: right;">
              Total
            </th>
          </tr>
        </thead>
        <tbody class="table-body-scroll">
          <tr v-for="item,i in processo.processo_taxas" :key="i">
            <td>
              {{ item.taxa.nome }}
            </td>
            <td style="text-align: right;">
              {{ item.quantidade }}
            </td>
            <td style="text-align: right;">
              {{ formatMoney(item.valor) }}
            </td>
            <td style="text-align: right;">
              {{ formatMoney(item.valor_total) }}
            </td>
          </tr>
          <tr class="font-weight-bold">
            <td>
              Subtotal
            </td>
            <td style="text-align: right">
              {{ totalQuantidadeTaxas }}
            </td>
            <td style="text-align: right">
              {{ formatMoney(totalValorUnitarioTaxas) }}
            </td>
            <td style="text-align: right">
              {{ formatMoney(totalValorTaxas) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-4 text-right pr-2 font-weight-bold">
      <h3>Valor Total: {{ formatMoney(processo.valor_total) }}</h3>
    </div>
    <div class="mt-4">
      <h4 style="padding-left: 0.5rem;"><b>Perguntas</b></h4>

      <table class="table table-striped datatable-table">
        <thead>
          <tr>
            <th style="width: 50%">
              Descrição
            </th>
            <th style="width: 50%">
              Tarefa
            </th>
          </tr>
        </thead>
        <tbody class="table-body-scroll">
          <tr v-for="item,i in processo.processo_perguntas" :key="i">
            <td>
              {{ item.pergunta.nome }}
            </td>
            <td>
              {{ item.resposta == 'S' ? 'Sim' : 'Não' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-4">
      <h4 style="padding-left: 0.5rem;"><b>Condições de Pagamento</b></h4>

      <table class="table table-striped datatable-table">
        <thead>
          <tr>
            <th style="width: 33%">
              Vencimento
            </th>
            <th style="width: 33%">
              Forma de Pagamento
            </th>
            <th style="width: 34%; text-align: right;">
              Valor
            </th>
          </tr>
        </thead>
        <tbody class="table-body-scroll">
          <tr v-for="item,i in processo.processo_pagamentos" :key="i">
            <td>
              {{formatDate(item.vencimento) }}
            </td>
            <td>
              {{ item.forma_pagamento.nome }}
            </td>
            <td style="text-align: right;">
              {{ formatMoney(item.valor) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      ref="modal_proposta_manual"
      aria-hidden="true"
    >
      <div style="width: 80%; max-width: none;" class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Proposta Manual</h5>
          </div>
          <div class="modal-body">
            <Select
              ref="modelo"
              labelini="Modelo"
              path="/modelo-proposta-manual"
              campo="nome"
              vazio="Selecione"
              @change="modelo_id = $event"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="app-button app-button-secondary"
              data-dismiss="modal"
              @click="closeModalPropostaManual"
            >
              Fechar
            </button>
            <button 
              class="app-button app-button-primary"
              @click="gerarPropostaManual"
            >
              Gerar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "../crud/input/Select.vue";
import docxtemplater from 'docxtemplater';
import JSZip from 'jszip'; 
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';

export default {
  props: ["processo", "hidebuttons"],
  components: {
    Select
  },
  data() {
    return {
      modelo_id: null,
      showButtons: !this.hidebuttons
    };
  },
  computed: {
    totalQuantidadeServicos() {
      let total = 0;

      for (let servico of this.processo.processo_servicos) {
        total += servico.quantidade;
      }

      return total;
    },
    totalValorUnitarioServicos() {
      let total = 0;

      for (let servico of this.processo.processo_servicos) {
        total += parseFloat(servico.valor);
      }

      return total;
    },
    totalValorServicos() {
      let total = 0;

      for (let servico of this.processo.processo_servicos) {
        total += parseFloat(servico.valor_total);
      }

      return total;
    },
    totalQuantidadeTaxas() {
      let total = 0;

      for (let taxa of this.processo.processo_taxas) {
        total += taxa.quantidade;
      }

      return total;
    },
    totalValorUnitarioTaxas() {
      let total = 0;

      for (let taxa of this.processo.processo_taxas) {
        total += parseFloat(taxa.valor);
      }

      return total;
    },
    totalValorTaxas() {
      let total = 0;

      for (let taxa of this.processo.processo_taxas) {
        total += parseFloat(taxa.valor_total);
      }

      return total;
    }
  },
  methods: {
    loadFile(url,callback){
      JSZipUtils.getBinaryContent(url,callback);
    },
    gerarPropostaManual() {
      callAPI(
        '/dados-proposta-manual/' + this.processo.id,
        'GET',
        null,
        (response) => {
          var processo_id = this.processo.id;
          var modelo_id = this.modelo_id;
          this.loadFile(
            loadRecurso('/api/download-modelo-proposta-manual/' + modelo_id),
            function (error,content) {
              if (error) { 
                throw error
              };
              let zip = new JSZip(content);
              let doc = new docxtemplater().loadZip(zip);
              doc.setData(response);

              try {
                doc.render()
              } catch (error) {
                let e = {
                  message: error.message,
                  name: error.name,
                  stack: error.stack,
                  properties: error.properties,
                }
                console.log(JSON.stringify({error: e}));
                // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
                throw error;
              }
              // docx generating
              let out = doc.getZip().generate({
                type:"blob",
                mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              });
              saveAs(out,"proposta-manual-" + processo_id + ".docx");    // You can pass this blob to a custom file saver component in the project.  
            }
          );

          this.closeModalPropostaManual();
        }
      );
    },
    formatMoney(value) {
      return formatMoney(value);
    },
    formatDate(date) {
      return formatDate(date);
    },
    gerarOrcamentoCompleto() {
      callAPI(
        '/pdf/orcamento-completo/' + this.processo.id,
        'GET',
        null,
        (response) => {
          window.open(loadRecurso(response));
        }
      );
    },
    gerarOrcamentoResumido() {
      callAPI(
        '/pdf/orcamento-resumido/' + this.processo.id,
        'GET',
        null,
        (response) => {
          window.open(loadRecurso(response));
        }
      );
    },
    loadModeloPropostaManual(modelo) {
      this.$refs.texto_proposta_manual.valor = modelo.conteudo;
    },
    openModalPropostaManual() {
      $(this.$refs.modal_proposta_manual).modal('show');
    },
    closeModalPropostaManual() {
      $(this.$refs.modal_proposta_manual).modal('hide');
    },
    imprimirResumoProcesso() {
      window.open('/imprimir-resumo-processo/' + this.processo.id, '_blank').focus();
    }
  }
};
</script>