<template>
  <div style="background-color: white">
    <ResumoProcesso v-if="processo != null" :processo="processo" :hidebuttons="true" />
  </div>
</template>

<script>
import ResumoProcesso from "../../components/custom/ResumoProcesso.vue";

export default {
  props: ['processoId'],
  components: {
    ResumoProcesso
  },
  data() {
    return {
      processo: null
    };
  },
  created() {
    callAPI(
      '/resumo-processo/' + this.processoId,
      'GET',
      null,
      (response) => {
        this.processo = response;

        document.title = "resumo-processo-" + this.processo.ticket;

        setTimeout(() => {
          window.print();
        }, 100);
      }
    );
  }
};
</script>