<template>
  <Layout @show="$emit('show')">
    <template slot="body">
      <div class="p-3">
        <h4 class="m-3">Processo</h4>
        <div class="row m-0">
          <Card
            path="/info/clientes"
            tituloini="Clientes"
            iconinit="fas fa-user-tie"
            style="cursor: pointer"
            @click="$router.push('/cliente')"
          ></Card>
          <Card
            path="/info/processos"
            tituloini="Processos"
            iconinit="fas fa-file-export"
            style="cursor: pointer"
            @click="$router.push('/processo')"
          ></Card>

          <!-- <Card
            path="/info/orcamentos"
            tituloini="Orçamentos"
            iconinit="fas fa-file-invoice-dollar"
            style="cursor: pointer"
            @click="$router.push('/orcamento')"
          ></Card>

          <Card
            path="/info/propostas-manuais"
            tituloini="Propostas manuais"
            iconinit="fas fa-file-signature"
            style="cursor: pointer"
            @click="$router.push('/proposta-manual')"
          ></Card> -->
        </div>

        <h4 class="m-3">Cadastros</h4>
        <div class="row m-0">
          <Card
            path="/info/taxas"
            tituloini="Taxas"
            iconinit="fas fa-dollar-sign"
            style="cursor: pointer"
            @click="$router.push('/taxa')"
          ></Card>
          <Card
            path="/info/perguntas"
            tituloini="Perguntas"
            iconinit="fas fa-question"
            style="cursor: pointer"
            @click="$router.push('/pergunta')"
          ></Card>

          <Card
            path="/info/servicos"
            tituloini="Serviços"
            iconinit="fas fa-cog"
            style="cursor: pointer"
            @click="$router.push('/servico')"
          ></Card>

          <Card
            path="/info/tarefas"
            tituloini="Tarefas"
            iconinit="fas fa-clipboard-check"
            style="cursor: pointer"
            @click="$router.push('/tarefa')"
          ></Card>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "../../components/layout/Layout.vue";
import Card from "../../components/painel/card/Simples.vue";
export default {
  data() {
    return {};
  },
  components: {
    Card,
    Layout,
  },
};
</script>