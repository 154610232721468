<template>
  <div class="gray-bg h-100">
    <div class="passwordBox animated fadeInDown h-100" style="max-width: 800px; padding-top: 0px;">
      <div class="row d-flex flex-wrap align-items-center h-100">
        <div class="col-md-12">
          <div class="ibox-content">
            <div class="row" style="height: 350px;">
              <div class="col-4 b-r">
                <div class="row d-flex flex-wrap align-items-center h-100">
                  <img
                    src="/img/logo.jpeg"
                    alt="Malcon Contabilidade"
                    class="m-auto w-100"
                  />
                </div>
              </div>
              <div class="col-8">
                <div class="row" style="height: calc(100% - 40px)">
                  <div class="col-lg-12">
                    <h2 class="font-bold">Recuperar senha</h2>

                    <p>
                      Informe sua nova senha
                    </p>

                    <div class="form-group">
                      <input v-model="formData.email" id="input-email" type="email" class="form-control" disabled required>
                    </div>
                    <div class="form-group">
                      <input v-model="formData.password" id="input-password" type="password" class="form-control" placeholder="Nova senha" required>
                    </div>
                    <div class="form-group">
                      <input v-model="formData.password_confirmation" id="input-password-confirmation" type="password" class="form-control" placeholder="Confirmar senha" required>
                    </div>
                    <div class="alert alert-success" v-if="enviouEmail">
                      Senha redefinida com sucesso! <a class="alert-link" href="/login">Ir para o login</a>.
                    </div>
                    <div class="alert alert-danger" v-if="temErro">
                      Erro ao redefinir a senha!
                    </div>
                  </div>
                </div>
                <div class="row" style="height: 40px">
                  <div class="col-12">
                    <button @click="recuperarSenha" class="btn btn-primary btn-block m-b">Salvar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      enviouEmail: false,
      temErro: false,
      formData: {
        email: "",
        password: "",
        password_confirmation: "",
        token: ""
      }
    };
  },
  created() {
    this.formData.email = this.$route.query.email;
    this.formData.token = this.$route.query.token;
  },
  methods: {
    recuperarSenha() {
      if (this.formData.password == null || this.formData.password == '') {
        document.getElementById('input-password').setCustomValidity('Informe a senha');
        document.getElementById('input-password').reportValidity();
        document.getElementById('input-password').focus();
        return false;
      } else {
        document.getElementById('input-password').setCustomValidity('');
      }

      if (this.formData.password_confirmation == null || this.formData.password_confirmation == '') {
        document.getElementById('input-password-confirmation').setCustomValidity('Confirme a senha');
        document.getElementById('input-password-confirmation').reportValidity();
        document.getElementById('input-password-confirmation').focus();
        return false;
      } else {
        document.getElementById('input-password-confirmation').setCustomValidity('');
      }

      if (this.formData.password != this.formData.password_confirmation) {
        document.getElementById('input-password-confirmation').setCustomValidity('A confirmação está diferente da senha');
        document.getElementById('input-password-confirmation').reportValidity();
        document.getElementById('input-password-confirmation').focus();
        return false;
      } else {
        document.getElementById('input-password-confirmation').setCustomValidity('');
      }

      callAPI(
        "/recuperar-senha",
        "PUT",
        this.formData,
        this.sucesso,
        this.fail,
        this.erro
      );
    },
    sucesso(dados) {
      this.enviouEmail = true;
      this.temErro = false;
    },
    fail(dados) {
      this.enviouEmail = false;
      this.temErro = true;
    },
    erro(dados) {
      this.enviouEmail = false;
      this.temErro = true;
    },
  },
};
</script>