<template>
  <Layout @show="$emit('show')">
    <template slot="body">
      <div class="tabs-container">
        <ul class="nav nav-tabs" role="tablist">
          <li><a class="nav-link active" data-toggle="tab" href="#tab-pendentes">Ativos</a></li>
          <li><a class="nav-link" data-toggle="tab" href="#tab-concluidas">Concluídos</a></li>
        </ul>
        <div class="tab-content">
          <div role="tabpanel" id="tab-pendentes" class="tab-pane active">
            <div class="panel-body">
              <table class="table table-stripped">
                <thead>
                  <tr>
                    <th
                      :style="'width: 100px; cursor: pointer; ' + (orderField == 'ticket' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('ticket', 'PENDENTE')"
                    >
                      Ticket
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th
                      :style="'width: 250px; cursor: pointer; ' + (orderField == 'cliente.nome' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('cliente.nome', 'PENDENTE')"
                    >
                      Cliente
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th style="width: 140px">
                      CPF/CNPJ
                    </th>
                    <th style="width: 200px">
                      UF/Município
                    </th>
                    <th>
                      Síntese
                    </th>
                    <th style="width: 40px"></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(processo, i) in processosPendentes">
                    <tr :key="i" @click="showDetailPendente(i)" :style="i % 2 == 0 ? 'background-color: rgba(0,0,0,.05)' : ''">
                      <td>
                        {{ processo.ticket }}
                      </td>
                      <td>
                        {{ processo.cliente.nome }}
                      </td>
                      <td>
                        {{ processo.cliente.cpf_cnpj }}
                      </td>
                      <td>
                        {{ processo.cliente.cidade.estado.uf }}/{{ processo.cliente.cidade.nome }}
                      </td>
                      <td>
                        {{ processo.sintese }}
                      </td>
                      <td>
                        <div
                          v-if="administrador"
                          style="cursor: pointer; text-align: center;"
                          @click.stop="visualizar(processo.id)"
                        >
                          <i class="fas fa-search" title="Visualizar"></i>
                        </div>
                      </td>
                    </tr>
                    <tr :id="'detail-pendente-' + i" :key="'detail-' + i" style="display: none;">
                      <td colspan="5">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>
                                Tarefa
                              </th>
                              <th style="width: 130px;">
                                Status
                              </th>
                              <th style="width: 350px;">
                                Observação
                              </th>
                              <th style="width: 80px; text-align: center;">
                                Link
                              </th>
                              <th style="width: 80px; text-align: center;">
                                Arquivo
                              </th>
                              <th style="width: 80px; text-align: center;">
                                Upload
                              </th>
                            </tr>
                          </thead>
                          <tbody class="table-body-scroll">
                            <tr v-for="(servicoPergunta, j) in processo.servicos_perguntas" :key="j">
                              <td>
                                {{ servicoPergunta.nome_tarefa }}
                              </td>
                              <td>
                                <select
                                  class="painel-status"
                                  v-model="servicoPergunta.status_id"
                                  :style="'color: '+ getColor(servicoPergunta.status_id)"
                                  @change="salvarServicoPergunta(servicoPergunta, servicoPergunta.status_id, null)"
                                >
                                  <option
                                    v-for="(st, sti) in status"
                                    :key="sti"
                                    :value="st.id"
                                    :style="'color: ' + st.color"
                                  >
                                    {{ st.nome }}
                                  </option> 
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  v-model="servicoPergunta.observacao"
                                  style="font-size: 14px; color: red; display: inline-block; width: calc(100% - 20px);"
                                  class="painel-status"
                                  @keyup.enter="salvarServicoPergunta(servicoPergunta, null, servicoPergunta.observacao);"
                                />
                                <div
                                  style="cursor: pointer; display: inline-block; width: 20px; text-align: center;"
                                  @click="salvarServicoPergunta(servicoPergunta, null, servicoPergunta.observacao);"
                                >
                                  <i class="fas fa-save" title="Salvar observação"></i>
                                </div>
                              </td>
                              <td>
                                <div
                                  v-if="servicoPergunta.url != null"
                                  style="cursor: pointer; text-align: center;"
                                >
                                  <a :href="servicoPergunta.url" target="_blank" :title="servicoPergunta.url">
                                    <div class="col-12">
                                      <i class="fas fa-link"></i>
                                    </div>
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div
                                  v-if="servicoPergunta.arquivo != null"
                                  style="cursor: pointer; text-align: center;"
                                >
                                  <a :href="getUrlArquivo(servicoPergunta)" target="_blank" :title="servicoPergunta.arquivo">
                                    <div class="col-12">
                                      <i style="color: black" class="fas fa-file"></i>
                                    </div>
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div
                                  style="cursor: pointer; text-align: center;"
                                  @click="
                                    openModalUpload(servicoPergunta);
                                  "
                                >
                                  <i style="color: green" class="fas fa-upload" title="Upload"></i>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </template>
                  <tr v-if="processosPendentes.length == 0">
                    <td colspan="5">
                      <h4 class="text-center">Nenhum registro encontrado</h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div role="tabpanel" id="tab-concluidas" class="tab-pane">
            <div class="panel-body">
              <table class="table table-stripped table-dashed">
                <thead>
                  <tr>
                    <th
                      :style="'width: 100px; cursor: pointer; ' + (orderField == 'ticket' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('ticket', 'CONCLUIDO')"
                    >
                      Ticket
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th
                      :style="'width: 250px; cursor: pointer; ' + (orderField == 'cliente.nome' ? 'background-color: #dfdfdf;' : '')"
                      @click="ordenar('cliente.nome', 'CONCLUIDO')"
                    >
                      Cliente
                      <i class="fas fa-sort" style="float: right; margin-top: 3px;"></i>
                    </th>
                    <th style="width: 140px">
                      CPF/CNPJ
                    </th>
                    <th style="width: 200px">
                      UF/Município
                    </th>
                    <th>
                      Síntese
                    </th>
                    <th style="width: 40px"></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(processo, i) in processosConcluidos">
                    <tr :key="i" @click="showDetailConcluido(i)" :style="i % 2 == 0 ? 'background-color: rgba(0,0,0,.05)' : ''">
                      <td>
                        {{ processo.ticket }}
                      </td>
                      <td>
                        {{ processo.cliente.nome }}
                      </td>
                      <td>
                        {{ processo.cliente.cpf_cnpj }}
                      </td>
                      <td>
                        {{ processo.cliente.cidade.estado.uf }}/{{ processo.cliente.cidade.nome }}
                      </td>
                      <td>
                        {{ processo.sintese }}
                      </td>
                      <td>
                        <div
                          v-if="administrador"
                          style="cursor: pointer; text-align: center;"
                          @click.stop="visualizar(processo.id)"
                        >
                          <i class="fas fa-search" title="Visualizar"></i>
                        </div>
                      </td>
                    </tr>
                    <tr :id="'detail-concluido-' + i" :key="'detail-' + i" style="display: none;">
                      <td colspan="5">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>
                                Tarefa
                              </th>
                              <th style="width: 130px;">
                                Status
                              </th>
                              <th style="width: 350px;">
                                Observação
                              </th>
                              <th style="width: 80px; text-align: center;">
                                Link
                              </th>
                              <th style="width: 80px; text-align: center;">
                                Arquivo
                              </th>
                              <th style="width: 80px; text-align: center;">
                                Upload
                              </th>
                            </tr>
                          </thead>
                          <tbody class="table-body-scroll">
                            <tr v-for="(servicoPergunta, j) in processo.servicos_perguntas" :key="j">
                              <td>
                                {{ servicoPergunta.nome_tarefa }}
                              </td>
                              <td>
                                <select
                                  class="painel-status"
                                  v-model="servicoPergunta.status_id"
                                  :style="'color: '+ getColor(servicoPergunta.status_id)"
                                  @change="salvarServicoPergunta(servicoPergunta, servicoPergunta.status_id, null)"
                                >
                                  <option
                                    v-for="(st, sti) in status"
                                    :key="sti"
                                    :value="st.id"
                                    :style="'color: ' + st.color"
                                  >
                                    {{ st.nome }}
                                  </option> 
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  v-model="servicoPergunta.observacao"
                                  :style="'font-size: 14px; color: red; width: calc(100% - 20px);'"
                                  class="painel-status"
                                  @keyup.enter="
                                  salvarServicoPergunta(servicoPergunta, null, servicoPergunta.observacao);"
                                />
                                <div
                                  style="cursor: pointer; display: inline-block; width: 20px; text-align: center;"
                                  @click="salvarServicoPergunta(servicoPergunta, null, servicoPergunta.observacao);"
                                >
                                  <i class="fas fa-save" title="Salvar observação"></i>
                                </div>
                              </td>
                              <td>
                                <div
                                  v-if="servicoPergunta.url != null"
                                  style="cursor: pointer; text-align: center;"
                                >
                                  <a :href="servicoPergunta.url" target="_blank" :title="servicoPergunta.url">
                                    <div class="col-12">
                                      <i class="fas fa-link"></i>
                                    </div>
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div
                                  v-if="servicoPergunta.arquivo != null"
                                  style="cursor: pointer; text-align: center;"
                                  @click="
                                    openModalViewArquivo(servicoPergunta);
                                  "
                                >
                                  <i class="fas fa-file" title="Visualizar arquivo"></i>
                                </div>
                              </td>
                              <td>
                                <div
                                  style="cursor: pointer; text-align: center;"
                                  @click="
                                    openModalUpload(servicoPergunta);
                                  "
                                >
                                  <i style="color: green" class="fas fa-upload" title="Upload"></i>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </template>
                  <tr v-if="processosConcluidos.length > 0 && processosConcluidos.length < concluidosTotal">
                    <td colspan="5" style="text-decoration-line: none; text-align: center;">
                      <button class="btn btn-primary" @click="buscarProcessosConcluidos(true)">
                        Buscar mais processos (exibindo {{ processosConcluidos.length }} de {{ concluidosTotal }})
                      </button>
                    </td>
                  </tr>
                  <tr v-if="processosConcluidos.length == 0">
                    <td colspan="5" style="text-decoration-line: none;">
                      <h4 class="text-center">Nenhum registro encontrado</h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        ref="modalUpload"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Upload de Arquivos
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="closeModalUpload"
              >
                <span aria-hidden="true" style="font-size: 30px">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <Texto
                :columnGroup="1"
                ref="linkServico"
                labelini="Link"
                :valueini="servicoPerguntaUpload.url"
                @change="servicoPerguntaUpload.url = $event"
              />

              <div class="row col-12">
                <input id="input-arquivo" ref="file" type="file" @change="uploadFile" class="col-12 mb-3">
              </div>
              <div class="row col-12" v-if="servicoPerguntaUpload.arquivo">
                <button @click="removerArquivoServicoPergunta()" type="button" style="margin-left: 15px;" class="btn btn-danger">
                  Excluir arquivo
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeModalUpload()">
                Cancelar
              </button>
              <button type="button" class="btn btn-primary" @click="uploadArquivoServicoPergunta()">
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        ref="modal_visualizar"
        aria-hidden="true"
      >
        <div style="width: 80%; max-width: none;" class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">Visualizar</h3>
            </div>
            <div class="modal-body" v-if="modelView.id">
              <ResumoProcesso :processo="modelView" />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="app-button app-button-secondary"
                data-dismiss="modal"
                @click="fecharVisualizacao"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "../../components/layout/Layout.vue";
import Texto from "../../components/crud/input/Text.vue"
import Data from "../../components/crud/input/Data.vue"
import Collapse from "../../components/crud/input/Collapse.vue";
import ResumoProcesso from "../../components/custom/ResumoProcesso.vue";

export default {
  components: {
    Layout,
    Texto,
    Data,
    Collapse,
    ResumoProcesso,
  },
  data() {
    return {
      administrador: false,
      atividade: {},
      processosPendentes: [],
      processosConcluidos: [],
      status: [],
      modelView: {},
      servicoPerguntaUpload: {},
      urlArquivo: null,
      orderField: "cliente.nome",
      orderType: "ASC",
      concluidosOffset: 0,
      concluidosLimit: 20,
      concluidosTotal: 0
    };
  },
  created() {
    this.buscarPermissao();
    this.buscarProcessosPendentes();
    this.buscarProcessosConcluidos();

    callAPI(
      '/status',
      'GET',
      null,
      (e) => {
        this.status = e
      }
    );
  },
  methods: {
    buscarPermissao() {
      callAPI(
        '/has-role',
        'GET',
        { role: 'administrador'},
        (e) => {
          this.administrador = e.hasRole;
        }
      );
    },
    buscarProcessosPendentes() {
      callAPI(
        '/processo/completo',
        'GET',
        {
          status: 'PENDENTE'
        },
        (e) => {
          this.processosPendentes = e.processos;
          this.processosPendentes.sort(this.ordenarProcessos);
        }
      );
    },
    buscarProcessosConcluidos(loadMore = false, reload = false) {
      if (loadMore) {
        this.concluidosOffset += this.concluidosLimit;
      }

      var limit = this.concluidosLimit;
      var offset = this.concluidosOffset;

      if (reload) {
        limit = offset + limit;
        offset = 0;
      }

      callAPI(
        '/processo/completo',
        'GET',
        {
          status: 'CONCLUIDO',
          offset: offset,
          limit: limit
        },
        (e) => {
          this.concluidosTotal = e.total;

          if (loadMore) {
            this.processosConcluidos.push(...e.processos);
          } else {
            this.processosConcluidos = e.processos;
          }

          this.processosConcluidos.sort(this.ordenarProcessos);
        }
      );
    },
    ordenar(orderField, status) {
      if (this.orderField == orderField) {
        this.orderType = this.orderType == 'ASC' ? 'DESC' : 'ASC';
      }

      this.orderField = orderField;

      if (status == "PENDENTE") {
        this.processosPendentes.sort(this.ordenarProcessos);
      } else {
        this.processosConcluidos.sort(this.ordenarProcessos);
      }
    },
    ordenarProcessos(a,b) {
      let field = this.orderField.split(".");
      let x;
      let y;

      if (field.length == 1) {
        x = a[field[0]].toLowerCase();
        y = b[field[0]].toLowerCase();
      } else {
        x = a[field[0]][field[1]].toLowerCase();
        y = b[field[0]][field[1]].toLowerCase();
      }

      if (this.orderType == "ASC") {
        if (x < y) {return -1;}
        if (x > y) {return 1;}
      } else {
        if (x < y) {return 1;}
        if (x > y) {return -1;}
      }

      return 0;
    },
    salvarServicoPergunta(processo, id_status, obs) {
      var id = processo.processo_servico_id ? processo.processo_servico_id : processo.processo_pergunta_id;
      var path = processo.processo_servico_id ? '/processo-servico/atualizar-status/' : '/processo-pergunta/atualizar-status/';

      if (id_status) {
        callAPI(
          path + id,
          'PUT',
          {
            processo_id: processo.processo_id,
            status: id_status
          },
          e => {
            this.buscarProcessosPendentes();
            this.buscarProcessosConcluidos(false, true);
          }
        );
      }

      if (obs != null) {
        callAPI(
          path + id,
          'PUT',
          {
            observacao: obs
          },
          e => {
            swal({
              title: "Salvou",
              type: "success",
              showConfirmButton: false,
              timer: 1500
            });
          }
        );
      }
    },
    visualizar(processo_id) {
      callAPI(
        '/resumo-processo/' + processo_id,
        'GET',
        null,
        (data)=>{
          this.modelView = data;
          $(this.$refs.modal_visualizar).modal('show');
        }
      );
    },
    fecharVisualizacao() {
      this.modelView = {};
      $(this.$refs.modal_visualizar).modal('hide');
    },
    openModalUpload(servicoPergunta) {
      this.servicoPerguntaUpload = servicoPergunta;
      $(this.$refs.modalUpload).modal("show");
    },
    closeModalUpload() {
      this.servicoPerguntaUpload = {};
      this.$refs.file.value = '';
      $(this.$refs.modalUpload).modal("hide");
    },
    uploadFile() {
      this.servicoPerguntaUpload.file = this.$refs.file.files[0];
    },
    uploadArquivoServicoPergunta() {
      const formData = new FormData();

      if (this.servicoPerguntaUpload.file != null) {
        formData.append('file', this.servicoPerguntaUpload.file);
      }

      formData.append('params', JSON.stringify({ url: this.servicoPerguntaUpload.url }));
      
      formData.append(
        'id',
        this.servicoPerguntaUpload.processo_servico_id ?
        this.servicoPerguntaUpload.processo_servico_id :
        this.servicoPerguntaUpload.processo_pergunta_id
      );

      fileUpload(
        this.servicoPerguntaUpload.processo_servico_id ? '/processo-servico' : '/processo-pergunta',
        'POST',
        formData,
        (e) => {
          this.buscarProcessosPendentes();
          this.buscarProcessosConcluidos(false, true);
          this.closeModalUpload();
        },
      );
    },
    removerArquivoServicoPergunta() {
      callAPI(
        this.servicoPerguntaUpload.processo_servico_id ?
        '/processo-servico-remover-arquivo/' + this.servicoPerguntaUpload.processo_servico_id :
        '/processo-pergunta-remover-arquivo/' + this.servicoPerguntaUpload.processo_pergunta_id,
        'PUT',
        null,
        (data)=>{
          this.buscarProcessosPendentes();
          this.buscarProcessosConcluidos(false, true);
          this.closeModalUpload();
        }
      );
    },
    getUrlArquivo(servico) {
      return loadRecurso((servico.processo_servico_id ? "/storage/servicos/" : "/storage/perguntas/") + servico.processo_id + "/" + servico.arquivo);
    },
    getColor(s) {
      for (const st of this.status) {
        if (st.id == s) {
          return st.color;
        }
      }
    },
    formatMoney(value) {
      return formatMoney(value);
    },
    formatDate(date) {
      return formatDate(date);
    },
    showDetailPendente(row_id) {
      for (let row in this.processosPendentes) {
        var selectedRow = document.getElementById('detail-pendente-' + row);

        if (row == row_id) {
          if ( selectedRow.style.display == 'none' ) {
            selectedRow.style.display = 'table-row';
          } else {
            selectedRow.style.display = 'none';
          }
        } else {
          selectedRow.style.display = 'none';
        }
      }
    },
    showDetailConcluido(row_id) {
      for (let row in this.processosConcluidos) {
        var selectedRow = document.getElementById('detail-concluido-' + row);

        if (row == row_id) {
          if ( selectedRow.style.display == 'none' ) {
            selectedRow.style.display = 'table-row';
          } else {
            selectedRow.style.display = 'none';
          }
        } else {
          selectedRow.style.display = 'none';
        }
      }
    }
  }
};
</script>

<style scoped>
.painel-status {
  outline: none;
  margin: auto;
  padding: 3px;
  width: 100%;
  border: 1px solid #aaaaaaaa;
  border-radius: 3px;
  background-color: #fff;
}

.close {
  outline: none;
  border: 0px;
  background-color: transparent;
}

.table td {
  vertical-align: middle !important;
}

.table-dashed td {
  text-decoration-line: line-through;
}
</style>