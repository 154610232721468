<template>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    ref="modal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="head"></slot>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="show(false)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body cadastro-modal-body">
          <slot name="body" />
        </div>
        <div class="modal-footer">
          <slot name="foot"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    show(show = true) {
      if (show) {
        $(this.$refs.modal).modal("show");
        setTimeout(() => {
          this.$emit("show");
        }, 10);
      } else {
        $(this.$refs.modal).modal("hide");
        setTimeout(() => {
          this.$emit("hide");
        }, 10);
      }
    },
  },
};
</script>