<template>
  <Crud
    ref="crud"
    :path="config.path"
    :novo="config.novo"
    :list="config.list"
    :form="config.form"
    :datatable="datatable"
    :modal="true"
    :formonly="formonly"
    @closeform="$emit('closeform')"
    @list="$emit('back', false)"
    @edit="
      model = $event;
      $emit('back', true);
    "
    @novo="
      model = {};
      $emit('back', true);
    "
    @clone="clone"
    @save="save"
    @create="create"
    @errors="errors = $event"
  >
    <template slot="form">
      <div class="row">
        <Texto
          ref="nome"
          labelini="Nome *"
          :valueini="model.nome"
          @change="model.nome = $event"
          :columnGroup="1"
          inputstyle="text-transform: uppercase"
          :errors="getErrors('nome')"
        />

        <Texto
          ref="valor"
          labelini="Valor *"
          :valueini="model.valor"
          @change="model.valor = $event"
          :columnGroup="1"
          :errors="getErrors('valor')"
        />

        <TextArea
          ref="observacao"
          labelini="Observação"
          :valueini="model.observacao"
          @change="model.observacao = $event"
          css="col-12"
          :errors="getErrors('observacao')"
        />
      </div>
    </template>
  </Crud>
</template>

<script>
import Crud from "../crud/Crud.vue";
import Texto from "../crud/input/Text.vue";
import TextArea from "../crud/input/TextArea.vue";

export default {
  props: ["formonly"],
  data() {
    return {
      config: {
        novo: "Novo",
        list: "Parâmetros Genéricos",
        form: "Parâmetro Genérico",
        path: "/parametros-genericos",
      },
      list: [],
      campos: ["nome", "valor"],
      model: {},
      errors: null,
      datatable: {
        campos: [
          {
            label: "Cód.",
            objeto: "id",
            head: {
              style: {
                "width": "80px",
              },
            },
          },
          {
            label: "Nome",
            objeto: "nome",
          },
          {
            label: "Valor",
            objeto: "valor",
          },
        ],
      },
    };
  },
  methods: {
    getErrors(campo) {
      if (this.errors) {
        return this.errors[campo];
      } else {
        return null;
      }
    },
    save() {
      this.$refs.crud.save(this.model);
    },
    create() {
      this.$refs.crud.create(this.model);
    },
    clone(model) {
      this.model = structuredClone(model);
      this.model.id = null;
    }
  },
  mounted() {},
  components: {
    Crud,
    Texto,
    TextArea
  },
};
</script>