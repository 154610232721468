<template>
  <div :class="(css ? css : 'col-12 col-md-6 mb-3 form-group')">
    <label v-if="label">{{ label }}</label>
    <input
      :id="id ? id : null"
      :type="type ? type : 'text'"
      class="form-control"
      v-model="value"
      :autocomplete="type == 'password' ? 'new-password' : 'off'"
      @keyup.enter="$event.target.blur()"
    />
  
    <small
      class="form-text text-danger d-block"
      v-for="(e, i) in erro"
      :key="i"
      >{{ e }}</small
    >
  </div>
</template>

<script>
export default {
  props: ["typeini", "labelini", "valueini", "css", "id"],
  data() {
    return {
      label: this.labelini,
      value: this.valueini,
      erro: null,
      type: this.typeini,
    };
  },
  watch: {
    value(v) {
      if (v) {
        if (v == "") {
          v = null;
        } else if(v.length > 50) {
          v = v.substring(0,50);
          this.value = v;
        }
      }

      this.$emit("change", v);
    },
    valueini(v) {
      this.value = this.valueini;
    }
  },
};
</script>