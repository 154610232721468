<template>
  <div class="form-group col-12">
    <label v-if="label">{{ label }}</label>
    <div class="summernote"></div>
    <input :id="id ? id : null" type="text" inputmode="none" style="height: 0px; width: 100%; border: 0px; display: block; outline: none;">
    <small
      class="form-text text-danger d-block"
      v-for="(e, i) in erro"
      :key="i"
    >
      {{ e }}
    </small>
  </div>
</template>

<script>
export default {
  props: ["labelini", "valueini", "id", "height"],
  data() {
    return {
      valor: null,
      label: this.labelini,
      erro: null,
      initiated: false
    };
  },
  mounted() {
    var _this = this;
    
    if (!this.initiated) {
      $('.summernote').summernote({
        height: this.height ? this.height : 300
      }).on("summernote.change", function(we, contents, $editable) {
        _this.valor = contents;
      });
    }

    this.initiated = true;
    if (this.valueini) {
      $('.summernote').summernote('code', this.valueini);
    }
  },
  watch: {
    valor() {
      this.$emit("change", this.valor);
    },
    valueini() {
      if (this.valueini != this.valor) {
        $('.summernote').summernote('code', this.valueini);
      }
    }
  },
};
</script>