<template>
  <div :class="(css ? css : 'col-12 col-md-6 mb-3 form-group')">
    <label v-if="label">{{ label }}</label>
    <div class="form-control">
      <input
        :id="id ? id : null"
        type="text"
        style="color: inherit; border-width: 0px; box-shadow: 0 0 0 0; outline: 0;
        width: calc(100% - 25px);"
        v-model="value"
        @keyup.enter="$event.target.blur()">
      <span class="mt-2 mr-auto">
        <p style="background:transparent;border:none; float:right;">m²</p>
      </span>
    </div>
    <small
      class="form-text text-danger d-block"
      v-for="(e, i) in erro"
      :key="i"
      >{{ e }}</small
    >
  </div>
</template>

<script>
export default {
  props: ["typeini", "labelini", "valueini", "css", "id"],
  data() {
    return {
      label: this.labelini,
      value: this.valueini,
      erro: null,
      type: this.typeini,
    };
  },
  watch: {
    value(v) {
      if (v == "") {
        v = null;
      }
      
      this.$emit("change", v);
    },
    valueini(v) {
      this.value = this.valueini;
    }
  },
};
</script>