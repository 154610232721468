<template>
  <Crud
    ref="crud"
    :path="config.path"
    :novo="config.novo"
    :list="config.list"
    :form="config.form"
    :datatable="datatable"
    :formonly="formonly"
    :modal="true"
    @closeform="$emit('closeform')"
    @list="$emit('back', false)"
    @edit="
      model = $event;
      $emit('back', true);
    "
    @novo="
      model = {
        ordem: 1
      };
      $emit('back', true);
    "
    @clone="clone"
    @save="save"
    @create="create"
    @errors="errors = $event"
  >
    <template slot="form">
      <div class="row">
        <Texto
          ref="nome"
          labelini="Nome *"
          :valueini="model.nome"
          @change="model.nome = $event"
          :errors="getErrors('nome')"
        />
        <Money
          labelini="Valor *"
          :valueini="model.valor"
          :changeeventini="true"
          @change="model.valor = $event"
          :errors="getErrors('valor')"
        ></Money>
        <Select
          ref="tarefa_id"
          labelini="Tarefa"
          :valueini="model.tarefa_id"
          path="/tarefas"
          campo="nome"
          vazio="Selecione"
          @change="model.tarefa_id = $event"
          :btnnovo="true"
          css="col-6 mb-3"
        >
          <template slot="novo">
            <Tarefa
              :formonly="true"
              @closeform="$refs.tarefa_id.novo = false"
            />
          </template>
        </Select>

        <Texto
          ref="ordem"
          typeini="number"
          labelini="Ordem de exibição *"
          :valueini="model.ordem"
          @change="model.ordem = $event"
          :errors="getErrors('ordem')"
        />
      </div>
    </template>
  </Crud>
</template>

<script>
import Tarefa from "../cadastro/Tarefa.vue";
import Crud from "../crud/Crud.vue";
import Texto from "../crud/input/Text.vue";
import Add from "../crud/input/AddList.vue";
import Money from "../crud/input/Money.vue";
import Form from "../crud/layout/Form.vue";
import Select from "../crud/input/Select.vue";
export default {
  props: ["formonly"],
  data() {
    return {
      config: {
        novo: "Novo",
        list: "Serviços",
        form: "Serviço",
        path: "/servicos",
      },
      acao: false,
      campos: ["nome"],
      list: [],
      model: {},
      errors: null,
      datatable: {
        campos: [
          {
            label: "Cód.",
            objeto: "id",
            mobile: false,
            numeric: true,
            head: {
              style: {
                "width": "80px",
              },
            },
          },
          {
            label: "Nome",
            objeto: "nome",
          },
          {
            label: "Valor",
            objeto: "valor",
            mobile: false,
            cast: function (e) {
              if (e != null && e !== "") {
                return parseFloat(e).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
              } else {
                return 'R$ 0,00';
              }
            },
            head: {
              style: {
                width: "150px",
              },
            },
            body: {
              style: {
                "text-align": "right"
              }
            }
          },
        ],
      },
    };
  },
  methods: {
    getErrors(campo) {
      if (this.errors) {
        return this.errors[campo];
      } else {
        return null;
      }
    },
    save() {
      this.$refs.crud.save(this.model);
    },
    create() {
      this.$refs.crud.create(this.model);
    },
    clone(model) {
      this.model = structuredClone(model);
      this.model.id = null;
    }
  },
  components: {
    Crud,
    Texto,
    Add,
    Money,
    Select,
    Form,
    Tarefa
  },
};
</script>