<template>
  <div :class="(css ? css : (columnGroup ? (columnGroup == 3 ? 'col-4' :
                                           (columnGroup == 2 ? 'col-6' : 'col-12')) :
                                           'col-12 col-md-6') + ' mb-3 form-group')">
    <label v-if="label">{{ label }}</label>
    <div class="input-group">
      <input
        type="date"
        class="form-control"
        v-model="value"
        @keyup.enter="$event.target.blur()"
      />
      <div class="input-group-append">
        <button
          class="app-button app-button-primary"
          :disabled="value == '' || value == null"
          @click="$refs.modal.show()"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <small
      class="form-text text-danger d-block"
      v-for="(e, i) in erro"
      :key="i"
      >{{ e }}</small
    >
    <Modal ref="modal" @hide="$refs.somar.value = null">
      <template slot="head">
        <span
          >Adicionar dias de
          <b
            >{{
              value != "" && value != null
                ? getDate().toLocaleDateString()
                : ""
            }}
          </b>
          para
          <b>{{
            somar != null
              ? somarDias(parseInt(somar)).toLocaleDateString()
              : "?"
          }}</b>
        </span>
      </template>
      <template slot="body">
        <div class="row">
          <Texto
            css="col-12"
            typeini="number"
            ref="somar"
            labelini="Dias a somar"
            @change="
              somar = $event;
              $forceUpdate();
            "
          />
        </div>
      </template>
      <template slot="foot">
        <button
          type="button"
          class="app-button app-button-secondary"
          @click="$refs.modal.show(false)"
        >
          Cancelar
        </button>
        <button
          type="button"
          class="app-button app-button-primary"
          @click="
            $refs.modal.show(false);
            setDate(somarDias(parseInt(somar)));
          "
        >
          Adicionar
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "../../Modal.vue";
import Texto from "./Text.vue";
export default {
  components: {
    Modal,
    Texto,
  },
  props: ["labelini", "valueini", "css", "showsum", "columnGroup"],
  data() {
    return {
      label: this.labelini,
      value: this.valueini,
      erro: null,
      somar: null,
    };
  },
  methods: {
    somarDias(n) {
      var dias = this.getDate();
      dias.setDate(dias.getDate() + parseInt(this.somar));
      return dias;
    },
    dig(v) {
      return v < 10 ? "0" + v : v;
    },
    getDate() {
      return new Date(this.value + " 00:00");
    },
    setDate(date) {
      this.value = date.getFullYear() + "-" + this.dig(date.getMonth() + 1) + "-" + this.dig(date.getDate());
    },
  },
  watch: {
    valueini(v) {
      if (v) {
        this.value = v;
      } else {
        this.value = null;
      }
    },
    value(v) {
      if (v == "") {
        v = null;
        this.$emit("change", v);
      } else {
        this.$emit("change", v);
      }
    },
  },
};
</script>
