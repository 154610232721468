import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VMask from "v-mask"

import config from '../.env.json';

Number.prototype.formatar = function() {
    return this.toLocaleString(undefined, {
        maximumFractionDigits: 1,
        minimumFractionDigits: 0
    })
};

Vue.use(VMask);
Vue.config.productionTip = false;
apiConfig = config;

const VUE = new Vue({
    router,
    render: h => h(App)
}).$mount('#app');