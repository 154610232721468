<template>
  <Crud
    ref="crud"
    :path="config.path"
    :novo="config.novo"
    :list="config.list"
    :form="config.form"
    :datatable="datatable"
    :modal="true"
    :formonly="formonly"
    @closeform="$emit('closeform')"
    @list="$emit('back', false)"
    @edit="
      model = $event;
      $emit('back', true);
    "
    @novo="
      model = {};
      $emit('back', true);
    "
    @clone="clone"
    @save="save"
    @create="create"
    @errors="errors = $event"
  >
    <template slot="form">
      <div class="row">
        <Texto
          ref="nome"
          labelini="Nome da tarefa *"
          :valueini="model.nome"
          @change="model.nome = $event"
          :columnGroup="1"
          :errors="getErrors('nome')"
        />
      </div>
    </template>
  </Crud>
</template>

<script>
import Crud from "../crud/Crud.vue";
import List from "../crud/input/List.vue";
import Texto from "../crud/input/Text.vue";
import Money from "../crud/input/Money.vue";
import Select from "../crud/input/Select.vue";

export default {
  props: ["formonly"],
  data() {
    return {
      config: {
        novo: "Nova",
        list: "Tarefas",
        form: "Tarefa",
        path: "/tarefas",
      },
      list: [],
      campos: ["nome", "tempo_dia", "tempo_hora", "valor"],
      model: {},
      errors: null,
      datatable: {
        campos: [
          {
            label: "Cód.",
            objeto: "id",
            head: {
              style: {
                "width": "80px",
              },
            },
          },
          {
            label: "Nome",
            objeto: "nome",
          },
        ],
      },
    };
  },
  methods: {
    getErrors(campo) {
      if (this.errors) {
        return this.errors[campo];
      } else {
        return null;
      }
    },
    save() {
      this.$refs.crud.save(this.model);
    },
    create() {
      this.$refs.crud.create(this.model);
    },
    clone(model) {
      this.model = structuredClone(model);
      this.model.id = null;
    }
  },
  mounted() {},
  components: {
    Crud,
    Texto,
    List,
    Money,
    Select
  },
};
</script>