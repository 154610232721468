<template>
  <Layout
    ref="layout"
    @show="$emit('show')"
    @back="$refs.crud.acao = 'list'"
    :showback="false"
  >
    <template slot="body">
      <Crud
        ref="crud"
        :path="config.path"
        :novo="config.novo"
        :list="config.list"
        :form="config.form"
        :datatable="datatable"
        @list="$refs.layout.back(false)"
        @edit="
          nav = 'conta';
          model = $event;
          $refs.layout.back(true);
        "
        @clone="
          model = $event;
          model.id = null;
        "
        @novo="
          nav = 'conta';
          model = {};
          $refs.layout.back(true);
        "
        @save="save"
        @create="create"
        @fail="setErros"
      >
        <template slot="form">
          <div class="row">
                  <Select
                    ref="cliente_id"
                    labelini="Cliente *"
                    :valueini="model.cliente_id"
                    path="/clientes"
                    campo="nome"
                    vazio="Selecione"
                    @change="model.cliente_id = $event"
                  />

                  <Data
                      ref="dt_criacao"
                      labelini="Data de criação"
                      :valueini="model.dt_criacao"
                      @change="model.dt_criacao = modificarData($event)"
                  />

                  <Editor
                    ref="conteudo"
                    labelini="Conteúdo *"
                    :valueini="conteudo"
                    @change="model.conteudo = $event"
                  />

                  <Texto
                    ref="observacao"
                    labelini="Observação "
                    :valueini="model.observacao"
                    @change="model.observacao = $event"
                  />

                  <Money
                    ref="valor"
                    labelini="Valor *"
                    typeini="number"
                    :valueini="model.valor"
                    @change="
                      model.valor = $event;
                    "
                  />
                  <Data
                    ref="dt_aprovacao"
                    labelini="Aprovado"
                    :valueini="model.dt_aprovacao"
                    @change="model.dt_aprovacao = modificarData($event)"
                  />

                  <Select
                    ref="codigo_processo"
                    labelini="Código de processo *"
                    :valueini="model.condigo_processo"
                    path="/processos"
                    campo="nome"
                    vazio="Selecione"
                    @change="model.condicao_pagamento_id = $event;
                    "
                  />

                  <Select
                    ref="codigo_proposta_manual"
                    labelini="Código de Proposta Manual *"
                    :valueini="model.codigo_proposta_manual"
                    path="/proposta-manual"
                    campo="nome"
                    vazio="Selecione"
                    @change="model.codigo_proposta_manual = $event;
                    buscaTemplate($event)"
                  />
          </div>
        </template>
      </Crud>
    </template>
  </Layout>
</template>

<script>
import Layout from "../../../components/layout/Layout.vue";
import Crud from "../../../components/crud/Crud.vue";
import List from "../../../components/crud/input/List.vue";
import Texto from "../../../components/crud/input/Text.vue";
import Select from "../../../components/crud/input/Select.vue";
import Money from "../../../components/crud/input/Money.vue";
import Data from "../../../components/crud/input/Data.vue";
import TextoArea from "../../../components/crud/input/TextArea.vue";
import Editor from "../../../components/crud/input/Editor.vue";
import Autocomplete from "../../../components/crud/input/Autocomplete.vue";
import Cliente from "../../../components/cadastro/Cliente.vue";
export default {
  data() {
    return {
      nav: "conta",
      config: {
        novo: "Nova",
        list: "Orçamentos",
        form: "Orçamento",
        path: "/orcamentos",
      },
      campos: ["nome", "descricao"],
      model: {},
      conteudo: null,
      list: [],
      datatable: {
        campos: [
          {
            label: "Cód.",
            objeto: "id",
            mobile: false,
          },
          {
            label: "Cliente",
            objeto: "cliente_id",
          },
          {
            label: "Observação",
            objeto: "observacao",
            mobile: false,
          },
          {
            label: "Criado",
            objeto: "created_at",
            mobile: false,
            cast: function (e) {
              return new Date(e).toLocaleString();
            },
          },
          {
            label: "",
            icone: "fas fa-copy",
            head: {
              style: {
                "min-width": "50px",
              },
            },
            body: {
              style: {
                color: "var(--clone)",
                cursor: "pointer",
                "text-align": "center",
              },
            },

            click: "clone",
          },
          {
            label: "",
            icone: "fas fa-edit",
            head: {
              style: {
                "min-width": "50px",
              },
            },
            body: {
              style: {
                color: "var(--edit)",
                cursor: "pointer",
                "text-align": "center",
              },
            },

            click: "edit",
          },
          {
            label: "",
            icone: "fas fa-trash",
            head: {
              style: {
                "min-width": "50px",
              },
            },
            body: {
              style: {
                color: "var(--delete)",
                cursor: "pointer",
                "text-align": "center",
              },
            },
            click: "delete",
          },
        ],
      },
    };
  },
  watch: {
    conteudo(){
        this.$refs.conteudo.valor = this.conteudo;
    }
  },
  methods: {
    modificarData(e){
      if(e != undefined){
      const tempo = new Date(e);
      const date = tempo.getFullYear()+'-'+(tempo.getMonth()+1)+'-'+tempo.getDate();
      const dateTime = date;
      return dateTime;
      }
    },
    buscaTemplate(id){
      callAPI('/template-proposta-manual/'+id, 'GET', null, e => {this.conteudo = e.conteudo});

      
    },
    conlog(e){
      console.log(e);
    },
    // novoCliente(e) {
    //   if (e) {
    //     this.$refs.cliente_id.setId(e.id);
    //     setTimeout(() => {
    //       this.$refs.cliente_id.novo = false;
    //     }, 1000);
    //   } else {
    //     this.$refs.cliente_id.novo = false;
    //   }
    // },
    setErros(e) {
      for (const ca of this.campos) {
        var erro = e[ca];
        this.$refs[ca].erro = erro;
      }
    },
    save() {
      this.$refs.crud.save(this.model);
    },
    create() {
      this.$refs.crud.create(this.model);
    },
  },
  mounted() {},
  components: {
    Crud,
    Texto,
    Layout,
    List,
    Select,
    Autocomplete,
    TextoArea,
    Editor,
    Cliente,
    Data,
    Money
  },
};
</script>