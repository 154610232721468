<template>
  <Layout @show="$emit('show')">
    <template slot="body">
      <div style="display: grid; grid-template-columns: 200px calc(100% - 400px) 200px;">
        <div>
        </div>
        <div>
          <h1 class="m-3 text-center">Notificações</h1>
        </div>
        <div style="display: flex;">
          <button
            type="button"
            class="btn btn-primary my-auto ml-auto"
            @click="showModal()"
          >
            <i class="fas fa-plus"></i>
            Nova Notificação
          </button>
        </div>
      </div>

      <div class="tabs-container">
        <ul class="nav nav-tabs" role="tablist">
          <li><a class="nav-link active" data-toggle="tab" href="#tab-pendentes" @click="buscarNotificacoesPendentes()">Pendentes</a></li>
          <li><a class="nav-link" data-toggle="tab" href="#tab-concluidas" @click="buscarNotificacoesConcluidas()">Concluídas</a></li>
        </ul>
        <div class="tab-content">
          <div role="tabpanel" id="tab-pendentes" class="tab-pane active">
            <div class="panel-body">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th style="width: 200px;">
                      Data
                    </th>
                    <th>
                      Descrição
                    </th>
                    <th style="width: 400px;">
                      Cliente
                    </th>
                    <th style="width: 136px; text-align: center;">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody class="table-body-scroll">
                  <tr v-for="(noti, i) in notificacoes_pendentes" :key="i">
                    <td>
                      {{ (noti.dt_notificacao ? noti.dt_notificacao : "") + (noti.hr_notificacao ? " " + noti.hr_notificacao : "") }}
                    </td>
                    <td>
                      {{ noti.descricao }}
                    </td>
                    <td>
                      {{ noti.cliente_id ? noti.cliente_id + " - " + noti.cliente.nome : "" }}
                    </td>
                    <td>
                      <div style="display: grid; grid-template-columns: 40px 40px 40px;">
                        <div
                          style="cursor: pointer; text-align: center;"
                          @click="setNotificacao(noti);
                                  concluirNotificacao();">
                          <i style="color: green;" class="fas fa-check" title="Concluir"></i>
                        </div>
                        <div
                          style="cursor: pointer; text-align: center;"
                          @click="setNotificacao(noti);
                                  showModal();"
                        >
                          <i class="fas fa-edit edit-button" title="Editar"></i>
                        </div>
                        <div
                          style="cursor: pointer; text-align: center;"
                          @click="setNotificacao(noti)
                                  confirmarExclusaoNotificacao();
                          ">
                          <i class="fas fa-trash delete-button" title="Excluir"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="notificacoes_pendentes.length == 0">
                <h4 class="text-center">Nenhum registro encontrado</h4>
              </div>
            </div>
          </div>
          <div role="tabpanel" id="tab-concluidas" class="tab-pane">
            <div class="panel-body">
              <table class="table table-striped table-hover table-dashed">
                <thead>
                  <tr>
                    <th style="width: 200px;">
                      Data
                    </th>
                    <th>
                      Descrição
                    </th>
                    <th style="width: 400px;">
                      Cliente
                    </th>
                    <th style="width: 136px; text-align: center;">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody class="table-body-scroll">
                  <tr v-for="(noti, i) in notificacoes_concluidas" :key="i">
                    <td>
                      {{ (noti.dt_notificacao ? noti.dt_notificacao : "") + (noti.hr_notificacao ? " " + noti.hr_notificacao : "") }}
                    </td>
                    <td>
                      {{ noti.descricao }}
                    </td>
                    <td>
                      {{ noti.cliente_id ? noti.cliente_id + " - " + noti.cliente.nome : "" }}
                    </td>
                    <td>
                      <div style="display: grid; grid-template-columns: 40px 40px; width: 80px; margin: auto;">
                        <div
                          style="cursor: pointer; text-align: center;"
                          @click="setNotificacao(noti)
                                  showModal()">
                          <i class="fas fa-edit edit-button" title="Editar"></i>
                        </div>
                        <div
                          style="cursor: pointer; text-align: center;"
                          @click="setNotificacao(noti)
                                  confirmarExclusaoNotificacao()">
                          <i class="fas fa-trash delete-button" title="Excluir"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="notificacoes_concluidas.length == 0">
                <h4 class="text-center">Nenhum registro encontrado</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal inmodal fade"
        tabindex="-1"
        role="dialog"
        ref="modal_notificacao"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Notificação</h5>
            </div>
            <div class="modal-body" style="max-height: 60vh; overflow: auto;">
              <Texto
                :columnGroup="1"
                ref="descricao"
                labelini="Descrição *"
                :valueini="notificacao.descricao"
                @change="notificacao.descricao = $event"
              />

              <Data
                :columnGroup="1"
                ref="dt_notificacao"
                labelini="Data de Início"
                :valueini="notificacao.dt_notificacao"
                @change="notificacao.dt_notificacao = $event"
              />

              <Hora
                :columnGroup="1"
                ref="hr_notificacao"
                labelini="Hora de Início"
                :valueini="notificacao.hr_notificacao"
                @change="notificacao.hr_notificacao = $event"
              />

              <TextoArea
                css="col-12 mb-2"
                rows="3"
                ref="observacao"
                labelini="Observação"
                :valueini="notificacao.observacao"
                @change="notificacao.observacao = $event"
              />

              <Autocomplete
                id="cliente_id"
                ref="cliente_id"
                labelini="Cliente"
                :valueini="notificacao.cliente_id"
                path="/clientes"
                :characteres="1"
                :campo="[
                  'nome',
                  { valor: 'id', numerico: true }
                ]"
                @change="notificacao.cliente_id = $event"
                :cast="
                  (ob) => {
                    return (
                      ob.nome +
                      ' - ' +
                      (ob.cpf_cnpj != null ? ob.cpf_cnpj : '...') +
                      ' - Pessoa ' +
                      (ob.tipo == 'F' ? 'Física' : 'Júridica')
                    );
                  }
                "
              >
              </Autocomplete>

              <Select
                columnGroup="1"
                v-if="notificacao.id"
                ref="status_notificacao"
                labelini="Status"
                :valueini="notificacao.status"
                :valuesini="statusNotificacao"
                @change="notificacao.status = $event"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                v-if="notificacao.id"
                class="btn btn-danger"
                data-dismiss="modal"
                @click="confirmarExclusaoNotificacao()">
                Excluir
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                @click="clearNotificacao();
                        hideModal()"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="salvarNotificacao(getNotificacao())"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Texto from "../../components/crud/input/Text.vue";
import TextoArea from "../../components/crud/input/TextArea.vue";
import Select from "../../components/crud/input/Select.vue";
import Data from "../../components/crud/input/Data.vue";
import Hora from "../../components/crud/input/Hora.vue";
import Layout from "../../components/layout/Layout.vue";
import Card from "../../components/painel/card/Simples.vue";
import Autocomplete from "../../components/crud/input/Autocomplete.vue";
import Cliente from "../../components/cadastro/Cliente.vue";

export default {
  components: {
    Card,
    Layout,
    Texto,
    TextoArea,
    Select,
    Data,
    Hora,
    Autocomplete,
    Cliente,
  },
  data() {
    return {
      notificacoes_pendentes: [],
      notificacoes_concluidas: [],
      statusNotificacao: {
        PENDENTE: "Pendente",
        CONCLUIDA: "Concluída"
      },
      notificacao: {
        id: null,
        descricao: null,
        dt_notificacao: null,
        hr_notificacao: null,
        observacao: null,
        status: null,
        cliente_id: null,
        cliente: null,
        created_at: null,
        updated_at: null
      },
      original_status: ''
    };
  },
  created () {
    this.buscarNotificacoesPendentes();
  },
  methods: {
    setNotificacao(noti) {
      if (noti.id) {
        this.notificacao = structuredClone(noti);
        this.original_status = this.getNotificacao().status
      } else {
        this.clearNotificacao()
      }
    },
    getNotificacao() {
      return this.notificacao
    },
    clearNotificacao() {
      this.notificacao = {
        id: null,
        descricao: null,
        dt_notificacao: null,
        hr_notificacao: null,
        observacao: null,
        status: null,
        cliente_id: null,
        cliente: null,
        created_at: null,
        updated_at: null
      }
      this.original_status = null;
    },
    showModal(){
      $(this.$refs.modal_notificacao).modal('show');
    },
    hideModal(){
      $(this.$refs.modal_notificacao).modal('hide');
    },
    getColor(s) {
      for (const st of this.status) {
        if (st.id == s) {
          return st.color;
        }
      }
    },
    confirmarExclusaoNotificacao() {
      var _this = this;
      swal({
        title: "Deseja realmente deletar a notificação?",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#ED5565",
        confirmButtonText: "Deletar",
      }, (confirm) => {
        if (confirm) {
          _this.excluirNotificacao();
        } else {
          _this.clearNotificacao();
        }
      });
      $('.sweet-alert button.cancel').css('background-color','#5a6268');
    },
    excluirNotificacao() {
      callAPI(
        '/notificacoes/' + this.getNotificacao().id,
        'DELETE',
        null,
        (e) => {
          if (this.getNotificacao().status === 'PENDENTE') {
            this.buscarNotificacoesPendentes();
          } else {
            this.buscarNotificacoesConcluidas();
          }
          this.clearNotificacao()
        }
      );
    },
    concluirNotificacao() {
      callAPI(
        '/concluir-notificacao/' + this.getNotificacao().id,
        'PUT',
        null,
        (e) => {
          this.buscarNotificacoesPendentes();
          this.clearNotificacao();
          swal({
            title: "Notificação concluída!",
            type: "success",
            confirmButtonColor: "#1ab394",
          });
        }
      );
    },
    salvarNotificacao(data) {
      if (this.getNotificacao().id) {
        callAPI(
          '/notificacoes/' + this.getNotificacao().id,
          'PUT',
          data,
          (e) => {
            this.hideModal();
            this.original_status === 'PENDENTE' ? this.buscarNotificacoesPendentes() : this.buscarNotificacoesConcluidas()
            this.clearNotificacao();
          }
        );
      } else {
        callAPI(
          '/notificacoes',
          'POST',
          data,
          (e) => {
            this.hideModal();
            this.buscarNotificacoesPendentes();
            this.clearNotificacao();
          }
        )
      }
    },
    buscarNotificacoesPendentes() {
      callAPI(
        '/notificacoes-painel',
        'GET',
        {status: 'PENDENTE'},
        (e) => {
          this.notificacoes_pendentes = e;
        }
      );
    },
    buscarNotificacoesConcluidas() {
      callAPI(
        '/notificacoes-painel',
        'GET',
        {status: 'CONCLUIDA'},
        (e) => {
          this.notificacoes_concluidas = e;
        }
      );
    }
    //   if ( this.notificacoes.length > 0 ) {
    //     return this.notificacoes.filter(n => n.status == status);
    //   }
      
    //   return [];
    // },
  },
};
</script>

<style scoped>
  .table-dashed td {
    text-decoration-line: line-through;
  }
</style>