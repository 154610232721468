<template>
  <div :class="(css ? css : (columnGroup ? (columnGroup == 3 ? 'col-4' :
                                        (columnGroup == 2 ? 'col-6' : 'col-12')) :
                                        'col-12 col-md-6') + ' mb-3 form-group')">
    <label v-if="label">{{ label }}</label>
    <input
      :id="inputId ? inputId : ''"
      :type="type ? type : 'text'"
      class="form-control"
      :style="inputstyle"
      v-model="value"
      :placeholder="placeholder"
      :autocomplete="type == 'password' ? 'new-password' : 'off'"
      @keyup.enter="$event.target.blur()"
    />
    
    <label
      class="error d-block"
      v-for="(e, i) in erro"
      :key="i"
    >
      {{ e }}
    </label>
  </div>
</template>

<script>

export default {
  props: ["inputId", "typeini", "labelini", "valueini", "css", "placeholder", "errors", "columnGroup", "inputstyle"],
  data() {
    return {
      label: this.labelini,
      value: this.valueini,
      erro: this.errors,
      type: this.typeini,
    };
  },
  watch: {
    valueini(){
         this.value = this.valueini;
    },
    value(v) {
      if (v == "") {
        v = null;
      }
      this.$emit("change", v);
    },
    errors(v) {
      this.erro = v;
    }
  },
};
</script>