<template>
  <div
    :class="(css ? css : 'col-12 col-md-6 col-lg-4 col-xl-3') + ' p-3'"
    @click="$emit('click', $event)"
  >
    <div class="painel-card-simples">
      <h5>{{ titulo }}</h5>
      <div class="d-flex" v-if="!ready">
        <div class="load">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else>
        <div v-if="erro" class="text-center">
          <h6>Um erro ocorreu</h6>

          <button
            style="font-size: 20px"
            class="app-button app-button-primary"
            @click="buscar"
          >
            <i class="fa fa-redo"></i>
          </button>
        </div>
        <div class="painel-card-simples-body" v-else>
          <span>{{ value }}</span>
          <i :class="icon" style="font-size: 35px"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  
  props: ["css", "tituloini", "path", "data", "iconinit", "click"],
  data() {
    return {
      titulo: this.tituloini,
      ready: false,
      erro: false,
      value: {},
      icon: this.iconinit,
    };
  },
  methods: {
    buscar() {
      this.ready = false;
      callAPI(this.path, 'GET', null, (e) => {
          this.value = e.value,
          this.ready = true,
          this.erro = false
        });
    },
  },
  created() {
    this.buscar();
  },
};
</script>

<style>
.painel-card-simples {
  background-color: var(--thema2);
  height: 150px;
  box-shadow: 0px 0px 8px 0px var(--thema7);
  border-radius: 5px;
  padding: 20px;
}
.painel-card-simples span {
  font-size: 28px;
  font-weight: 600;
  width: calc(100% - 70px);
  display: inline-block;
  margin-left: 10px;
}

.painel-card-simples-body {
  line-height: 65px;
  /* color: var(--font3); */
}
</style>
