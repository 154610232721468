<template>
  <Layout>
    <template slot="body">
      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-title" style="padding-right: 15px;">
              <h3>{{ title }}</h3>
            </div>
            <div class="ibox-content">
              <div class="row">
                <slot name="form"></slot>
              </div>
            </div>
            <div class="ibox-title" style="border-style: none solid solid; padding: 20px;">
              <button
                type="button"
                class="btn btn-primary"
                @click="gerar()"
              >
                Gerar
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "../../components/layout/Layout.vue";

export default {
  components: {
    Layout,
  },
  props: {
    title: String,
    path: String,
    model: Object
  },
  methods: {
    gerar() {
      callAPI(
        this.path,
        'GET',
        this.model,
        (response)=>{
          window.open(loadRecurso(response));
        }
      );
    }
  },
};
</script>