<template>
  <div class="row">
    <Data
      ref="dt_aprovacao"
      labelini="Aprovado"
      :valueini="processo.dt_aprovacao"
      :showsum="true"
      @change="processo.dt_aprovacao = $event"
    />
    <Data
      ref="dt_faturamento"
      labelini="Faturado"
      :valueini="processo.dt_faturamento"
      :showsum="true"
      @change="processo.dt_faturamento = $event"
    />
    <Money
      css="col-6"
      labelini="Percentual de Retenção"
      :valueini="processo.percentual_retencao"
      :changeeventini="true"
      @change="processo.percentual_retencao = $event"
    />
    <Favorito
      ref="favorito"
      labelini="Favorito"
      :valueini="processo.favorito"
      @change="processo.favorito = $event"
    />
    <div class="col-12">
      <div style="border: 1px solid rgb(226, 226, 226); border-radius: 5px;">
        <ResumoProcesso :processo="modelView" v-if="modelView != null" />
      </div>
    </div>
  </div>
</template>

<script>
import Favorito from "../crud/input/Favorito.vue";
import Data from "../crud/input/Data.vue";
import ResumoProcesso from "../custom/ResumoProcesso.vue";
import Money from "../crud/input/Money.vue";

export default {
  props: ["processo"],
  components: {
    Favorito,
    Data,
    ResumoProcesso,
    Money
  },
  data() {
    return {
      model: {},
      total: 0,
      valor_servico : 0,
      formasPagamento: [],
      modelo_id: null,
    };
  },
  computed: {
    modelView() {
      if (this.formasPagamento == null || this.formasPagamento.length == 0) {
        return null;
      }

      var processoView = this.processo;

      processoView.valor_total = this.processo.pagamento[0].vl_total;
      processoView.processo_servicos = this.processo.servico;
      processoView.processo_taxas = this.processo.taxas;
      processoView.processo_perguntas = this.processo.perguntas;
      processoView.processo_pagamentos = this.processo.pagamento;

      for (const servico of processoView.processo_servicos) {
        servico.servico = {
          nome: servico.nome
        }
      }

      for (const taxa of processoView.processo_taxas) {
        taxa.taxa = {
          nome: taxa.nome
        }
      }

      for (const pergunta of processoView.processo_perguntas) {
        pergunta.pergunta = {
          nome: pergunta.nome
        }
      }

      for (const pagamento of processoView.processo_pagamentos) {
        pagamento.forma_pagamento = {
          nome: this.getFormaPagamento(pagamento.forma_pagamento_id)
        }
      }

      return processoView;
    }
  },
  created() {
    callAPI(
      '/formas-pagamento',
      'GET',
      null,
      (data)=>{
        this.formasPagamento = data.itens;
      }
    );
  },
  methods: {
    getFormaPagamento(forma_pagamento_id) {
      for (const formaPagamento of this.formasPagamento) {
        if (formaPagamento.id == forma_pagamento_id) {
          return formaPagamento.nome;
        }
      }

      return null;
    }
  }
};
</script>