<template>
  <Layout ref="layout" @show="$emit('show')" :showback="false">
    <template slot="body">
      <ParametroGenerico ref="model" @back="$refs.layout.back($event)" />
    </template>
  </Layout>
</template>

<script>
import Layout from "../../../components/layout/Layout.vue";
import ParametroGenerico from "../../../components/cadastro/ParametroGenerico.vue";
export default {
  components: {
    Layout,
    ParametroGenerico,
  },
};
</script>