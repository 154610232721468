<template>
  <Crud
    ref="crud"
    :path="config.path"
    :novo="config.novo"
    :list="config.list"
    :form="config.form"
    :datatable="datatable"
    :download="download"
    :modal="true"
    :formonly="formonly"
    @closeform="$emit('closeform')"
    @list="$emit('back', false)"
    @edit="
      nav = 'conta';
      model = $event;
      $emit('back', true);
    "
    @novo="
      nav = 'conta';
      model = {};
      $emit('back', true);
    "
    @save="save"
    @create="create"
    @fail="setErros"
  >
    <template slot="form">
      <div class="row">
        <Texto
          css="col-12"
          ref="nome"
          labelini="Nome *"
          :valueini="model.nome"
          @change="model.nome = $event"
        />
      </div>
    </template>
  </Crud>
</template>

<script>
import Crud from "../crud/Crud.vue";
import Texto from "../crud/input/Text.vue";
export default {
  props: ["formonly"],
  data() {
    return {
      nav: "conta",
      config: {
        novo: "Nova",
        list: "Formas de pagamento",
        form: "Forma de pagamento",
        path: "/formas-pagamento",
      },
      campos: ["nome"],
      model: {},
      list: [],
      download: [
        {
          label: "Cód.",
          objeto: "id",
          usar: true,
        },
        {
          label: "Nome",
          objeto: "nome",
          usar: true,
        },
        {
          label: "Criado",
          objeto: "created",
          cast: function (e) {
            return new Date(e).toLocaleString();
          },
          usar: true,
        },
        {
          label: "Atualizado",
          objeto: "updated",
          cast: function (e) {
            return new Date(e).toLocaleString();
          },
        },
      ],
      datatable: {
        campos: [
          {
            label: "Cód.",
            objeto: "id",
            mobile: false,
            numeric: true,
          },
          {
            label: "Nome",
            objeto: "nome",
          },
          {
            label: "Criado",
            objeto: "created",
            mobile: false,
            cast: function (e) {
              return new Date(e).toLocaleString();
            },
          },
          {
            label: "Editar",
            labelMobile: false,
            icone: "fas fa-edit",

            head: {
              style: {
                "min-width": "50px",
                "text-align": "center",
              },
            },
            body: {
              style: {
                color: "var(--edit)",
                cursor: "pointer",
                "text-align": "center",
              },
            },

            click: "edit",
          },
          {
            label: "Deletar",
            labelMobile: false,
            icone: "fas fa-trash",
            head: {
              style: {
                "min-width": "50px",
                "text-align": "center",
              },
            },
            body: {
              style: {
                color: "var(--delete)",
                cursor: "pointer",
                "text-align": "center",
              },
            },
            click: "delete",
          },
        ],
      },
    };
  },
  methods: {
    setErros(e) {
      for (const ca of this.campos) {
        var erro = e[ca];
        this.$refs[ca].erro = erro;
      }
    },
    save() {
      this.$refs.crud.save(this.model);
    },
    create() {
      this.$refs.crud.create(this.model);
    },
  },
  mounted() {},
  components: {
    Crud,
    Texto,
  },
};
</script>