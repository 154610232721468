<template>
  <div class="h-100">
    <div class="row h-100 mx-0">
      <div class="col-md-4 login-div col-12">
        <div class="mx-lg-5 mx-md-1 mx-3">
          <div class="my-4 d-flex">
            <img
              src="/img/logo.jpeg"
              alt=""
              style="border-radius: 10px"
              class="m-auto"
              height="200"
              width="200"
            />
          </div>
          <div>
            <div class="form-group mb-3">
              <label class="text-white"
                ><strong>Usuário ou email</strong></label
              >
              <input
                type="text"
                class="form-control mt-2"
                @keyup.enter="$refs.pass.focus()"
                v-model="login"
              />
              <h6 class="text-danger mt-3" v-if="temErro">
                Usuário ou senha incorretos
              </h6>
            </div>
            <div class="form-group mb-3">
              <label class="text-white"><strong>Senha</strong></label>
              <input
                ref="pass"
                type="password"
                class="form-control mt-2"
                v-model="senha"
                @keyup.enter="acessar"
              />
            </div>
          </div>
          <div>
            <a class="link-primary" href="/esqueceu-senha">Esqueceu a senha?</a>
          </div>
          <button
            class="app-button app-button-primary mt-4 py-2 w-100 mb-4"
            @click="acessar"
          >
            Acessar
          </button>
          <div style="margin-top: 20px; color: white; text-align: center">
            <a class="link-primary" @click="$router.push('/termo')"
              >Termos e Condições do Malcon</a
            >
            <a class="link-primary mt-3" @click="$router.push('/politica')"
              >Política de Privacidade</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-8 col-12 d-none d-md-block p-0">
        <img src="/img/login.jpg" alt="" class="h-100 w-100" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      login: "",
      senha: "",
      temErro: false,
      formData: {
        login: "",
        password: "",
      }
    };
  },
  methods: {
    acessar() {
      this.temErro = false;
      this.formData.login = this.login;
      this.formData.password = this.senha;
      callAPI("/auth/login", "POST", this.formData, this.sucesso, this.fail, this.erro);

    },
    sucesso(dados) {
      localStorage.setItem("token", dados.request.token);
      this.sendHome();
    },
    fail(dados) {
      this.temErro = true;
    },
    erro(dados) {
      swal({
        title: "Erro inesperado!",
        text: "Entre em contato com o suporte.",
        confirmButtonColor: "#ED5565",
        type: "error",
      });
    },
    sendHome() {
      window.location = '/';
    }
  },
};
</script>

<style>
.login-div {
  background-color: var(--thema1);
  max-height: 100vh;
  overflow: auto;
}
@media (max-width: 600px) {
  .qrcode-div {
    display: none;
  }
  .login-div {
    padding-top: 15%;
  }
}
</style>