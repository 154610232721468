<template>
  <div>
    <div class="wizard-header">
      <div
        class="wizard-barra"
        :style="
        
          'width: calc(100% - ' +
          100 / etapas.length +
          '%); margin-left: ' +
          (100 / etapas.length) / 2 +
          '%'
        "
      >
        <div class="wizard-barra-etapa" :style="'width: ' + barraWidth"></div>
      </div>
      <div
        
        v-for="(etapa, i) in etapas"
        :key="i"
        :class="'wizard-etapa' + (step >= i ? ' active' : '')"
        :style="'width: ' + size + '%'"
      >
        <div class="m-auto">
          <div class="wizard-action" @click="step = i"></div>
          <span :class="'wizard-atual' + (step == i ? ' active' : '')">
            {{ etapa }}
          </span>
        </div>
      </div>
    </div>

    <div class="row" style="padding: 0px 15px 0px 15px">
      <div class="col-4">
        <button
          class="btn btn-secondary"
          :disabled="step == 0"
          @click="step--"
        >
          Anterior
        </button>
      </div>
      <div class="col-4 text-center">
        <button
          class="btn btn-white"
          @click="$emit('back')"
        >
          Cancelar
        </button>
      </div>
      <div class="col-4 d-inline-flex">
        <button
          v-if="step < etapas.length - 1"
          class="wizard-button-next btn btn-primary"
          @click="step++"
        >
          Próximo
        </button>
        <button
          v-else
          class="wizard-button-next btn btn-primary"
          @click="$emit('finalizar')"
        >
          Finalizar
        </button>
      </div>
    </div>
    <hr>

    <div class="wizard-body">
      <slot name="body" :step="step"></slot>
    </div>

    <hr>
    <div class="row wizard-foot">
      <div class="col-4">
        <button
          class="btn btn-secondary"
          :disabled="step == 0"
          @click="step--"
        >
          Anterior
        </button>
      </div>
      <div class="col-4 text-center">
        <button
          class="btn btn-white"
          @click="$emit('back')"
        >
          Cancelar
        </button>
      </div>
      <div class="col-4 d-inline-flex">
        <button
          v-if="step < etapas.length - 1"
          class="wizard-button-next btn btn-primary"
          @click="step++"
        >
          Próximo
        </button>
        <button
          v-else
          class="wizard-button-next btn btn-primary"
          @click="$emit('finalizar')"
        >
          Finalizar
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["etapasini"],
  data() {
    return {
      etapas: this.etapasini ? this.etapasini : [],
      step: 0,
    };
  },
  methods: {},
  computed: {
    size() {
      return this.etapas.length > 0 ? 100 / this.etapas.length : 0;
    },
    barraWidth() {
      if (this.etapas.length == 0) {
        return "0px";
      }
      var size = 100 / (this.etapas.length - 1);
      var m = size / 2;
      var s =  size * this.step;
      if (s > 100) {
        s = 100;
      }
      return s + "%";
    },
  },
  mounted() {},
  watch: {
    step(b) {
      this.$emit('proximo', this.step);
    }
  }
};
</script>

<style>
.wizard-header {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}
.wizard-barra {
  content: "";
  height: 8px;
  background-color: var(--thema3);
  position: absolute;
  top: 11px;
  z-index: 0;
}
.wizard-barra-etapa {
  background-color: var(--thema1);
  height: 100%;
  border: 1px var(--thema1) solid;
  transition: all 0.1s ease-out;
}
.wizard-etapa {
  display: inline-flex;
}
.wizard-action {
  background-color: var(--thema3);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: block;
  margin: auto;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.wizard-atual {
  font-size: 14px;
  transition: all 0.1s;
  line-height: 18px;
}
.wizard-atual.active {
  font-weight: bold;
}
.wizard-etapa.active .wizard-action {
  background-color: var(--thema1) !important;
}
.wizard-action::before {
  content: "";
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  transform: translate(10px, 10px);
}
.wizard-body {
  padding: 15px;
}
.wizard-foot {
  margin-top: 10px;
  padding: 0px 15px 0px 15px;
}
.wizard-button-next {
  right: 0px;
  margin-left: auto;
  margin-right: 0px;
  position: relative;
}
@media (max-width: 600px) {
  .wizard-atual {
    font-size: 12px;
  }
  .wizard-atual.active {
    font-size: 14px;
  }

  .wizard-foot {
    position: fixed;
    bottom: 85px;
    left: 0px;
    width: 100%;
    margin: 0px;
  }
}
</style>