<template>
  <div :class="css ? css : 'col-12 mb-3 form-group'">
    <label v-if="label">{{ label }}</label>
    <div class="input-group">
      <select :id="id ? id : null" class="form-control" v-model="value">
        <option v-if="vazio" :value="null" @click="$emit('changeObject', null)">{{ vazio }}</option>
        <option v-for="(v, i) in values" :key="i" :value="campo ? v.id : i" @click="$emit('changeObject', v)">
          {{ campo ? v[campo] : v }}
        </option>
      </select>
      <div class="input-group-append" v-if="btnnovo" @click="novo = true">
        <button class="app-button app-button-primary">
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    
    <label
      class="error d-block"
      v-for="(e, i) in erro"
      :key="i"
    >
      {{ e }}
    </label>
    <slot v-if="novo" name="novo"> </slot>
  </div>
</template>

<script>
export default {
  props: [
    "vazio",
    "path",
    "labelini",
    "valueini",
    "campo",
    "valuesini",
    "css",
    "btnnovo",
    "errors",
    "id"
  ],
  data() {
    return {
      value: this.valueini ? this.valueini : null,
      values: this.valuesini ? this.valuesini : [],
      label: this.labelini,
      erro: this.errors,
      novo: false,
    };
  },
  watch: {
    value(v) {
      this.$emit("change", v);

      if (this.campo) {
        for (const object of this.values) {
          if (object.id == v) {
            this.$emit('changeObject', object);
          }
        }
      }
    },
    valueini(v) {
      this.value = this.valueini;
    },
    valuesini(v) {
      if (this.valuesini != null) {
        this.values = this.valuesini;
      }
    },
    novo(n) {
      if (!n) {
        this.buscar();
      }
    },
    errors(v) {
      this.erro = v;
    }
  },
  methods: {
    buscar() {
      try {
        callAPI(
          this.path,
          'GET',
          null,
          (e) => {
            this.values = e.itens;
          }
        );
      } catch ($e) {}
    },
  },
  created() {
    if (this.path != null) {
      this.buscar();
    }
  },
};
</script>