<template>
  <div :class="css ? css : 'col-12 col-md-6 form-group'">
    <label v-if="label">{{ label }}</label>
    <input
      class="form-control"
      v-model="value"
      style="text-align: right"
      @keyup.enter="$event.target.blur()"
      @blur="formatar"
      @change="
        $emit('change', value == null || value == '' ? null : Number(value.replace(',', '.')))
      "
    />

    <label
      class="error d-block"
      v-for="(e, i) in erro"
      :key="i"
    >
      {{ e }}
    </label>
  </div>
</template>

<script>
export default {
  props: ["labelini", "valueini", "css", "changeeventini", "errors"],
  data() {
    return {
      label: this.labelini,
      value: this.formatValueIni(this.valueini),
      erro: this.errors,
    };
  },
  methods: {
    formatValueIni(value) {
      if (this.valueini) {
        var temp = this.valueini.toString();
        var dot = temp.indexOf(".");
        
        if (dot == -1) {
          dot = temp.indexOf(",");
        }

        if (dot == -1) {
          temp = temp + ".00";
        } else if (dot > 0 && dot == temp.length - 2) {
          temp = temp + "0";
        }

        return temp.replace('.', ',');
      } else {
        return null;
      }
    },
    formatar() {
      if (this.value != null && this.value !== "") {
        var dot = this.value.indexOf(".");

        if (dot == -1) {
          dot = this.value.indexOf(",");
        }

        if (dot == -1) {
          this.value = this.value + ",00";
        } else if (dot > 0 && dot == this.value.length - 2) {
          this.value = this.value.replace('.', ',') + "0";
        }
      }
    },
    setValue(value) {
      value = value + "";
      var dot = value.indexOf(".");

      if (dot != -1 && value.length - dot > 3) {
        value = value.substring(0, dot + 3);
      }
      this.value = value.replace('.', ',');
      this.formatar();
      this.$emit(
        "change",
        this.value == null || this.value == "" ? null : Number(this.value.replace(',', '.'))
      );
    },
  },
  mounted() {
    this.formatar();
    if (this.changeeventini) {
      this.$emit(
        "change",
        this.value == null || this.value == "" ? null : Number(this.value.replace(',', '.'))
      );
    }
  },
  watch: {
    value(input) {
      if (input) {
        input = input.replace(/\D/g,'');
        var temp = '';

        for ( var i = 0; i < input.length; i++ ) {
          if (input.length > 2 && i == input.length - 2) {
            temp += ',';
          }

          temp += input.charAt(i);
        }

        this.value = temp;
      }
    },
    valueini(v) {
      this.value = this.formatValueIni(this.valueini);
    },
    errors(v) {
      this.erro = v;
    }
  },
};
</script>