var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:(_vm.formonly ? ' formonly' : '')},[(!_vm.formonly)?_c('List',{directives:[{name:"show",rawName:"v-show",value:(_vm.acao == 'list' || _vm.modal),expression:"acao == 'list' || modal"}],ref:"list",attrs:{"novo":_vm.novo,"path":_vm.path,"nome":_vm.list,"btnnovo":_vm.btnnovo,"customfilter":_vm.customfilter,"filtrosini":_vm.filtrosini,"datatableinit":_vm.datatable ? true : false},on:{"novo":function($event){_vm.edit = false;
      _vm.acao = 'form';
      _vm.$emit('novo');},"buscando":function($event){_vm.$refs.datatable ? _vm.$refs.datatable.buscando($event) : null;
      _vm.$emit('buscando', $event);},"list":function($event){_vm.setaDatatable($event);
      _vm.$emit('list', $event);}}},[_c('template',{slot:"body"},[(_vm.datatable)?_c('Datatable',{ref:"datatable",attrs:{"filtrosini":_vm.filtrosini,"config":_vm.datatable,"showView":_vm.showView,"showClone":_vm.showClone,"showEdit":_vm.showEdit,"showDelete":_vm.showDelete},on:{"view":function($event){return _vm.$emit('view', $event);},"edit":function($event){_vm.$emit('edit', $event);
          _vm.acao = 'form';
          _vm.edit = true;},"delete":function($event){_vm.$emit('delete', $event);
          _vm.del = $event;},"clone":function($event){_vm.$emit('clone', $event);
          _vm.acao = 'form';
          _vm.edit = false;},"customEvent1":function($event){return _vm.$emit('customEvent1', $event);},"customEvent2":function($event){return _vm.$emit('customEvent2', $event);},"pagination":function($event){_vm.$refs.list.filtros = $event},"filtrar":function($event){_vm.$refs.list.filtros = $event;
          _vm.$refs.list.buscar();}}},[_c('template',{slot:"customFilter"},[_vm._t("customFilter")],2)],2):_vm._e(),_vm._t("list")],2)],2):_vm._e(),(_vm.acao == 'form')?_c('Form',{attrs:{"edit":_vm.edit,"nome":_vm.form,"modal":_vm.modal,"savebutton":_vm.savebutton},on:{"back":function($event){_vm.acao = 'list';
      _vm.checkFormonly();},"save":function($event){return _vm.$emit('create')},"edit":function($event){return _vm.$emit('save')}}},[(!_vm.modal)?_c('template',{slot:"body"},[_vm._t("form")],2):_c('template',{slot:"body"},[_vm._t("form")],2)],2):_vm._e(),_c('div',{ref:"modal",staticClass:"modal inmodal fade",attrs:{"tabindex":"-1","role":"dialog","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),(_vm.datatable && _vm.del)?_c('div',{staticClass:"modal-body"},_vm._l((_vm.getCampos),function(c,k){return _c('h4',{key:k},[_vm._v(" "+_vm._s(c.label)+": "+_vm._s(_vm.cast(_vm.del, c))+" ")])}),0):_vm._e(),_c('div',{staticClass:"modal-footer"},[(!_vm.deletando)?_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){_vm.del = null}}},[_vm._v(" Cancelar ")]):_vm._e(),_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button","disabled":_vm.deletando},on:{"click":function($event){return _vm.obdelete(_vm.del.id)}}},[_vm._v(" "+_vm._s(_vm.deletando ? "Deletando..." : "Deletar")+" ")])])])])]),_c('Notification',{ref:"notification"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Deseja realmente deletar o registro?")])])}]

export { render, staticRenderFns }