import Notificacoes from '../views/painel/Notificacoes.vue'
import Operacional from '../views/painel/Operacional.vue'
import Gerencial from '../views/painel/Gerencial.vue'
import Geral from '../views/painel/Geral.vue'
import ImprimirResumoProcesso from '../views/painel/ImprimirResumoProcesso.vue'

const routes = [
    {
        path: '/notificacoes',
        name: 'Notificações',
        component: Notificacoes,
    },{
        path: '/operacional',
        name: 'Operacional',
        component: Operacional,
    }, {
        path: '/gerencial',
        name: 'Gerencial',
        component: Gerencial,
    }, {
        path: '/geral',
        name: 'Geral',
        component: Geral,
    }, {
        path: '/imprimir-resumo-processo/:processoId',
        name: 'ImprimirResumoProcesso',
        component: ImprimirResumoProcesso,
        props: true,
    }
];

export default routes