<template>
  <div class="col-12">
    <div class="card pt-3">
      <!-- <div class="card-header card-header-icon">
      <div class="card-icon">
        <i class="material-icons">description</i>
      </div>
      <h4 class="card-title">{{ title }}</h4>
    </div> -->
      <div class="card-body" v-if="!reading">
        <div
          style="border: 2px dashed #4caf50; height: 200px; display: flex"
          @click="$refs.input.click()"
          @drop="
            $event.preventDefault();
            directory
              ? arquivosWebkit($event.dataTransfer.items)
              : arquivo($event.dataTransfer.files);
          "
          @dragover="$event.preventDefault()"
        >
          <div style="margin: auto">
            <div>
              <h4 v-if="file" style="text-align: center">{{ file }}</h4>
              <h5 v-else style="text-align: center">
                Formatos: {{ formatos }}
              </h5>
            </div>
          </div>
        </div>

        <input
          ref="input"
          type="file"
          style="display: none"
          :accept="accept"
          directory
          multiple
          webkitdirectory
          v-if="directory"
          @change="
            $event.preventDefault();
            arquivos($event.target.files);
          "
        />

        <input
          ref="input"
          type="file"
          style="display: none"
          :accept="accept"
          v-else
          @change="
            $event.preventDefault();
            arquivo($event.target.files);
          "
        />
      </div>
      <div v-else class="card-body">
        <div class="d-flex" style="height: 200px">
          <div class="text-center m-auto">
            <h5>Carregando...</h5>
            <div class="load">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    accept: String,
    formatos: String,
    title: String,
    directory: Boolean,
  },
  data() {
    return {
      file: null,
      reading: false,
    };
  },
  methods: {
    arquivosWebkit(e) {
      this.reading = true;
      for (var i = 0; i < e.length; i++) {
        var item = e[i].webkitGetAsEntry();
        this.scanFile(
          item,
          null,
          0,
          (data) => {
            this.reading = false;
            this.file = Object.keys(data)[0];
            this.$emit("dados", data);
          },
          {}
        );
        this.file = item.name;
      }
    },
    scanFiles(files, p, end, ob) {
      if (files != null && p < files.length) {
        var item = files[p];
        this.scanFile(item, files, p, end, ob);
      } else {
        end(ob);
      }
    },
    scanFile(item, files, p, end, ob) {
      if (item.isDirectory) {
        var directoryReader = item.createReader();
        var key = item.name;
        directoryReader.readEntries((entries) => {
          this.scanFiles(
            entries,
            0,
            (r) => {
              ob[key] = r;
              this.scanFiles(files, p + 1, end, ob);
            },
            {}
          );
        });
      } else {
        item.file((e) => {
          var key = e.name;
          this.toString(e, (d) => {
            ob[key] = d;
            this.scanFiles(files, p + 1, end, ob);
          });
        });

        // this.toString(item, (e) => {
        //   debugger
        // })
      }
    },
    readFiles(files, p, end, ob) {
      if (p < files.length) {
        this.readFile(files[p], files, p, end, ob);
      } else {
        end(ob);
      }
    },
    readFile(f, files, p, end, ob) {
      var path = f.webkitRelativePath.split("/");
      this.toString(f, (e) => {
        var n = ob;
        for (var i = 0; i < path.length - 1; i++) {
          var pa = path[i];
          if (n[pa] === undefined) {
            n[pa] = {};
          }
          n = n[pa];
        }
        n[f.name] = e;
        this.readFiles(files, p + 1, end, ob);
      });
    },
    arquivos(e) {
      this.reading = true;
      this.readFiles(
        e,
        0,
        (data) => {
          this.file = Object.keys(data)[0];
          this.$emit("dados", data);
          this.reading = false;
        },
        {}
      );
      // this.file = e[0].name;
      // this.toString(e[0], (data) => {
      //   this.$emit("dados", data);
      // });
    },
    arquivo(e) {
      this.reading = true;
      this.file = e[0].name;
      this.toString(e[0], (data) => {
        this.$emit("dados", { name: this.file, data });
        this.reading = false;
      });
    },
    toString(file, call) {
      var reader = new FileReader();

      reader.onload = function (e) {
        var data = e.target.result;
        call(data);
      };

      reader.readAsBinaryString(file);
    },
  },
};
</script>
