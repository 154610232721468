<template>
  <Layout
    ref="layout"
    @show="$emit('show')"
    :showback="false"
  >
    <template slot="body">
      <Crud
        ref="crud"
        :path="config.path"
        :novo="config.novo"
        :list="config.list"
        :form="config.form"
        :filtrosini="filtros"
        :datatable="datatable"
        :savebutton="false"
        :customfilter="customfilter"
        :showView="true"
        @view="visualizar($event)"
        @list="$refs.layout.back(false)"
        @edit="loadProcesso($event)"
        @novo="novoProcesso()"
        @clone="clone"
        @fail="setErros"
        @customEvent1="aprovarProcesso($event);"
        @customEvent2="faturarProcesso($event);"
      >
        <template slot="customFilter">
          <div class="row col-12 mb-3">
            <div class="col-5">
              <span class="font-weight-bold">Status: </span>
              <div class="btn-group pl-2">
                <button
                  @click="filtrarPendente()"
                  :class="'btn ' + (customfilter.status.value == 'PENDENTE' ? 'btn-primary' : 'btn-white')"
                  style="width: 150px"
                >
                  Ativos
                </button>
                <button
                  @click="filtrarConcluido()"
                  :class="'btn ' + (customfilter.status.value == 'CONCLUIDO' ? 'btn-primary' : 'btn-white')"
                  style="width: 150px"
                >
                  Concluídos
                </button>
              </div>
            </div>
            <div class="col-7">
              <span class="font-weight-bold">Aprovação: </span>
              <div class="btn-group pl-2">
                <button
                  @click="filtrarAprovado(null)"
                  :class="'btn ' + (customfilter.aprovado.value == null ? 'btn-primary' : 'btn-white')"
                  style="width: 150px"
                >
                  Todos
                </button>
                <button
                  @click="filtrarAprovado(true)"
                  :class="'btn ' + (customfilter.aprovado.value == true ? 'btn-primary' : 'btn-white')"
                  style="width: 150px"
                >
                  Aprovados
                </button>
                <button
                  @click="filtrarAprovado(false)"
                  :class="'btn ' + (customfilter.aprovado.value == false ? 'btn-primary' : 'btn-white')"
                  style="width: 150px"
                >
                  Não Aprovados
                </button>
              </div>
            </div>
          </div>
        </template>
        <template slot="form">
          <div>
            <Wizard
              ref="etapas"
              :etapasini="[
                'Dados',
                'Serviços',
                'Taxas',
                'Perguntas',
                'Pagamento',
                'Resumo',
              ]"
              @finalizar="finalizar"
              @proximo="validacao($event)"
              @back="
                $refs.crud.acao = 'list';
              "
            >
              <template slot="body" slot-scope="{ step }">
                <div>
                  <div class="row" v-show="step == 0">
                    <Autocomplete
                      id="cliente_id"
                      ref="cliente_id"
                      labelini="Cliente *"
                      :valueini="model.cliente_id"
                      path="/clientes"
                      :characteres="1"
                      :btnnovo="true"
                      :campo="[
                        'nome',
                        { valor: 'id', numerico: true }
                      ]"
                      @change="
                        model.cliente_id = $event;
                        dadosCli($event);
                      "
                      @changeObject="
                        model.cliente = $event;
                      "
                      :cast="
                        (ob) => {
                          return (
                            ob.nome +
                            ' - ' +
                            (ob.cpf_cnpj != null ? ob.cpf_cnpj : '...') +
                            ' - Pessoa ' +
                            (ob.tipo == 'F' ? 'Física' : 'Júridica')
                          );
                          
                        }
                      "
                    >
                      <template slot="novo">
                        <Cliente :formonly="true" @closeform="novoCliente($event)" />
                      </template>
                      
                    </Autocomplete>

                    <TextoArea
                      css="col-12"
                      :rowsini="3"
                      labelini="Dados da síntese"
                      :valueini="model.sintese"
                      @change="model.sintese = $event"
                    />

                    <div class="col-6 px-0">
                      <TextAreaEndereco
                        css="col-12"
                        :rowsini="7"
                        ref="dados_cliente"
                        labelini="Dados do Cliente"
                        :valueini="dados_cliente"
                      />

                      <TextoArea
                        css="col-12"
                        :rowsini="2"
                        labelini="Observação"
                        :valueini="model.observacao"
                        @change="model.observacao = $event"
                      />
                    </div>

                    <div class="col-6 px-0">
                      <Metragem
                        id="metragem"
                        css="form-group col-12 mb-2"
                        ref="metragem"
                        labelini="Metragem do Imóvel"
                        :valueini="model.metragem"
                        @change="model.metragem = $event"
                      />
                    
                    <Inscricao
                      id="inscricao_imovel"
                      css="form-group col-12 mb-2"
                      ref="inscricao"
                      labelini="Inscrição do Imóvel"
                      :valueini="model.inscricao_imovel"
                      @change="model.inscricao_imovel = $event"
                    />
                    
                    <Data
                      :columnGroup="1"
                      ref="dt_validade"
                      labelini="Data de validade do processo"
                      :valueini="model.dt_validade"
                      @change="model.dt_validade = $event"
                    />

                    <Data
                      :columnGroup="1"
                      ref="dt_conclusao"
                      labelini="Data prevista da conclusão"
                      :valueini="model.dt_conclusao"
                      @change="model.dt_conclusao = $event"
                    />

                    </div>
                  </div>

                  <Servico
                    ref="servicos_step"
                    v-show="step == 1"
                    :processo="model"
                    :cloneprocesso="cloneProcessoId"
                    @change="
                      model.servico = $event;
                      $refs.pagamento.atualizaValores(
                        model.taxas,
                        model.servico
                      );
                    "
                  ></Servico>

                  <Taxa
                    ref="taxas_step"
                    v-show="step == 2"
                    :processo="model"
                    :cloneprocesso="cloneProcessoId"
                    @change="
                      model.taxas = $event;
                      $refs.pagamento.atualizaValores(
                        model.taxas,
                        model.servico
                      );
                    "
                  >
                  </Taxa>

                  <Pergunta
                    ref="perguntas_step"
                    v-show="step == 3"
                    :processo="model"
                    :cloneprocesso="cloneProcessoId"
                    @change="model.perguntas = $event"
                  >
                  </Pergunta>

                  <Pagamento
                    ref="pagamento"
                    v-show="step == 4"
                    :processo="model"
                    @change="model.pagamento = $event"
                  >
                  </Pagamento>

                  <Finalizacao
                    :processo="model"
                    v-if="step == 5"
                    ref="finalizacao"
                  ></Finalizacao>
                </div>
              </template>
            </Wizard>
          </div>
        </template>
      </Crud>
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        ref="modal_visualizar"
        aria-hidden="true"
      >
        <div style="width: 80%; max-width: none;" class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">Visualizar</h3>
            </div>
            <div class="modal-body" v-if="modelView.id">
              <ResumoProcesso :processo="modelView" />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="app-button app-button-secondary"
                data-dismiss="modal"
                @click="fecharVisualizacao"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "../../../components/layout/Layout.vue";
import Crud from "../../../components/crud/Crud.vue";
import Texto from "../../../components/crud/input/Text.vue";
import DataText from "../../../components/crud/input/DataText.vue";
import Metragem from "../../../components/crud/input/Metragem.vue";
import Inscricao from "../../../components/crud/input/Inscricao.vue";

import TextAreaEndereco from "../../../components/crud/input/TextAreaEndereco.vue";
import Editor from "../../../components/crud/input/Editor2.vue";
import TextoArea from "../../../components/crud/input/TextArea.vue";
import Data from "../../../components/crud/input/Data.vue";
import Collapse from "../../../components/crud/input/Collapse.vue";
import Select from "../../../components/crud/input/Select.vue";
import Autocomplete from "../../../components/crud/input/Autocomplete.vue";
import Wizard from "../../../components/crud/input/Wizard.vue";
import Checkbox from "../../../components/crud/input/Checkbox.vue";
import Servico from "../../../components/custom/Servico.vue";
import Taxa from "../../../components/custom/Taxa.vue";

import Pergunta from "../../../components/custom/Pergunta.vue";
import Pagamento from "../../../components/custom/Pagamento.vue";
import Cliente from "../../../components/cadastro/Cliente.vue";
import Finalizacao from "../../../components/custom/Finalizacao.vue";

import ResumoProcesso from "../../../components/custom/ResumoProcesso.vue";

export default {
  data() {
    return {
      at: true,
      modelProcesso: {},
      cloneProcessoId: null,
      config: {
        novo: "Novo",
        list: "Processos",
        form: "Processo",
        path: "/processos"
      },
      dados_cliente: null,
      campos: [
        "sintese",
        "cliente_id",
        "condicao_pagamento_id",
        "dt_validade",
        "dt_conclusao",
      ],
      filtros: {
        orderBy: {
          favorito: 'DESC'
        },
      },
      customfilter: {
        status: {
          field: "status",
          value: "PENDENTE",
          operator: "="
        },
        aprovado: {
          field: "aprovado",
          value: null,
          operator: "="
        }
      },
      model: {},
      modelView: {},
      datatable: {
        campos: [
          {
            label: "Ticket",
            objeto: "ticket",
            head: {
              style: {
                "width": "100px",
              },
            },
          },
          {
            label: "Cliente",
            objeto: "cliente",
            orderField: "cliente.nome",
            cast: function (e) {
              return e.id + " - " + e.nome;
            },
          },
          {
            label: "Favorito",
            objeto: "favorito",
            showFilter: false,
            cast: function (e) {
              if (e) {
                return '<i class="fas fa-star"></i>';
              } else {
                return '';
              }
            },
            head: {
              style: {
                "width": "100px",
                "text-align": "center",
              },
            },
            body: {
              style: {
                "color" : "#ccc90d",
                "text-align" : "center",
              },
            },
          },
          {
            label: "Aprovado",
            objeto: "dt_aprovacao",
            showFilter: false,
            cast: function (e) {
              if (!e) {
                return '<i class="fas fa-times" style="color: red" title="Não aprovado"></i>';
              } else {
                return '<i class="fas fa-check" style="color: green" title="' + e + '"></i>';
              }
            },
            eventCast: function (e) {
              if (!e.dt_aprovacao) {
                return 'customEvent1';
              } else {
                return '';
              }
            },
            head: {
              style: {
                "width": "100px",
                "text-align": "center",
              },
            },
            body: {
              style: {
                "cursor": "pointer",
                "text-align": "center",
              },
            },
          },
          {
            label: "Faturado",
            objeto: "dt_faturamento",
            showFilter: false,
            cast: function (e) {
              if (!e) {
                return '<i class="fas fa-times" style="color: red" title="Não faturado"></i>';
              } else {
                return '<i class="fas fa-check" style="color: green" title="' + e + '"></i>';
              }
            },
            eventCast: function (e) {
              if (!e.dt_faturamento) {
                return 'customEvent2';
              } else {
                return '';
              }
            },
            head: {
              style: {
                "width": "100px",
                "text-align": "center",
              },
            },
            body: {
              style: {
                "cursor": "pointer",
                "text-align": "center",
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    filtrarPendente() {
      this.customfilter.status.value = 'PENDENTE';
      this.$refs.crud.buscar();
    },
    filtrarConcluido() {
      this.customfilter.status.value = 'CONCLUIDO';
      this.$refs.crud.buscar();
    },
    filtrarAprovado(val) {
      this.customfilter.aprovado.value = val;
      this.$refs.crud.buscar();
    },
    loadProcesso(processo) {
      this.$refs.layout.back(true);
      this.model = {};

      setTimeout(() => {
        this.model = processo;
      }, 100);
    },
    validacao(step) {
      if (this.model.cliente_id == null || this.model.cliente_id == '') {
        document.getElementById('cliente_id').setCustomValidity('Informe o cliente');
        document.getElementById('cliente_id').reportValidity();
        document.getElementById('cliente_id').focus();
        this.voltarEtapa(0);
        return false;
      } else {
        document.getElementById('cliente_id').setCustomValidity('');
      }

      if (step >= 2) {
        /*if (this.$refs.servicos_step.validacao()) {
          this.voltarEtapa(1);
        }*/
      }

      if (step >= 4) {
        if (this.$refs.perguntas_step.validacao()) {
          this.voltarEtapa(3);
        }
      }

      if (step >= 5) {
        if (this.$refs.pagamento.validacao()) {
          this.voltarEtapa(4);
        }
      }
    },
    voltarEtapa(step) {
      this.$refs.etapas.step = step;
    },
    visualizar(modelView) {
      callAPI(
        '/resumo-processo/' + modelView.id,
        'GET',
        null,
        (data)=>{
          this.modelView = data;
          $(this.$refs.modal_visualizar).modal('show');
        }
      );
    },
    aprovarProcesso(processo) {
      callAPI(
        '/aprovar-processo/' + processo.id,
        'PUT',
        null,
        (data)=>{
          this.$refs.crud.buscar();
          this.$refs.crud.$refs.notification.success('Processo aprovado!');
        }
      );
    },
    faturarProcesso(processo) {
      callAPI(
        '/faturar-processo/' + processo.id,
        'PUT',
        null,
        (data)=>{
          this.$refs.crud.buscar();
          this.$refs.crud.$refs.notification.success('Processo faturado!');
        }
      );
    },
    fecharVisualizacao() {
      this.modelView = {};
      $(this.$refs.modal_visualizar).modal('hide');
    },
    mudarData(e){
      if(e != undefined){
      const tempo = new Date(e);
      const date = (tempo.getDate()+1)+'/'+(tempo.getMonth() + 1 < 10 ? '0' + (tempo.getMonth() + 1 ) : (tempo.getMonth() + 1))+ '/' + tempo.getFullYear();
      const dateTime = date;
      return dateTime;
      }
    },
    dadosCli(e){
      callAPI(
        '/clientes/' + e,
        'GET',
        null,
        (data)=>{
          this.dados_cliente = data[0].dados_endereco
        }
      );
    },
    novoProcesso() {
      var dt_validade = new Date();
      dt_validade.setDate(dt_validade.getDate() + 30);

      var dt_conclusao = new Date();

      for (let i = 1; i <= 30; i++) {
        dt_conclusao.setDate(dt_conclusao.getDate() + 1);
        if (dt_conclusao.getDay() === 6) {
          dt_conclusao.setDate(dt_conclusao.getDate() + 2);
        } else if (dt_conclusao.getDay() === 0) {
          dt_conclusao.setDate(dt_conclusao.getDate() + 1);
        }
      }

      dt_validade = this.modificarData(dt_validade);
      dt_conclusao = this.modificarData(dt_conclusao);
      
      this.$refs.layout.back(true);

      setTimeout(() => {
        this.dados_cliente = "";
        this.model = {
          dt_validade: dt_validade,
          dt_conclusao: dt_conclusao,
          taxas: [],
          tarefas: [],
        };
      }, 100);

      setTimeout(() => {
        this.$refs.servicos_step.limparServicos();
        this.$refs.taxas_step.limparTaxas();
      }, 1000);
    },
    clone(model) {
      var dt_validade = new Date();
      dt_validade.setDate(dt_validade.getDate() + 30);

      var dt_conclusao = new Date();

      for (let i = 1; i <= 30; i++) {
        dt_conclusao.setDate(dt_conclusao.getDate() + 1);
        if (dt_conclusao.getDay() === 6) {
          dt_conclusao.setDate(dt_conclusao.getDate() + 2);
        } else if (dt_conclusao.getDay() === 0) {
          dt_conclusao.setDate(dt_conclusao.getDate() + 1);
        }
      }

      dt_validade = this.modificarData(dt_validade);
      dt_conclusao = this.modificarData(dt_conclusao);

      this.cloneProcessoId = model.id;
      this.dados_cliente = "";

      this.model = {
        sintese: model.sintese,
        dt_validade: dt_validade,
        dt_conclusao: dt_conclusao,
      };
    },
    modificarData(e){
      if(e != undefined){
        const tempo = new Date(e);
        const date = tempo.getFullYear() + '-' + this.dig(tempo.getMonth() + 1) + '-' + this.dig(tempo.getDate());
        const dateTime = date;
        return dateTime;
      }
    },
    dig(v) {
      return v < 10 ? "0" + v : v;
    },
    novoCliente(e) {
      if (e) {
        this.model.cliente_id = e.model.id;
        setTimeout(() => {
          this.$refs.cliente_id.novo = false;
        }, 1000);
      } else {
        this.$refs.cliente_id.novo = false;
      }
    },
    setErros(e) {
      for (const ca of this.campos) {
        var erro = e[ca];
        if (this.$refs[ca]) {
          this.$refs[ca].erro = erro;
        }
      }
    },
    finalizar() {
      this.modelProcesso['id'] = this.model['id']; 
      this.modelProcesso['sintese'] = this.model['sintese'] ? this.model['sintese'] : null; 
      this.modelProcesso['cliente_id'] = this.model['cliente_id'];
      this.modelProcesso['observacao'] = this.model['observacao'] ? this.model['observacao'] : null;
      this.modelProcesso['metragem'] = this.model['metragem'] ? this.model['metragem'] : null;
      this.modelProcesso['inscricao_imovel'] = this.model['inscricao_imovel'] ? this.model['inscricao_imovel'] : null;
      this.modelProcesso['dt_aprovacao'] = this.model['dt_aprovacao'] ? this.model['dt_aprovacao'] : null;
      this.modelProcesso['dt_faturamento'] = this.model['dt_faturamento'] ? this.model['dt_faturamento'] : null;
      this.modelProcesso['dt_conclusao'] = this.model['dt_conclusao'] ? this.model['dt_conclusao'] : null;
      this.modelProcesso['dt_validade'] = this.model['dt_validade'] ? this.model['dt_validade'] : null;
      this.modelProcesso['favorito'] = this.model['favorito'];
      this.modelProcesso['valor_servico'] = this.model.pagamento[0].vl_servico;
      this.modelProcesso['valor_taxa'] = this.model.pagamento[0].vl_taxa;
      this.modelProcesso['valor_total'] = this.model.pagamento[0].vl_total;
      this.modelProcesso['valor_entrada'] = this.model.pagamento[0].valor;
      this.modelProcesso['quantidade_parcelas'] = this.model.pagamento.length;
      this.modelProcesso['percentual_retencao'] = this.model['percentual_retencao'] ? this.model['percentual_retencao'] : null;

      this.modelProcesso['servicos'] = [];
      this.modelProcesso['taxas'] = [];
      this.modelProcesso['perguntas'] = [];
      this.modelProcesso['parcelas'] = [];

      if (this.model.servico) {
        this.model.servico.forEach(this.loadServico);
      }

      if (this.model.taxas) {
        this.model.taxas.forEach(this.loadTaxa);
      }

      if (this.model.perguntas) {
        this.model.perguntas.forEach(this.loadPergunta);
      }

      if (this.model.pagamento) {
        this.model.pagamento.forEach(this.loadParcela);
      }

      callAPI(
        this.modelProcesso.id ? '/processos/' + this.modelProcesso.id : '/processos',
        this.modelProcesso.id ? 'PUT' : 'POST',
        this.modelProcesso,
        e => {
          setTimeout(() => {
            window.location = '/processo'
          }, 500);
        }
      );
    },
    loadServico(item, index) {
      var servico = {};
      
      if (item.processo_servico_id) {
        servico['id'] = item.processo_servico_id;
      }

      servico['servico_id'] = item.id;
      servico['valor'] = item.valor;
      servico['quantidade'] = item.quantidade;
      servico['valor_total'] = item.valor_total;
      servico['ordem'] = index + 1;

      this.modelProcesso['servicos'].push(servico);
    },
    loadTaxa(item, index) {
      var taxa = {};
      
      if (item.processo_taxa_id) {
        taxa['id'] = item.processo_taxa_id;
      }

      taxa['taxa_id'] = item.id;
      taxa['valor'] = item.valor;
      taxa['quantidade'] = item.quantidade;
      taxa['valor_total'] = item.valor_total;
      taxa['ordem'] = index + 1;

      this.modelProcesso['taxas'].push(taxa);
    },
    loadPergunta(item, index) {
      var pergunta = {};

      if (item.processo_pergunta_id) {
        pergunta['id'] = item.processo_pergunta_id;
      }

      pergunta['pergunta_id'] = item.id;
      pergunta['ordem'] = index + 1;
      pergunta['resposta'] = item.resposta;

      this.modelProcesso['perguntas'].push(pergunta);
    },
    loadParcela(item) {
      var pagamento = {};

      if (item.id) {
        pagamento['id'] = item.id;
      }

      pagamento['valor'] = item.valor;
      pagamento['vencimento'] = item.vencimento;
      pagamento['forma_pagamento_id'] = item.forma_pagamento_id;

      this.modelProcesso['parcelas'].push(pagamento);
    },
    sucessoCriar(e) {
      this.$refs.notification.success("Registro criado com sucesso");
      this.acao = "list";
      this.checkFormonly(e);
    },
    currentDateTime() {
      const current = new Date();
      const date = current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate();
      const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      const dateTime = date +' '+ time;
      return dateTime;
    },
    totalDesconto() {
      return this.descontoUni + (this.model.desconto ? this.model.desconto : 0);
    },
    formatMoney(value) {
      return formatMoney(value);
    },
    formatDate(date) {
      return formatDate(date);
    }
  },
  created(){
    this.dados_cliente = ""
  },
  mounted() {},
  components: {
    Crud,
    Texto,
    Layout,
    Select,
    Inscricao,
    Editor,
    Wizard,
    Metragem,
    Autocomplete,
    Collapse,
    Pergunta,
    Checkbox,
    Cliente,
    Finalizacao,
    Data,
    TextAreaEndereco,
    Servico,
    Taxa,
    Pagamento,
    TextoArea,
    DataText,
    ResumoProcesso
  },
};
</script>

<style>
.processo-atividade {
  border: 1px solid var(--thema3);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 8px 0px var(--thema7);
}
</style>
