<template>
  <Layout
    ref="layout"
    @show="$emit('show')"
    :showback="false"
  >
    <template slot="body">
      <Crud
        ref="crud"
        :path="config.path"
        :novo="config.novo"
        :list="config.list"
        :form="config.form"
        :datatable="datatable"
        @list="$refs.layout.back(false)"
        @edit="
          model = $event;
          $refs.layout.back(true);
        "
        @novo="
          model = {};
          $refs.layout.back(true);
        "
        @clone="clone"
        @save="save"
        @create="create"
        @errors="errors = $event"
      >
        <template slot="form">
          <div>
            <div class="row">
              <Texto
                ref="nome"
                labelini="Nome *"
                :valueini="model.nome"
                @change="model.nome = $event"
                :errors="getErrors('nome')"
              />

              <Texto
                ref="login"
                labelini="Login *"
                :valueini="model.login"
                @change="model.login = $event"
                :errors="getErrors('login')"
              />
              <Texto
                ref="password"
                labelini="Senha *"
                :valueini="model.password"
                typeini="password"
                @change="model.password = $event"
                :errors="getErrors('password')"
              />
              <Texto
                ref="email"
                labelini="Email *"
                :valueini="model.email"
                typeini="email"
                @change="model.email = $event"
                :errors="getErrors('email')"
              />
              <Select
              ref="tipo"
              labelini="Tipo *"
              :valueini="model.tipo"
              :valuesini="{ U: 'Usuário', A: 'Admin' }"
              @change="model.tipo = $event"
              :errors="getErrors('tipo')"
              />
            </div>
          </div>
        </template>
      </Crud>
    </template>
  </Layout>
</template>

<script>
import Layout from "../../../components/layout/Layout.vue";
import Crud from "../../../components/crud/Crud.vue";
import List from "../../../components/crud/input/List.vue";
import Texto from "../../../components/crud/input/Text.vue";
import Select from "../../../components/crud/input/Select.vue";
export default {
  data() {
    return {
      config: {
        novo: "Novo",
        list: "Usuários",
        form: "Usuário",
        path: "/usuarios",
      },
      list: [],
      campos: ["nome", "email", "senha", "login", "tipo"],
      model: {},
      errors: null,
      datatable: {
        campos: [
          {
            label: "Cód.",
            objeto: "id",
            mobile: false,
            numeric: true,
            head: {
              style: {
                "width": "80px",
              },
            },
          },
          {
            label: "Nome",
            objeto: "nome",
          },
          {
            label: "Login",
            objeto: "login",
          },
          {
            label: "E-mail",
            objeto: "email",
            mobile: false,
          },
        ],
      },
    };
  },
  methods: {
    getErrors(campo) {
      if (this.errors) {
        return this.errors[campo];
      } else {
        return null;
      }
    },
    save() {
      this.$refs.crud.save(this.model);
    },
    create() {
      this.$refs.crud.create(this.model);
    },
    clone(model) {
      this.model = structuredClone(model);
      this.model.id = null;
    }
  },
  mounted() {},
  components: {
    Crud,
    Texto,
    Layout,
    List,
    Select,
  },
};
</script>