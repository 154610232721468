<template>
  <div class="col-12  mb-3">
    <div class="form-group">
      <label v-if="label">{{ label }}</label>
      <div class="input-group">
        <input
          :id="id ? id : null"
          ref="input"
          type="text"
          class="form-control"
          v-model="value"
          @keyup.enter="focusSelect(focus) ? null : $event.target.blur()"
          @keydown="key"
          @blur="checkItem"
          autocomplete="off"
        />
        <div class="autocomplete-foot" v-if="itens.length">
          <ul class="autocomplete-list">
            <li
              v-for="(it, i) in itens"
              :key="i"
              @click="item = it"
              :class="'autocomplete-item' + (focus == i ? ' focus' : '')"
            >
              {{ cast ? cast(it) : it[campo] }}
            </li>
          </ul>
        </div>
        <div
          class="input-group-append"
          v-if="btnnovo && itens.length == 0"
          @click="novo = true"
        >
          <button class="app-button app-button-primary">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>

      <small
        class="form-text text-danger d-block"
        v-for="(e, i) in erro"
        :key="i"
        >{{ e }}</small
      >
    </div>
    <slot v-if="novo" name="novo"> </slot>
  </div>
</template>

<script>
export default {
  props: [
    "vazio",
    "path",
    "labelini",
    "valueini",
    "campo",
    "cast",
    "characteres",
    "btnnovo",
    "id"
  ],
  data() {
    return {
      value: null,
      label: this.labelini,
      erro: null,
      limit: 5,
      itens: [],
      item: null,
      focus: null,
      novo: false,
      show: false,
    };
  },
  watch: {
    itens(it) {
      if (this.focus != null && this.focus > it.length) {
        this.focus = null;
      }
    },
    value(v) {
      if (v == null || v == "") {
        this.item = null;
        this.itens = [];
      } else {
        if (v.length >= (this.characteres != null ? this.characteres : 2) &&
            document.activeElement === this.$refs.input) {
          this.item = null;
          this.buscar(v);
        } else {
          this.itens = [];
        }
      }
    },
    valueini() {
      if (this.valueini == null) {
        this.item = null;
        this.value = null;
      } else {
        this.setId(this.valueini);
      }
    },
    item(item) {
      if (item != null) {
        this.value = this.cast ? this.cast(item) : item[this.campo];
        this.itens = [];
        this.$emit("change", item.id);
        this.$emit("changeObject", item);
        this.focus = null;
      } else {
        this.$emit("change", null);
        this.$emit("changeObject", null);
      }
    },
  },
  methods: {
    checkItem($event) {
      setTimeout(() => {
        if (this.item == null) {
          this.value = "";
        }
        this.itens = [];
      }, 500);
    },
    focusSelect(focus) {
      if (focus != null) {
        this.item = this.itens[focus];
      }
    },
    key(event) {
      switch (event.keyCode) {
        case 38:
          if (this.focus == null) {
            this.focus = this.itens.length - 1;
          } else {
            this.focus = --this.focus;
            if (this.focus < 0) {
              this.focus = this.itens.length - 1;
            }
          }
          break;
        case 40:
          if (this.itens.length > 0) {
            if (this.focus == null) {
              this.focus = 0;
            } else {
              this.focus = ++this.focus % this.itens.length;
            }
          }
          break;
      }
    },
    setId(id) {
      callAPI(
        this.path,
        'GET',
        {
          where: [{
            field: "id",
            value: id,
            operator: "="
          }]
        },
        (e) => {
          this.item = e.itens[0];
        },
      );
    },
    buscar(v) {
      var where = [];
      var order = [];

      for (const key of this.campo) {
        if (typeof key === "string") {
          where.push({
            field: key,
            operator: "ilike",
            value: "%" + v + "%",
            whereType: "or"
          });

          order.push({
            orderBy: key,
            orderType: "asc"
          });
        } else if (key.numerico && Number.isInteger(Number(v))) {
          where.push({
            field: key.valor,
            operator: "=",
            value: Number(v),
            whereType: "or"
          });

          order.push({
            orderBy: key.valor,
            orderType: "asc"
          });
        }
      }

      callAPI(
        this.path,
        'GET',
        {
          where: where,
          order: order
        },
        (e) => {
          this.itens = e.itens;
          this.item = null;
        },
      );
    },
  },
};
</script>

<style>
.autocomplete-list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 8px 0px var(--thema7);
  width: 100%;
  padding-left: 0px;
  position: absolute;
}
.autocomplete-foot {
  width: 100%;
  position: relative;
  z-index: 100;
}
.autocomplete-item {
  display: flex;
  height: 35px;
  cursor: pointer;
  padding-left: 10px;
  line-height: 35px;
}
.autocomplete-item.focus {
  background-color: var(--thema1);
}
</style>