<template>
  <div :class="(formonly ? ' formonly' : '')">
    <List
      ref="list"
      :novo="novo"
      :path="path"
      :nome="list"
      :btnnovo="btnnovo"
      :customfilter="customfilter"
      @novo="
        edit = false;
        acao = 'form';
        $emit('novo');
      "
      @buscando="
        $refs.datatable ? $refs.datatable.buscando($event) : null;
        $emit('buscando', $event);
      "
      :filtrosini="filtrosini"
      :datatableinit="datatable ? true : false"
      @list="
        setaDatatable($event);
        $emit('list', $event);
      "
      v-if="!formonly"
      v-show="acao == 'list' || modal"
    >
      <template slot="body">
        <Datatable
          :filtrosini="filtrosini"
          v-if="datatable"
          ref="datatable"
          @view="
            $emit('view', $event);
          "
          @edit="
            $emit('edit', $event);
            acao = 'form';
            edit = true;
          "
          @delete="
            $emit('delete', $event);
            del = $event;
          "
          @clone="
            $emit('clone', $event);
            acao = 'form';
            edit = false;
          "
          @customEvent1="$emit('customEvent1', $event);"
          @customEvent2="$emit('customEvent2', $event);"
          @pagination="$refs.list.filtros = $event"
          :config="datatable"
          @filtrar="
            $refs.list.filtros = $event;
            $refs.list.buscar();
          "
          :showView="showView"
          :showClone="showClone"
          :showEdit="showEdit"
          :showDelete="showDelete"
        >
          <template slot="customFilter">
            <slot name="customFilter"></slot>
          </template>
        </Datatable>
        <slot name="list"></slot>
      </template>
    </List>
    <Form
      v-if="acao == 'form'"
      :edit="edit"
      :nome="form"
      @back="
        acao = 'list';
        checkFormonly();
      "
      @save="$emit('create')"
      @edit="$emit('save')"
      :modal="modal"
      :savebutton="savebutton"
    >
      <template slot="body" v-if="!modal">
        <slot name="form"></slot>
      </template>
      <template slot="body" v-else>
        <slot name="form"></slot>
      </template>
    </Form>
    <div
      ref="modal"
      class="modal inmodal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Deseja realmente deletar o registro?</h5>
          </div>
          <div class="modal-body" v-if="datatable && del">
            <h4 v-for="(c, k) in getCampos" :key="k">
              {{ c.label }}: {{ cast(del, c) }}
            </h4>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="del = null"
              v-if="!deletando"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="obdelete(del.id)"
              :disabled="deletando"
            >
              {{ deletando ? "Deletando..." : "Deletar" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <Notification ref="notification"></Notification>
  </div>
</template>

<script>
import List from "./layout/List.vue";
import Form from "./layout/Form.vue";
import Datatable from "./Datatable.vue";
import Notification from "../alert/Notification.vue";

export default {
  props: {
    novo: String,
    path: String,
    datatable: Object,
    list: String,
    form: String,
    filtrosini: Object,
    savebutton: {
      type: Boolean,
      default: true
    },
    modal: Boolean,
    formonly: Boolean,
    btnnovo: Boolean,
    customfilter: Object,
    showView: {
      type: Boolean,
      default: false
    },
    showClone: {
      type: Boolean,
      default: true
    },
    showEdit: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: true
    },
  },
  components: {
    List,
    Form,
    Datatable,
    Notification,
  },
  data() {
    return {
      acao: "list",
      edit: false,
      del: null,
      fields: null,
      deletando: false,
      errors: []
    };
  },
  created() {
    if (this.formonly) {
      this.$emit("novo");
      setTimeout(() => {
        this.acao = "form";
      }, 10);
    }
  },
  computed: {
    getCampos() {
      var camp = [];
      for (const c of this.datatable.campos) {
        if (c.objeto) {
          camp.push(c);
        }
      }
      return camp;
    },
  },
  methods: {
    buscarDataAtual(){
      const tempo = new Date();
      const date = tempo.getFullYear()+'-'+(tempo.getMonth())+'-'+tempo.getDate();
      const dateTime = date;
      return dateTime;
    },
    getErrors(input) {
      if (this.errors) {
        return this.errors[input];
      } else {
        return null;
      }
    },
    setaDatatable(e){
      if(this.datatable){
        this.$refs.datatable.list = e
      }
    },
    currentDateTime() {
      const current = new Date();
      const date = current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate();
      const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      const dateTime = date +' '+ time;
      return dateTime;
    },
    checkFormonly(e = null) {
      if (this.formonly) {
        this.$emit("closeform", e);
      }
    },
    cast(e, k) {
      var valor = "";
      if (k.objeto) {
        valor = e[k.objeto];
      }
      if (k.icone) {
        valor = `<i class="${k.icone}"></i>`;
      }
      if (k.cast) {
        valor = k.cast(valor);
      }
      return valor;
    },
    create(ob) {
      callAPI(this.path, 'POST', ob, this.sucessoCriar, this.fail, this.erro);
    },
    save(ob) {
      callAPI(this.path + '/' + ob.id, 'PUT', ob, this.sucessoEditar, this.fail, this.erro);
    },
    buscar() {
      this.$refs.list.buscar();
    },
    obdelete(ob) {
      this.deletando = true;
      callAPI(this.path + '/' + ob, 'DELETE', null, this.sucessoDeletar, this.fail, this.erro)
    },
    sucessoDeletar(e) {
      this.deletando = false;
      this.$refs.notification.success("Registro deletado com sucesso");
      $(this.$refs.modal).modal("hide");
      setTimeout(() => {
        this.del = null;
      }, 100);
      this.$refs.list.buscar();
    },
    sucessoCriar(e) {
      this.$refs.notification.success("Registro criado com sucesso");
      this.acao = "list";
      this.checkFormonly(e);
    },
    sucessoEditar(e) {
      this.$refs.notification.success("Registro alterado com sucesso");
      this.acao = "list";
    },
    fail(e) {
      var erros = e.responseJSON;
      this.$refs.notification.erro("Alguns campos possuem erro");
      this.$emit("fail", erros);
    },
    erro(e) {
      var erros = [];
      
      for (var key in e.responseJSON.errors) {
        for ( const error of e.responseJSON.errors[key] ) {
          if ( key in this.errors ) {
            erros[key].push(error);
          } else {
            erros[key] = [error];
          }
        }
      }
      
      this.$emit('errors', erros);
      //this.$refs.notification.erro(e.responseJSON.errors);
    },
  },
  watch: {
    acao(a, l) {
      if (a == "list") {
        if (this.$refs.list) {
          this.$refs.list.buscar();
        }
      }
    },
    del(e) {
      if (e != null) {
        $(this.$refs.modal).modal("show");
      } else {
        $(this.$refs.modal).modal("hide");
      }
    },
  },
};
</script>

<style>
.cadastro-basico {
  padding: 50px;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.cadastro-basico-body {
  background-color: var(--thema2);
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 8px 0px var(--thema7);
  height: 100%;
  overflow: auto;
}
.cadastro-basico-body.form {
  height: auto;
}

.cadastro-basico-body h4 {
  margin: 0px;
  line-height: 35px;
}

.cadastro-back {
  display: inline-block;
  font-size: 25px;
  width: 30px;
  padding: 0px;
  cursor: pointer;
  outline: none;
  border: 0px;
  background-color: transparent;
}

.cadastro-titulo {
  display: inline-block;
  width: calc(100% - 150px);
}

.cadastro-salvar {
  bottom: 20px;
  right: 20px;
  width: 150px;
  display: inline-block;
}

.cadastro-list-titulo {
  width: calc(100% - 310px);
  display: inline-block;
}

.cadastro-download {
  display: inline-block;
  width: 100px;
  margin-right: 5px;
  margin-left: auto;
}
.cadastro-search {
  display: inline-block;
  width: 100px;
}

.cadastro-novo {
  display: inline-block;
  width: 100px;
  margin-left: 5px;
}
.cadastro-acao {
  margin-left: 5px;
}

.cadastro-acoes {
  width: 310px;
  display: inline-block;
  text-align: right;
}
.cadastro-modal-body {
  max-height: calc(100vh - 200px);
  overflow: auto;
}

@media (max-width: 600px) {
  .cadastro-acoes {
    display: flex;
    position: fixed;
    bottom: 85px;
    left: 0px;
    width: 100%;
  }
  .cadastro-list-titulo {
    /* width: calc(100% - 160px); */
    width: 100%;
  }
  .cadastro-download {
    margin-left: auto;
  }
  /*
  .cadastro-search {
    width: 50px;
  }
  
  .cadastro-acao {
    display: none;
  } */
  .cadastro-novo {
    margin-right: auto;
  }
  .cadastro-back {
    display: none;
  }

  .cadastro-salvar {
    position: absolute;
    bottom: 85px;
    width: 100%;
    left: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .cadastro-titulo {
    width: calc(100% - 50px);
  }

  .cadastro-basico {
    overflow: auto;
    padding: 10px;
    max-height: calc(100% - 55px);
  }
}
.cadastro-basico.formonly {
  padding: 0px;
}
</style>