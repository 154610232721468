<template>
  <Layout
    ref="layout"
    @show="$emit('show')"
    @back="$refs.crud.acao = 'list'"
    :showback="false"
  >
    <template slot="body">
      <Crud
        ref="crud"
        :path="config.path"
        :novo="config.novo"
        :list="config.list"
        :form="config.form"
        :datatable="datatable"
        :modal="true"
        @list="$refs.layout.back(false)"
        @edit="
          model = $event;
          $emit('back', true);
        "
        @novo="
          model = {};
          $emit('back', true);
        "
        @save="save"
        @create="create"
        @errors="errors = $event"
        :showClone="false"
        :showEdit="false"
      >
        <template slot="form">
          <div class="row">
            <Texto
              ref="nome"
              labelini="Nome *"
              :valueini="model.name"
              @change="model.name = $event"
              :errors="getErrors('name')"
            />
          </div>
        </template>
      </Crud>
    </template>
  </Layout>
</template>

<script>
import Layout from "../../../components/layout/Layout.vue";
import Crud from "../../../components/crud/Crud.vue";
import Texto from "../../../components/crud/input/Text.vue";
export default {
  components: {
    Layout,
    Crud,
    Texto,
  },
  data() {
    return {
      config: {
        novo: "Nova",
        list: "Permissões",
        form: "Permissão",
        path: "/permissions",
      },
      campos: ["name"],
      model: {},
      errors: null,
      list: [],
      datatable: {
        campos: [
          {
            label: "Cód.",
            objeto: "id",
            head: {
              style: {
                "width": "80px",
              },
            },
          },
          {
            label: "Nome",
            objeto: "name",
          },
        ],
      },
    };
  },
  methods: {
    getErrors(campo) {
      if (this.errors) {
        return this.errors[campo];
      } else {
        return null;
      }
    },
    save() {
      this.$refs.crud.save(this.model);
    },
    create() {
      this.$refs.crud.create(this.model);
    },
  },
};
</script>