<template>
  <div class="h-100">
    <div ref="load" class="app-load">
      <div class="m-auto text-center">
        <img src="/img/logo.jpeg" class="d-flex" alt="" height="300" />
        <div class="load">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    callAPI(
      '/usuario',
      'GET',
      null,
      e => {
        window.location = '/geral';
      }
    );
  }
};
</script>