import Login from '../views/login/Login.vue'
import DadosUsuario from '../views/login/DadosUsuario.vue'
import AlterarSenha from '../views/login/AlterarSenha.vue'
import EsqueceuSenha from '../views/login/EsqueceuSenha.vue'
import RecuperarSenha from '../views/login/RecuperarSenha.vue'
import Termo from '../views/login/Termo.vue'
import Politica from '../views/login/Politica.vue'


const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { public: true }
    },
    {
        path: '/meus-dados',
        name: 'DadosUsuario',
        component: DadosUsuario
    },
    {
        path: '/alterar-senha',
        name: 'AlterarSenha',
        component: AlterarSenha
    },
    {
        path: '/esqueceu-senha',
        name: 'EsqueceuSenha',
        component: EsqueceuSenha
    },
    {
        path: '/recuperar-senha',
        name: 'RecuperarSenha',
        component: RecuperarSenha
    },
    {
        path: '/termo',
        name: 'Termo',
        component: Termo,
        meta: { public: true }
    },
    {
        path: '/politica',
        name: 'Politica',
        component: Politica,
        meta: { public: true }
    },
]

export default routes