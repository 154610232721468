<template>
  <div :class="css ? css : 'col-12'">
    <div class="form-group">
      <div class="row">
        <div :class="cssblock ? cssblock : 'col-12 col-md-6 mt-3'">
          <div class="d-flex mb-1">
            <h5 v-if="label" style="line-height: 30px" class="mb-2">
              {{ label }} - {{ values.length }}
              <input :id="validation_id ? validation_id : null" type="text" inputmode="none" style="height: 0px; width: 100%; border: 0px; display: block; outline: none;">
            </h5>
            <button
              v-if="btnnovo"
              class="app-button app-button-primary"
              style="margin-left: auto; margin-right: 5px"
              @click="novo = true"
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <div :class="'add-list-body ' + (cssleft ? cssleft : '')">
            <ul class="add-list-itens">
              <li
                v-for="(v, i) in values"
                :key="i"
                :value="campo ? v.id : i"
                class="d-flex add-list-item"
              >
                <span v-if="campo">
                  <b v-if="jatem(v, i)">{{ jatem(v, i).p }} - </b>
                  {{ campo ? v[campo] : v }}
                </span>
                <div
                  v-else
                  class="inline-block"
                  style="width: calc(100% - 30px)"
                >
                  <b v-if="jatem(v, i)">{{ jatem(v, i).p }} - </b>
                  <slot name="item" :item="v"></slot>
                </div>

                <div
                  style="
                    margin-left: auto;
                    margin-right: 10px;
                    cursor: pointer;
                    margin-top: auto;
                    margin-bottom: auto;
                  "
                  @click="add(v.id)"
                >
                  <i class="fas fa-plus edit-button"></i>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div :class="cssblock ? cssblock : 'col-12 col-md-6 mt-3'">
          <div style="min-height: 42px; height: 42px">
            <slot name="shead"></slot>
          </div>

          <div
            :class="'add-list-body ' + (cssright ? cssright : '')"
            :style="fullheight ? 'max-height: unset; height: auto' : ''"
          >
            <ul class="add-list-itens" v-if="values.length > 0">
              <li
                v-for="(v, i) in objects"
                :key="i"
                class="d-flex add-list-item"
              >
                <span v-if="campo">
                  <b v-if="jatem(v, i)">{{ jatem(v, i).p }} - </b>
                  {{ objects[i][campo] }}
                </span>
                <div
                  v-else
                  class="inline-block"
                  style="width: calc(100% - 30px)"
                >
                  <b v-if="jatem(v, i)">{{ jatem(v, i).p }} - </b>
                  <slot name="selecionado" :item="objects[i]"></slot>
                </div>

                <div
                  style="
                    margin-left: auto;
                    margin-right: 10px;
                    cursor: pointer;
                    margin-top: auto;
                    margin-bottom: auto;
                  "
                  @click="value.splice(i, 1); objects.splice(i,1)"
                >
                  <i class="fas fa-trash delete-button"></i>
                </div>
              </li>
              <h6 v-if="value.length == 0" class="text-center mt-3">
                Nenhum item foi adicionado
              </h6>
            </ul>
          </div>
          <slot name="sfoot"></slot>
        </div>
      </div>

      <small
        class="form-text text-danger d-block"
        v-for="(e, i) in erro"
        :key="i"
        >{{ e }}</small
      >
      <slot v-if="novo" name="novo" :call="novoCall"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "vazio",
    "path",
    "labelini",
    "valueini",
    "campo",
    "valuesini",
    "css",
    "filtrosini",
    "btnnovo",
    "filter",
    "relatorio",
    "cssblock",
    "fullheight",
    "cssright",
    "cssleft",
    "validation_id"
  ],
  data() {
    return {
      novo: false,
      value: this.valueini ? this.valueini : [],
      values: this.valuesini ? this.valuesini : [],
      label: this.labelini,
      erro: null,
      filtros: this.filtrosini,
      objects: [],
    };
  },
  watch: {
    values(values, old) {
      var objects = [];
      for (const va of this.value) {
        var ob = this.get(va, this.objects);
        if(ob == null){
          ob = this.get(va);
        }
        objects.push(ob);
      }
      this.objects = objects;
    },
    value(v) {
      // var ids = [];
      // for (const ob of v) {
      //   ids.push(ob.id);
      // if(this.edit){
      //   if(this.servicos.length){ 
      //     this.objects = {}
      //     this.objects = this.servicos;
      //     const ob = this.objects;
      //     console.log(ob);
      //     console.log("Tem Serviços");
      //   }else{
      //     console.log("Não entrou :(");
      //   }
      // }else{
      //   console.log("Não entrou no edit ");
      // }
      //        
      //         console.log(this.objects);
      //         this.objects = this.servicos;
      //         console.log(this.objects);
      //         //console.log(this.servicos);
      //         const ob = this.objects;
      //           if(ob){
      //             for(var item in ob){
      //               console.log('TESTE');
      //                 this.add(0,ob[item]);
      //             }
      //           }

            // }

      this.$emit("change", v);
    },
  },
  methods: {
    add(v) {
      var ob = this.get(v);
      if (ob) {
        this.value.push(v);
        this.objects.push(JSON.parse(JSON.stringify(ob)));
      }
    },
    novoCall() {
      this.buscar();
      this.novo = false;
    },
    jatem(v, i) {
      var c = -1;
      var p = 0;
      for (const k in this.value) {
        var s = this.value[k];
        if (s.id == v) {
          c++;
          if (k == i) {
            p = c + 1;
          }
        }
      }
      if (c <= 0) {
        return false;
      }
      return { c, p };
    },
    buscar() {
      
      // var filtros = this.filtros
      //   ? { filtros: JSON.stringify(this.filtros) }
      //   : {};
      // if (this.relatorio) {
      //   filtros["relatorio"] = true;
      // }
      try {
        callAPI(this.path, 'GET', null, (e) => {
          this.values = e.itens;
          if (this.valuesini) {
            for (const object of this.valuesini) {
              this.value.push(object.id);
              this.objects.push(JSON.parse(JSON.stringify(object)));
            }
          }
        });
        // API[this.path].list(
        //   (e) => {
        //     if (this.filter) {
        //       var vals = [];
        //       for (const fil of e.itens) {
        //         if (this.filter(fil)) {
        //           vals.push(fil);
        //         }
        //       }
        //       this.values = vals;
        //     } else {
        //       this.values = e.itens;
        //     }
        //   },
        //   (e) => {},
        //   (e) => {},
        //   filtros
        // );
      } catch ($e) {}
    },
    get(id, values = this.values) {
      for (const ob of values) {
        if (ob.id == id) {
          return ob;
        }
      }
      return null;
    },
  },
  created() {
    if (this.path != null) {
      this.buscar();
    }
  },
};
</script>

<style >
.add-list-body {
  height: 45vh;
  overflow: auto;
  max-height: 45vh;
  padding: 0px;
  border: 1px solid #000;
}
.add-list-itens {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}
.add-list-item {
  border: 1px solid #000;
  margin-left: -1px;
  margin-top: -1px;
  margin-right: -1px;
}
</style>