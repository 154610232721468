<template>
  <Layout
    ref="layout"
    @show="$emit('show')"
    @back="$refs.crud.acao = 'list'"
    :showback="false"
  >
    <template slot="body">
      <Crud
        ref="crud"
        :path="config.path"
        :novo="config.novo"
        :list="config.list"
        :form="config.form"
        :datatable="datatable"
        @list="$refs.layout.back(false)"
        @edit="
          nav = 'conta';
          model = $event;
          $refs.layout.back(true);
        "
        @clone="
          model = $event;
          model.id = null;
        "
        @novo="
          nav = 'conta';
          model = {};
          $refs.layout.back(true);
        "
        @save="save"
        @create="create"
        @fail="setErros"
      >
        <template slot="form">
          <div class="row">
            <Texto
              ref="nome"
              labelini="Nome *"
              :valueini="model.nome"
              @change="model.nome = $event"
            />
            <Autocomplete
              ref="cliente_id"
              labelini="Cliente *"
              :valueini="model.cliente_id"
              path="cliente"
              :characteres="1"
              :campo="['nome', { valor: 'id', numerico: true }, 'apelido']"
              @change="model.cliente_id = $event"
              :btnnovo="true"
              :cast="
                (ob) => {
                  return (
                    ob.nome +
                    ' - ' +
                    (ob.cpf_cnpj != null ? ob.cpf_cnpj : '...') +
                    ' - ' +
                    (ob.tipo == 'F' ? 'Física' : 'Júridica')
                  );
                }
              "
            >
              <template slot="novo">
                <Cliente
                  :formonly="true"
                  @closeform="novoCliente"
                />
              </template>
            </Autocomplete>
            <TextoArea
              ref="descricao"
              labelini="Descrição"
              :valueini="model.descricao"
              @change="model.descricao = $event"
            />
            <div class="col-md-6 col-12 mb-3">
              <div class="row h-100">
                <div class="col-6 d-flex">
                  <button
                    class="app-button app-button-secondary m-auto w-75"
                    @click="$refs.conteudo.copy()"
                  >
                    Copiar
                  </button>
                </div>
                <div class="col-6 d-flex">
                  <button
                    class="app-button app-button-secondary m-auto w-75"
                    @click="$refs.conteudo.pdf('Proposta Manual')"
                  >
                    PDF
                  </button>
                </div>
              </div>
            </div>
            <Editor
              ref="conteudo"
              :valueini="model.conteudo"
              @change="model.conteudo = $event"
            />
          </div>
        </template>
      </Crud>
    </template>
  </Layout>
</template>

<script>
import Layout from "../../../components/layout/Layout.vue";
import Crud from "../../../components/crud/Crud.vue";
import List from "../../../components/crud/input/List.vue";
import Texto from "../../../components/crud/input/Text.vue";
import TextoArea from "../../../components/crud/input/TextArea.vue";
import Editor from "../../../components/crud/input/Editor.vue";
import Autocomplete from "../../../components/crud/input/Autocomplete.vue";
import Cliente from "../../../components/cadastro/Cliente.vue";
export default {
  data() {
    return {
      nav: "conta",
      config: {
        novo: "Nova",
        list: "Propostas Manuais",
        form: "Proposta Manual",
        path: "proposta_manual",
      },
      campos: ["nome", "descricao"],
      model: {},
      list: [],
      datatable: {
        campos: [
          {
            label: "Cód.",
            objeto: "id",
            mobile: false,
          },
          {
            label: "Nome",
            objeto: "nome",
          },
          {
            label: "Descrição",
            objeto: "descricao",
            mobile: false,
          },
          {
            label: "Criado",
            objeto: "created",
            mobile: false,
            cast: function (e) {
              return new Date(e).toLocaleString();
            },
          },
          {
            label: "",
            icone: "fas fa-copy",
            head: {
              style: {
                "min-width": "50px",
              },
            },
            body: {
              style: {
                color: "var(--clone)",
                cursor: "pointer",
                "text-align": "center",
              },
            },

            click: "clone",
          },
          {
            label: "",
            icone: "fas fa-edit",
            head: {
              style: {
                "min-width": "50px",
              },
            },
            body: {
              style: {
                color: "var(--edit)",
                cursor: "pointer",
                "text-align": "center",
              },
            },

            click: "edit",
          },
          {
            label: "",
            icone: "fas fa-trash",
            head: {
              style: {
                "min-width": "50px",
              },
            },
            body: {
              style: {
                color: "var(--delete)",
                cursor: "pointer",
                "text-align": "center",
              },
            },
            click: "delete",
          },
        ],
      },
    };
  },
  methods: {
    novoCliente(e) {
      if (e) {
        this.$refs.cliente_id.setId(e.id);
        setTimeout(() => {
          this.$refs.cliente_id.novo = false;
        }, 1000);
      } else {
        this.$refs.cliente_id.novo = false;
      }
    },
    setErros(e) {
      for (const ca of this.campos) {
        var erro = e[ca];
        this.$refs[ca].erro = erro;
      }
    },
    save() {
      this.$refs.crud.save(this.model);
    },
    create() {
      this.$refs.crud.create(this.model);
    },
  },
  mounted() {},
  components: {
    Crud,
    Texto,
    Layout,
    List,
    Autocomplete,
    TextoArea,
    Editor,
    Cliente,
  },
};
</script>