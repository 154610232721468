<template>
  <div>
    <div class="row" v-if="!modal">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h3>{{ nome }}</h3>
          </div>
          <div class="ibox-content">
            <slot name="body" />
          </div>
          <div class="ibox-title" style="border-style: none solid solid; padding: 20px;" v-if="savebutton == null || savebutton">
            <button
              type="button"
              class="btn btn-white mr-2"
              data-dismiss="modal"
              @click="$emit('back')"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="$emit(edit ? 'edit' : 'save')"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="modal inmodal fade"
      ref="modal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">{{ edit ? "Editar " + nome : "Cadastrar " + nome }}</h4>
          </div>

          <div class="modal-body">
            <slot name="body" />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-white"
              data-dismiss="modal"
              @click="modalShow(false)"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="$emit(edit ? 'edit' : 'save')"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["edit", "nome", "savebutton", "modal"],
  methods: {
    modalShow(show = true) {
      if (show) {
        $(this.$refs.modal).modal("show");
      } else {
        $(this.$refs.modal).modal("hide");
        this.$emit("back");
      }
    },
  },
  mounted() {
    if (this.modal) {
      $(this.$refs.modal).modal({ backdrop: "static", keyboard: false });
      this.modalShow();
    }
  },
  beforeDestroy() {
    if (this.modal) {
      this.modalShow(false);
    }
  },
};
</script>