<template>
  <div :class="(css ? css : (columnGroup ? (columnGroup == 3 ? 'col-4' :
                                           (columnGroup == 2 ? 'col-6' : 'col-12')) :
                                           'col-12 col-md-6')) + ' mb-3'">
    <div class="form-group">
      <label v-if="label">{{ label }}</label>
      <div class="input-group">
        <input
          type="time"
          class="form-control"
          v-model="value"
          @keyup.enter="$event.target.blur()"
        />
      </div>
      <small
        class="form-text text-danger d-block"
        v-for="(e, i) in erro"
        :key="i"
        >{{ e }}</small
      >
    </div>
  </div>
</template>

<script>
import Texto from "./Text.vue";
export default {
  components: {
    Texto,
  },
  props: ["labelini", "valueini", "css", "showsum", "columnGroup"],
  data() {
    return {
      label: this.labelini,
      value: this.valueini,
      erro: null,
    };
  },
  watch: {
    valueini(v) {
      if (v) {
        this.value = v;
      } else {
        this.value = null;
      }
    },
    value(v) {
      if (v == "") {
        v = null;
        this.$emit("change", v);
      } else {
        this.$emit("change", v);
      }
    },
  },
};
</script>
