<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox">
        <div class="ibox-title" style="padding-right: 15px;">
          <div class="col-4" style="float: right; text-align: right;">
            <button class="btn btn-primary" @click="$emit('novo')">
              <i class="fas fa-plus"></i>
              <span class="ml-1"> {{ novo }}</span>
            </button>
          </div>
          <h3>{{ nome }}</h3>
        </div>
        <div class="ibox-content">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "nome",
    "path",
    "novo",
    "funcao",
    "filtrosini",
    "datatableinit",
    "filtrosavancadoini",
    "btnnovo",
    "customfilter"
  ],
  data() {
    return {
      filtros: this.filtrosini ? this.filtrosini : {},
      total: {
        max: 0,
        progresso: 0,
      },
    };
  },
  mounted() {
    this.buscar();
  },
  methods: {
    buscar(back = null, filtros = this.filtros, relatorio = false) {
      this.$emit("buscando", filtros);
      var fl = { filtros: JSON.stringify(filtros) };

      if (relatorio) {
        fl.relatorio = true;
      }

      var dados = {
        limit: filtros.limit,
        offset: filtros.offset,
        field: null,
        value: null,
        operator: null
      };

      dados['where'] = [];

      for (const item in filtros.where) {
        dados['where'].push({
          field: item,
          value: filtros.where[item].value,
          operator: filtros.where[item].operator
        });
      }

      for (const item in filtros.orderby) {
        dados['orderBy'] = item;
        dados['orderType'] = filtros.orderby[item];
      }

      if (this.customfilter) {
        for (const item in this.customfilter) {
          if (this.customfilter[item].value != null) {
            dados['where'].push({
              field: this.customfilter[item].field,
              value: this.customfilter[item].value,
              operator: this.customfilter[item].operator
            });
          }
        }
      }

      callAPI(
        this.path,
        'GET',
        dados,
        (e) => {
          this.total.max = e.max;
          if (back) {
            back(e);
          } else {
            this.$emit("list", e);
          }
        },
      );
    },
  },
};
</script>