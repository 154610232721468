<template>
  <div style="display: grid; grid-template-columns: 40% 60%;" class="col-12">
    <div style="padding-right: 5px;">
      <div class="d-flex mb-1">
        <h4 v-if="label" style="line-height: 23px" class="mb-2">
          {{ label }} - {{ values.length }}
          <input :id="validation_id ? validation_id : null" type="text" inputmode="none" style="height: 0px; width: 100%; border: 0px; display: block; outline: none;">
        </h4>
        <button
          v-if="btnnovo"
          class="app-button app-button-primary"
          style="margin-left: auto; margin-right: 5px"
          @click="novo = true"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <table class="table table-bordered">
        <tbody>
          <tr
            v-for="(v, i) in values"
            :key="i"
            :value="campo ? v.id : i"
          >
            <td class="d-flex">
              <span v-if="campo">
                <b v-if="jatem(v, i)">{{ jatem(v, i).p }} - </b>
                {{ campo ? v[campo] : v }}
              </span>
              <div
                v-else
                class="inline-block"
                style="width: calc(100% - 30px)"
              >
                <b v-if="jatem(v, i)">{{ jatem(v, i).p }} - </b>
                <slot name="item" :item="v"></slot>
              </div>

              <div
                style="
                  margin-left: auto;
                  margin-right: 10px;
                  cursor: pointer;
                  margin-top: auto;
                  margin-bottom: auto;
                "
                @click="add(v.id)"
              >
                <i class="fas fa-plus edit-button"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="padding-left: 5px;">
      <table class="add-list-table table table-bordered">
        <thead>
          <tr>
            <slot name="tablehead"></slot>
            <th style="width: 50px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v, i) in objects" :key="i">
            <slot name="tablebody" :item="objects[i]"></slot>
            <td style="text-align: center; vertical-align: middle;">
              <div
                style="cursor: pointer;"
                @click="value.splice(i, 1); objects.splice(i,1)"
              >
                <i class="fas fa-trash delete-button"></i>
              </div>
            </td>
          </tr>
          <tr v-if="objects == null || objects.length == 0">
            <h5 class="text-center mt-3">
              Nenhum item foi adicionado
            </h5>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <slot name="tablefoot"></slot>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>

    <small
      class="form-text text-danger d-block"
      v-for="(e, i) in erro"
      :key="i"
      >{{ e }}</small
    >
    <slot v-if="novo" name="novo" :call="novoCall"> </slot>
  </div>
</template>

<script>
export default {
  props: [
    "vazio",
    "path",
    "labelini",
    "valueini",
    "campo",
    "valuesini",
    "css",
    "filtrosini",
    "btnnovo",
    "filter",
    "relatorio",
    "cssblock",
    "fullheight",
    "cssright",
    "cssleft",
    "validation_id"
  ],
  data() {
    return {
      novo: false,
      value: this.valueini ? this.valueini : [],
      values: [],
      label: this.labelini,
      erro: null,
      filtros: this.filtrosini,
      objects: [],
    };
  },
  created() {
    if (this.path != null) {
      this.buscar();
    }
  },
  methods: {
    add(v) {
      var ob = this.get(v);
      if (ob) {
        this.value.push(v);
        this.objects.push(JSON.parse(JSON.stringify(ob)));
      }
    },
    novoCall() {
      this.buscar();
      this.novo = false;
    },
    jatem(v, i) {
      var c = -1;
      var p = 0;
      for (const k in this.value) {
        var s = this.value[k];
        if (s.id == v) {
          c++;
          if (k == i) {
            p = c + 1;
          }
        }
      }
      if (c <= 0) {
        return false;
      }
      return { c, p };
    },
    buscar() {
      callAPI(this.path, 'GET', null, (e) => {
        this.values = e.itens;
        var t_value = [];
        var t_objects = [];

        if (this.valuesini) {
          for (const object of this.valuesini) {
            t_value.push(object.id);
            t_objects.push(JSON.parse(JSON.stringify(object)));
          }
        }

        this.value = t_value;
        this.objects = t_objects;
      });
    },
    get(id, values = this.values) {
      for (const ob of values) {
        if (ob.id == id) {
          return ob;
        }
      }
      return null;
    },
  },
  watch: {
    value(v) {
      this.$emit("change", v);
    },
    valuesini() {
      var t_value = [];
      var t_objects = [];

      if (this.valuesini) {
        for (const object of this.valuesini) {
          t_value.push(object.id);
          t_objects.push(JSON.parse(JSON.stringify(object)));
        }
      }

      this.value = t_value;
      this.objects = t_objects;
    }
  },
};
</script>