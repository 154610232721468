import Usuario from '../views/crud/admin/Usuario.vue';
import Grupo from '../views/crud/admin/Grupo.vue';
import Permissao from '../views/crud/admin/Permissao.vue';
import ParametroGenerico from '../views/crud/cadastro/ParametroGenerico.vue';

const routes = [{
        path: '/admin/usuarios*',
        name: 'AdminUsuario',
        component: Usuario,
    },
    {
        path: '/admin/grupos*',
        name: 'AdminGrupo',
        component: Grupo,
    },
    {
        path: '/admin/permissoes*',
        name: 'AdminPermissao',
        component: Permissao,
    },
    {
        path: '/admin/parametros-genericos*',
        name: 'ParametroGenerico',
        component: ParametroGenerico,
    },
]

export default routes