<template>
  <div :class="(css ? css : 'col-12 col-md-6')">
    <div class="form-group d-flex h-100">
      <div class="form-check m-auto">
        <input type="checkbox" class="form-check-input" v-model="value" :id="'chekcbox-' + _uid" />
        <label class="form-check-label" :for="'chekcbox-' + _uid">{{ label }}</label>
      </div>

      <small
        class="form-text text-danger d-block"
        v-for="(e, i) in erro"
        :key="i"
        >{{ e }}</small
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["typeini", "labelini", "valueini", "css", "item"],
  data() {
    return {
      label: this.labelini,
      value: this.valueini,
      erro: null,
      type: this.typeini,
    };
  },
  watch: {
    value(v) {
      if (v == "") {
        v = null;
      }
      this.$emit("change", v);
    },
  },
  created(){
    if(this.item){
      this.item.value[this.item.name] = this;
    }
  }
};
</script>