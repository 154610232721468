<template>
  <div class="h-100">
    <div ref="load" class="app-load" v-show="!show">
      <div class="m-auto text-center">
        <img src="/img/logo.jpeg" class="d-flex" alt="" height="300" />
        <div class="load">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div ref="app" class="app-body" v-show="show">
      <router-view v-if="show" @show="showPage" />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      show: false
    };
  },
  methods: {
    showPage() {
      setTimeout(() => {
        this.show = true;
      }, 300);
    }
  },
  mounted() {
    this.showPage();
  },
  watch: {
    $route(to, from) {
    },
  },
};
</script>

<style>
:root {
  --font1: #212529;
  --font2: #8b8888;
  --font3: #008cff;
  --thema1: #4d4b4b;
  --thema2: #fff;
  --thema3: #d4d1d1;
  --thema4: #008cff;
  --thema5: #eceaea;
  --thema6: #abaec0;
  --thema7: #b1aeae;
  --thema8: #8b8888;
  --edit: #008cff;
  --delete: #c45b5b;
}

html,
body {
  margin: 0px;
  height: 100%;
}

.app-load {
  height: 100%;
  display: flex;
  background-color: var(--thema2);
}

.app-body {
  height: 100%;
}

.content-body {
  background-color: #f3f3f9;
  height: calc(100% - 80px);
  margin-left: 240px;
  transition: margin-left 0.3s ease-out;
  border-left: 1px solid var(--thema3);
}

.content-body.top{
  margin: 0px;
}

@media (max-width: 600px) {
  .content-body {
    max-height: calc(100% - 153px);
    overflow: auto;
    margin-left: 0px;
    transition: margin-left 0.3s ease-out;
  }
  .app-load img {
    max-width: 100%;
  }
}

.link-primary {
  color: var(--thema6);
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.link-primary:hover {
  color: var(--thema2);
}

.btn-back {
  position: fixed;
  top: 10px;
  left: 20px;
  z-index: 1000;
  font-size: 30px;
  color: var(--thema2) !important;
}

.load {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}

.load div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--thema6);
  border-radius: 50%;
  animation: load 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--thema6) transparent transparent transparent;
}

.load div:nth-child(1) {
  animation-delay: -0.45s;
}

.load div:nth-child(2) {
  animation-delay: -0.3s;
}

.load div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circular_progress {
  position: relative;
}

.circular_progress_porcentagem {
  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;
  /* Rounded border */
  border: 5px solid var(--thema3);
  border-radius: 9999px;
  /* Size */
  font-size: 20px;
  height: 64px;
  width: 64px;
}

.circular_progress_curva {
  left: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  clip: rect(0px, 64px, 64px, 32px);
}

.circular_progress_curva.maior-50 {
  clip: rect(auto, auto, auto, auto);
}

.circular_progress_metade {
  height: 100%;
  position: absolute;
  width: 100%;
  border: 5px solid var(--thema6);
  border-radius: 9999px;
}

.circular_progress_metade_lado1 {
  clip: rect(0px, 32px, 64px, 0px);
}

.circular_progress_metade_lado2 {
  clip: rect(0px, 32px, 64px, 0px);
  transform: rotate(0deg);
}

.circular_progress_metade_lado2.maior-50 {
  transform: rotate(180deg);
}
.edit-button {
  color: var(--edit);
}

.delete-button {
  color: var(--delete);
}

.close {
  outline: none;
  border: 0px;
  color: #fff;
}

.app-button {
  color: #fff;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 3px;
}

.app-button-primary {
  background-color: var(--thema4);
}
.app-button-secondary {
  background-color: var(--thema8);
}
.app-versao {
  position: fixed;
  bottom: 5px;
  right: 15px;
}
.app-button[disabled] {
  cursor: not-allowed;
}
@media (max-width: 600px) {
  .app-versao {
    position: fixed;
    top: 50px;
    left: 10px;
    bottom: unset;
    right: unset;
    color: #fff;
  }
}
</style>