var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dadosServico!=[])?_c('div',[_c('AddList',{ref:"servicos",attrs:{"labelini":"Selecione os serviços","validation_id":"servicos_validate","valuesini":_vm.dadosServico,"path":"/servicos","btnnovo":true,"cssright":"servico-add-grid","cssblock":"servico-grid-block col-12 col-md-6 mt-3"},on:{"change":_vm.change},scopedSlots:_vm._u([{key:"tablebody",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding-left":"5px","vertical-align":"middle"}},[_vm._v(" "+_vm._s(item.nome)+" ")]),_c('td',[_c('Texto',{attrs:{"css":"form-group w-100 m-auto","typeini":"number","valueini":item.quantidade ? item.quantidade : (item.quantidade = 1)},on:{"change":function($event){item.quantidade = parseInt($event);
            _vm.$refs.servicos.$forceUpdate();
            _vm.calculaTotal();}}})],1),_c('td',[_c('Money',{attrs:{"css":"w-100 m-auto","changeeventini":true,"valueini":item.valor ? item.valor : '0.00'},on:{"change":function($event){item.valor = $event;
            _vm.$refs.servicos.$forceUpdate();
            _vm.calculaTotal();}}})],1),_c('td',{staticStyle:{"padding-right":"5px","text-align":"right","vertical-align":"middle"}},[_c('b',{staticClass:"m-auto"},[_vm._v(" "+_vm._s(parseFloat(item.valor_total).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}))+" ")])])]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"p-1"},[_vm._v(" "+_vm._s(item.nome)+" ")])]}},{key:"novo",fn:function(ref){
            var call = ref.call;
return [_c('Servico',{attrs:{"formonly":true},on:{"closeform":call}})]}}],null,false,27219191)},[_c('template',{slot:"tablehead"},[_c('th',{staticStyle:{"text-align":"center"}},[_vm._v(" Nome ")]),_c('th',{staticStyle:{"width":"80px","text-align":"center"}},[_vm._v(" Qtd. ")]),_c('th',{staticStyle:{"width":"120px","text-align":"center"}},[_vm._v(" Valor (R$) ")]),_c('th',{staticStyle:{"width":"120px","text-align":"center"}},[_vm._v(" Total (R$) ")])]),_c('template',{slot:"tablefoot"},[_c('td',{staticStyle:{"padding-left":"5px"},attrs:{"colspan":"3"}},[_c('b',[_vm._v("Total")])]),_c('td',{staticStyle:{"padding-right":"5px","text-align":"right"}},[_c('b',[_vm._v(_vm._s(parseFloat(_vm.total).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})))])])])],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }