<template>
  <Crud
    ref="crud"
    :path="config.path"
    :novo="config.novo"
    :list="config.list"
    :form="config.form"
    :datatable="datatable"
    :modal="formonly"
    :formonly="formonly"
    @closeform="$emit('closeform', $event)"
    @list="$emit('back', false)"
    @edit="
      model = $event;
      $emit('back', true);
    "
    @novo="
      model = {
        tipo: 'F',
        estado_id: null,
        logradouro: null,
        complemento: null
      };
      $emit('back', true);
    "
    @clone="clone"
    @save="save"
    @create="create"
    @errors="errors = $event"
  >
    <template slot="form">
      <div class="row">
        <Texto
          ref="nome"
          labelini="Nome *"
          :valueini="model.nome"
          @change="model.nome = $event"
          :errors="getErrors('nome')"
        />
        <Texto
          ref="contato"
          labelini="Contato"
          :valueini="model.contato"
          @change="model.contato = $event"
        />
        <Texto
          ref="rg"
          labelini="RG"
          :valueini="model.rg"
          @change="model.rg = $event"
        />
        <Cpf
          ref="cpf_cnpj"
          labelini="CPF/CNPJ *"
          placeholder="000.000.000-00  ou  XX.XXX.XXX/0001-XX"
          :valueini="model.cpf_cnpj"
          @change="model.cpf_cnpj = $event"
          :errors="getErrors('cpf_cnpj')"
        />
        <Texto
          ref="email"
          labelini="E-mail"
          placeholder="exemplo@gmail.com"
          :valueini="model.email"
          :errors="getErrors('email')"
          @change="model.email = $event"
        />
        <Texto
          ref="email_secundario"
          labelini="2º opção de e-mail"
          placeholder="exemplo2@gmail.com"
          :valueini="model.email_secundario"
          @change="model.email_secundario = $event"
        />
        <Telefone
          placeholder="(99) 9 9999-9999"
          ref="celular"
          labelini="Celular"
          :valueini="model.celular"
          @change="model.celular = $event"
        />
        <Telefone
          placeholder="(99) 9 9999-9999"
          ref="telefone"
          labelini="Telefone"
          :valueini="model.telefone"
          @change="model.telefone = $event"
        />
        <Select
          ref="tipo"
          labelini="Tipo"
          :valueini="model.tipo"
          :valuesini="{ F: 'Física', J: 'Júridica' }"
          @change="model.tipo = $event"
        />
        <Cep
          ref="cep"
          labelini="CEP"
          :valueini="model.cep"
          @change="model.cep = $event"
          @local="loadEndereco($event)"
        />
        <Texto
          ref="logradouro"
          labelini="Logradouro"
          :valueini="model.logradouro"
          @change="model.logradouro = $event;"
        />
        <Texto
          ref="numero"
          labelini="Número"
          :valueini="model.numero"
          @change="model.numero = $event"
        />
        <Texto
          ref="complemento"
          labelini="Complemento"
          :valueini="model.complemento"
          @change="model.complemento = $event"
        />
        <Texto
          ref="bairro"
          labelini="Bairro"
          :valueini="model.bairro"
          @change="model.bairro = $event"
        />
        <Select
          ref="estado"
          labelini="Estado *"
          :valueini="model.estado_id"
          @change="
            model.estado_id = $event;
            estado_id = $event;
          "
          path="/estados"
          vazio="Selecione..."
          :errors="getErrors('estado_id')"
          campo="nome"
          css="col-6 mb-3"
        />
        <Select
          ref="cidade"
          labelini="Cidade *"
          :valueini="model.cidade_id"
          :valuesini="cidades"
          :errors="getErrors('cidade_id')"
          @change="model.cidade_id = $event"
          campo="nome"
          css="col-6 mb-3"
        />
        <TextArea
          ref="observacao"
          labelini="Observação"
          :valueini="model.observacao"
          @change="model.observacao = $event"
        />
      </div>
    </template>
  </Crud>
</template>

<script>
import Crud from "../crud/Crud.vue";
import Telefone from "../crud/input/Telefone.vue";
import Cpf from "../crud/input/Cpf.vue";
import List from "../crud/input/List.vue";
import Texto from "../crud/input/Text.vue";
import TextArea from '../crud/input/TextArea.vue';
import TextAreaEndereco from '../crud/input/TextAreaEndereco.vue';
import Select from "../crud/input/Select.vue";
import Autocomplete from "../crud/input/Autocomplete.vue";
import Cep from "../crud/input/Cep.vue";
export default {
  props: ["formonly"],
  data() {
    return {
      config: {
        novo: "Novo",
        list: "Clientes",
        form: "Cliente",
        path: "/clientes",
      },
      list: [],
      model: {},
      endereco: null,
      errors: null,
      estado_id: null,
      cidades: [],
      campos: [
        "nome",
        "contato",
        "tipo",
        "cpf_cnpj",
        "email",
        "email_secundario",
        "celular",
        "telefone",
        "cep",
        "logradouro",
        "numero",
        "cidade_id",
      ],
      datatable: {
        campos: [
          {
            label: "Cód.",
            objeto: "id",
            head: {
              style: {
                "width": "80px",
              },
            },
          },
          {
            label: "Nome",
            objeto: "nome",
          },
          {
            label: "E-mail",
            objeto: "email",
          },
          {
            label: "CPF/CNPJ",
            objeto: "cpf_cnpj",
          },
          {
            label: "Tipo",
            objeto: "tipo",
            mobile: false,
            cast: function (e) {
              return e == "F" ? "Física" : "Júridica";
            },
          },
        ],
      },
    };
  },
  methods: {
    getErrors(campo) {
      if (this.errors) {
        return this.errors[campo];
      } else {
        return null;
      }
    },
    save() {
      this.$refs.crud.save(this.model);
    },
    create() {
      this.$refs.crud.create(this.model);
    },
    clone(model) {
      this.model = structuredClone(model);
      this.model.id = null;
    },
    loadEndereco(event) {
      if (event.ibge) {
        callAPI(
          '/buscar-estado-por-ibge-cidade',
          'GET',
          {
            ibge: event.ibge
          }, 
          (e) => {
            if ( event.bairro ) {
              this.model.bairro = event.bairro;
            }

            if ( event.logradouro ) {
              this.model.logradouro = event.logradouro;
            }

            this.model.cidade_id = event.ibge;
            this.model.estado_id = e.id;
          }
        );
      }
    }
  },
  watch: {
    estado_id(newValue){
      callAPI(
        '/cidades-estado',
        'GET',
        {
          estado_id: newValue
        }, 
        (e) => {
          this.cidades = e.itens;
        }
      );
    },
    model(e) {
      if (this.model['estado_id']) {
        callAPI(
          '/cidades-estado',
          'GET',
          {
            estado_id: this.model['estado_id']
          }, 
          (e) => {
            this.cidades = e.itens;
          }
        );
      }
    },
  },
  mounted() {},
  components: {
    Crud,
    Texto,
    List,
    Select,
    Autocomplete,
    TextAreaEndereco,
    Cep,
    Telefone,
    TextArea,
    Cpf
  },
};
</script>