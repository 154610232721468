<template>
  <div class="col-12 mb-3">
    <div class="form-group">
      <label v-if="label">{{ label }}</label>
      <div ref="editor"></div>
      <input :id="id ? id : null" type="text" inputmode="none" style="height: 0px; width: 100%; border: 0px; display: block; outline: none;">
      <small
        class="form-text text-danger d-block"
        v-for="(e, i) in erro"
        :key="i"
        >{{ e }}</small
      >
    </div>
    <Notification ref="notification"></Notification>
  </div>
</template>

<script>
import Notification from "../../alert/Notification.vue";
export default {
  components: { Notification },
  props: ["labelini", "valueini", "id"],
  data() {
    return {
      valor: this.valueini,
      label: this.labelini,
      erro: null,
      editor: null,
    };
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.editor.getText());
      this.$refs.notification.success("Copiado", 1000);
    },
    pdf(title = "Documento") {
      const doc = new jspdf.jsPDF("p", "pt", "a4");
      var img = new Image();
      img.src = "/img/logo.jpeg";
      img.onload = (img) => {
        doc.setFillColor("#91C01C");
        doc.rect(0, 0, doc.internal.pageSize.width, 60, "F");
        doc.addImage(
          img.target,
          "PNG",
          doc.internal.pageSize.width / 2 - 50 / 2,
          5,
          50,
          50
        );
        doc.setProperties({
          title: title,
        });
        doc.html(this.editor.getHTMLCode(), {
          callback: function (doc) {
            //doc.save("Rotina manual");
            doc.output("dataurlnewwindow", title);
          },
          filename: title,
          x: 10,
          y: 65,
          width: doc.internal.pageSize.getWidth() - 10,
          windowWidth: doc.internal.pageSize.getWidth() - 10,
        });
      };
    },
  },
  mounted() {
    this.editor = new RichTextEditor(this.$refs.editor);
    if (this.valor) {
      this.editor.setHTMLCode(this.valor);
    }
    this.editor.attachEvent("change", (e) => {
      var html = this.editor.getHTMLCode();
      this.editor.closeCurrentPopup();
      this.$emit("change", html);
    });
  },
  beforeDestroy() {
    this.$refs.editor.blur();
    this.editor.closeCurrentPopup();
    for (const f of document.getElementsByTagName("rte-floatpanel")) {
      f.remove();
    }
  },
  watch: {
    valor() {
      if (this.valor) {
        this.editor.setHTMLCode(this.valor);
      }
    },
    valueini(newValue, oldValue) {
      if (oldValue == null) {
        this.valor = this.valueini;
      }
    }
  },
};
</script>