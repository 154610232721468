<template>
  <Layout ref="layout">
    <template slot="body">
      <div class="ibox">
        <div class="ibox-title" style="padding-right: 15px;">
          <h4 class="mb-3">
            Meus Dados
          </h4>
        </div>
        <div class="ibox-content">
          <Texto
            inputId="input-login"
            labelini="Login"
            :valueini="formData.login"
            @change="formData.login = $event"
            :errors="getErrors('login')"
          />

          <Texto
            inputId="input-nome"
            labelini="Nome"
            :valueini="formData.nome"
            @change="formData.nome = $event"
            :errors="getErrors('nome')"
          />

          <Texto
            inputId="input-email"
            labelini="Email"
            :valueini="formData.email"
            @change="formData.email = $event"
            :errors="getErrors('email')"
          />
        </div>
        <div class="ibox-title" style="border-style: none solid solid; padding: 20px;">
          <div class="col-12">
            <button class="btn btn-primary" :disabled="waiting" @click="salvar()">
              Salvar
            </button>
            <span v-show="success" class="ml-2 font-weight-bold">
              Dados atualizados com sucesso!
            </span>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "../../components/layout/Layout.vue";
import Texto from "../../components/crud/input/Text.vue";
export default {
  components: {
    Layout,
    Texto
  },
  data() {
    return {
      formData: {},
      waiting: false,
      success: false,
      errors: []
    }
  },
  created() {
    callAPI(
      '/dados-usuario',
      'GET',
      null,
      (res) => {
        if (res.success) {
          this.formData = res.usuario;
        }
      },
      (res) => {
        console.log('Erro ao buscar dados');
      },
      (res) => {
        console.log('Erro ao buscar dados');
      }
    );
  },
  methods: {
    getErrors(input) {
      if (this.errors) {
        return this.errors[input];
      } else {
        return null;
      }
    },
    salvar() {
      if (this.formData.login == null || this.formData.login == '') {
        document.getElementById('input-login').setCustomValidity('Informe o seu login');
        document.getElementById('input-login').reportValidity();
        document.getElementById('input-login').focus();
        return false;
      } else {
        document.getElementById('input-login').setCustomValidity('');
      }

      if (this.formData.nome == null || this.formData.nome == '') {
        document.getElementById('input-nome').setCustomValidity('Informe o seu nome');
        document.getElementById('input-nome').reportValidity();
        document.getElementById('input-nome').focus();
        return false;
      } else {
        document.getElementById('input-nome').setCustomValidity('');
      }

      if (this.formData.email == null || this.formData.email == '') {
        document.getElementById('input-email').setCustomValidity('Informe o seu email');
        document.getElementById('input-email').reportValidity();
        document.getElementById('input-email').focus();
        return false;
      } else {
        document.getElementById('input-email').setCustomValidity('');
      }

      this.waiting = true;
      
      callAPI(
        '/dados-usuario/' + this.formData.id,
        'PUT',
        this.formData,
        (res) => {
          if (res.success) {
            this.success = true;
            this.errors = null;
          }

          this.waiting = false;
        },
        (res) => {
          this.waiting = false;
        },
        (res) => {
          this.success = false;
          this.errors = [];

          for (var key in res.responseJSON.errors) {
            for (const error of res.responseJSON.errors[key]) {
              if (key in this.errors) {
                this.errors[key].push(error);
              } else {
                this.errors[key] = [error];
              }
            }
          }

          this.waiting = false;
        }
      );
    },
  },
};
</script>