<template>
  <div class="container" style="background-color: #fff;">
    <a class="btn-back" @click="$router.push('/login')">
      <i class="fas fa-arrow-left"></i>
    </a>
    <div class="px-2 pb-4">
      <div class="d-flex">
        <img
          src="/img/logo.jpeg"
          alt=""
          width="200"
          height="200"
          class="m-auto"
        />
      </div>
      <h1>Privacy Policy</h1>

      <h3>Web Site Terms and Conditions of Use</h3>
      <h4>1. Terms</h4>
      <p>
        By accessing this web site, you are agreeing to be bound by these web
        site Terms and Conditions of Use, all applicable laws and regulations,
        and agree that you are responsible for compliance with any applicable
        local laws. If you do not agree with any of these terms, you are
        prohibited from using or accessing this site. The materials contained in
        this web site are protected by applicable copyright and trade mark law.
      </p>
      <h4>2. Disclaimer</h4>
      <p>
        The materials on CSSPortal.com's web site are provided "as is".
        CSSPortal.com makes no warranties, expressed or implied, and hereby
        disclaims and negates all other warranties, including without
        limitation, implied warranties or conditions of merchantability, fitness
        for a particular purpose, or non-infringement of intellectual property
        or other violation of rights. Further, CSSPortal.com does not warrant or
        make any representations concerning the accuracy, likely results, or
        reliability of the use of the materials on its Internet web site or
        otherwise relating to such materials or on any sites linked to this
        site.
      </p>
      <h4>3. Limitations</h4>
      <p>
        In no event shall CSSPortal.com or its suppliers be liable for any
        damages (including, without limitation, damages for loss of data or
        profit, or due to business interruption,) arising out of the use or
        inability to use the materials on CSSPortal.com's Internet site, even if
        CSSPortal.com or a CSSPortal.com authorized representative has been
        notified orally or in writing of the possibility of such damage. Because
        some jurisdictions do not allow limitations on implied warranties, or
        limitations of liability for consequential or incidental damages, these
        limitations may not apply to you.
      </p>
      <h4>4. Revisions and Errata</h4>
      <p>
        The materials appearing on CSSPortal.com's web site could include
        technical, typographical, or photographic errors. CSSPortal.com does not
        warrant that any of the materials on its web site are accurate,
        complete, or current. CSSPortal.com may make changes to the materials
        contained on its web site at any time without notice. CSSPortal.com does
        not, however, make any commitment to update the materials.
      </p>
      <h4>5. Links</h4>
      <p>
        CSSPortal.com has not reviewed all of the sites linked to its Internet
        web site and is not responsible for the contents of any such linked
        site. The inclusion of any link does not imply endorsement by
        CSSPortal.com of the site. Use of any such linked web site is at the
        user's own risk.
      </p>
      <h4>6. Site Terms of Use Modifications</h4>
      <p>
        CSSPortal.com may revise these terms of use for its web site at any time
        without notice. By using this web site you are agreeing to be bound by
        the then current version of these Terms and Conditions of Use. <br />
        General Terms and Conditions applicable to Use of a Web Site.
      </p>
      <h4>Privacy Policy</h4>
      <p>
        Your privacy is very important to us. Accordingly, we have developed
        this Policy in order for you to understand how we collect, use,
        communicate and disclose and make use of personal information. The
        following outlines our privacy policy. <br />
        Before or at the time of collecting personal information, we will
        identify the purposes for which information is being collected. <br />
        We will collect and use of personal information solely with the
        objective of fulfilling those purposes specified by us and for other
        compatible purposes, unless we obtain the consent of the individual
        concerned or as required by law. <br />
        We will only retain personal information as long as necessary for the
        fulfilment of those purposes.
        <br />
        We will collect personal information by lawful and fair means and, where
        appropriate, with the knowledge or consent of the individual concerned.
        <br />
        Personal data should be relevant to the purposes for which it is to be
        used, and, to the extent necessary for those purposes, should be
        accurate, complete, and up-to-date. <br />
        We will protect personal information by reasonable security safeguards
        against loss or theft, as well as unauthorized access, disclosure,
        copying, use or modification. <br />
        We will make readily available to customers information about our
        policies and practices relating to the management of personal
        information.
        <br />
        We are committed to conducting our business in accordance with these
        principles in order to ensure that the confidentiality of personal
        information is protected and maintained.<br />
        We use third-party advertising companies to serve ads when you visit our
        website. These companies may use information (not including your name,
        address, email address or telephone number) about your visits to this
        and other websites in order to provide advertisements about goods and
        services of interest to you. If you would like more information about
        this practice and would like to know your options in relation to·not
        having this information used by these companies,
        <a href="http://www.google.com/privacy_ads.html" rel="nofollow">
          click here</a
        >.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>