<template>
  <div id="wrapper">
    <div id="page-wrapper" class="gray-bg">
      <div class="row border-bottom white-bg">
        <Menu></Menu>
      </div>
      <div class="wrapper wrapper-content">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "./Menu.vue";
import Foot from "./Foot.vue";
export default {
  components: {
    Menu,
    Foot,
  },
  methods: {
    back(v) {
      this.$emit('back');
    },
  },
};
</script>