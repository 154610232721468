<template>
  <nav class="navbar navbar-expand-lg navbar-static-top" style="height: 51px;" role="navigation">
    <a
      href="#"
      @click="home"
      style="border-radius: 100px; background-color: #fff;">
        <img
          src="/img/logo_menu.jpeg"
          style="height: 100%; border-radius: 100px;"
        />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fa fa-reorder"></i>
    </button>

    <div class="navbar-collapse collapse" id="navbar">
      <ul class="nav navbar-nav mr-auto">
        <li class="dropdown" v-for="(menu, i) in menus" :key="i">
          <a aria-expanded="false" role="button" href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i :class="menu.icone" style="margin-right: 5px;"></i>{{ menu.nome }}
          </a>
          <ul v-if="menu.menus" role="menu" class="dropdown-menu">
            <li v-for="(submenu, j) in menu.menus" :key="j">
              <a @click="rota(submenu.path, $event)" :href="submenu.path">
                <div style="display: grid; grid-template-columns: 25px calc(100% - 45px);">
                  <div style="display: flex; align-items: center;">
                    <i :class="submenu.icone" style="color: #676a6c"></i>
                  </div>
                  <div>
                    {{ submenu.nome }}
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </li>
      </ul>

      <!--<ul class="nav navbar-nav mr-auto"></ul>
        <li class="dropdown border-right">
          <a aria-expanded="false" role="button" href="#" class="dropdown-toggle" data-toggle="dropdown">
            <span :key="modulo.icone">
              <i :class="modulo.icone" style="margin-right: 5px;"></i>
              {{ modulo.nome }}
            </span>
          </a>
          <ul role="menu" class="dropdown-menu">
            <li v-for="(m, i) in modulos" :key="i">
              <a @click="selecionarModulo(m, i)" href="#">
                <div style="display: grid; grid-template-columns: 25px calc(100% - 45px);">
                  <div style="display: flex; align-items: center;">
                    <i :class="m.icone" style="color: #676a6c"></i>
                  </div>
                  <div>
                    {{ m.nome }}
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </li>
        <li class="dropdown" v-for="(menu, i) in modulo.menus" :key="i">
          <a aria-expanded="false" role="button" href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i :class="menu.icone" style="margin-right: 5px;"></i>{{ menu.nome }}
          </a>

          <ul v-if="menu.menus" role="menu" class="dropdown-menu">
            <li v-for="(submenu, j) in menu.menus" :key="j">
              <a @click="rota(submenu.path, $event)" :href="submenu.path">
                <div style="display: grid; grid-template-columns: 25px calc(100% - 45px);">
                  <div style="display: flex; align-items: center;">
                    <i :class="submenu.icone" style="color: #676a6c"></i>
                  </div>
                  <div>
                    {{ submenu.nome }}
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </li>
      </ul>-->
      <ul class="nav navbar-nav">
        <li class="dropdown">
          <a aria-expanded="false" role="button" href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="fas fa-bars" style="margin-right: 5px;"></i>
          </a>

          <ul role="menu" class="dropdown-menu">
            <li>
              <a @click="dadosUsuario">
                <i class="fas fa-user-tie" style="color: #676a6c; margin-right: 5px;"></i> Meus dados
              </a>
            </li>
            <li>
              <a @click="alterarSenha">
                <i class="fas fa-key" style="color: #676a6c; margin-right: 5px;"></i> Alterar Senha
              </a>
            </li>
            <li>
              <a @click="sair">
                <i class="fas fa-sign-out-alt" style="color: #676a6c; margin-right: 5px;"></i> Sair
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      show: {
        item: null,
        element: null
      },
      menus: [],
      atual: window.location.pathname,
    };
  },
  methods: {
    home() {
      window.location = '/';
    },
    dadosUsuario() {
      this.$router.push('/meus-dados');
    },
    alterarSenha() {
      this.$router.push('/alterar-senha');
    },
    sair() {
      localStorage.removeItem('token');
      window.location = '/login'
    },
    clickItem($event, i) {
      if (!$event.ctrlKey) {
        if (this.show.item == i) {
          this.show.item = null;
        } else {
          this.show.item = i;
        }
      }
    },
    rota(path, e) {
      e.stopPropagation();
      if (e.ctrlKey) {
        return;
      }
      e.preventDefault();
      if (this.atual == path) {
        this.$router.go(path);
        return;
      }
      this.$router.push(path);
    },
    mountMenu(response) {
      this.menus = response;
    },
  },
  mounted() {
    var _this = this;
    if (this.menus.length>0) {
      _this.mountMenu(null);
    } else {
      callAPI(
        "/auth/menu",
        "GET",
        null,
        function(response) {
          _this.mountMenu(response);
        },
        function(response) {
          console.log(response);
        },
        function(response) {
          console.log(response);
        }
      );
    }
  },
  created(){
    if(!localStorage.getItem('token')){
      window.location = '/login'
    }
  }
};
//export default {
//  data() {
//    return {
//      atual: window.location.pathname,
//      modulos: [],
//      modulo: {}
//    };
//  },
//  created(){
//    if(!localStorage.getItem('token')){
//      window.location = '/login'
//    }
//  },
//  mounted() {
//    var _this = this;
//    if (this.modulos.length>0) {
//      _this.mountMenu(null);
//    } else {
//      callAPI(
//        "/auth/menu",
//        "GET",
//        null,
//        function(response) {
//          _this.mountMenu(response);
//        },
//        function(response) {
//          console.log(response);
//        },
//        function(response) {
//          console.log(response);
//        }
//      );
//    }
//  },
//  methods: {
//    home() {
//      window.location = '/';
//    },
//    dadosUsuario() {
//      this.$router.push('/meus-dados');
//    },
//    alterarSenha() {
//      this.$router.push('/alterar-senha');
//    },
//    sair() {
//      localStorage.removeItem('token');
//      window.location = '/login'
//    },
//    rota(path, e) {
//      e.stopPropagation();
//      if (e.ctrlKey) {
//        return;
//      }
//      e.preventDefault();
//      if (this.atual == path) {
//        this.$router.go(path);
//        return;
//      }
//      this.$router.push(path);
//    },
//    mountMenu(response) {
//      this.modulos = response;
//
//      this.modulo = this.modulos[getSelectedModule()];
//    },
//    selecionarModulo(modulo, index) {
//      this.modulo = modulo;
//      setSelectedModule(index);
//    }
//  },
//};
</script>