<template>
  <Layout ref="layout">
    <template slot="body">
      <div class="ibox">
        <div class="ibox-title" style="padding-right: 15px;">
          <h4 class="mb-3">
            Alterar Senha
          </h4>
        </div>
        <div class="ibox-content">
          <Texto
            inputId="input-senha-atual"
            labelini="Senha atual"
            :valueini="formData.current_password"
            typeini="password"
            @change="formData.current_password = $event"
            :errors="getErrors('current_password')"
          />

          <Texto
            inputId="input-nova-senha"
            labelini="Nova senha"
            :valueini="formData.new_password"
            typeini="password"
            @change="formData.new_password = $event"
            :errors="getErrors('new_password')"
          />

          <Texto
            inputId="input-confirmar-senha"
            labelini="Confirmar senha"
            :valueini="formData.new_confirm_password"
            typeini="password"
            @change="formData.new_confirm_password = $event"
            :errors="getErrors('new_confirm_password')"
          />
        </div>

        <div class="ibox-title" style="border-style: none solid solid; padding: 20px;">
          <div class="col-12">
            <button class="btn btn-primary" :disabled="waiting" @click="alterarSenha()">
              Alterar senha
            </button>
            <span v-show="success" class="ml-2 font-weight-bold">
              Senha alterada com sucesso!
            </span>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "../../components/layout/Layout.vue";
import Texto from "../../components/crud/input/Text.vue";
export default {
  components: {
    Layout,
    Texto,
  },
  data() {
    return {
      formData: {
        current_password: null,
        new_password: null,
        new_confirm_password: null
      },
      waiting: false,
      success: false,
      errors: []
    }
  },
  methods: {
    getErrors(input) {
      if (this.errors) {
        return this.errors[input];
      } else {
        return null;
      }
    },
    alterarSenha() {
      if (this.formData.current_password == null || this.formData.current_password == '') {
        document.getElementById('input-senha-atual').setCustomValidity('Informe a senha atual');
        document.getElementById('input-senha-atual').reportValidity();
        document.getElementById('input-senha-atual').focus();
        return false;
      } else {
        document.getElementById('input-senha-atual').setCustomValidity('');
      }

      if (this.formData.new_password == null || this.formData.new_password == '') {
        document.getElementById('input-nova-senha').setCustomValidity('Informe a nova senha');
        document.getElementById('input-nova-senha').reportValidity();
        document.getElementById('input-nova-senha').focus();
        return false;
      } else {
        document.getElementById('input-nova-senha').setCustomValidity('');
      }

      if (this.formData.new_confirm_password == null || this.formData.new_confirm_password == '') {
        document.getElementById('input-confirmar-senha').setCustomValidity('Confirme a nova senha');
        document.getElementById('input-confirmar-senha').reportValidity();
        document.getElementById('input-confirmar-senha').focus();
        return false;
      } else {
        document.getElementById('input-confirmar-senha').setCustomValidity('');
      }

      this.waiting = true;
      
      callAPI(
        '/alterar-senha',
        'PUT',
        this.formData,
        (res) => {
          if (res.success) {
              this.success = true;
              this.errors = null;
              this.formData.current_password = null;
              this.formData.new_password = null;
              this.formData.new_confirm_password = null;
          }

          this.waiting = false;
        },
        (res) => {
          this.waiting = false;
        },
        (res) => {
          this.success = false;
          this.errors = [];

          for (var key in res.responseJSON.errors) {
            for ( const error of res.responseJSON.errors[key] ) {
              if ( key in this.errors ) {
                this.errors[key].push(error);
              } else {
                this.errors[key] = [error];
              }
            }
          }

          this.waiting = false;
        }
      );
    },
  },
};
</script>

<style>
.main-card {
  padding: 50px;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.main-body {
  background-color: var(--thema2);
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 8px 0px var(--thema7);
  height: 100%;
  overflow: auto;
}

.main-body h4 {
  margin: 0px;
  line-height: 35px;
}

.cadastro-salvar {
  bottom: 20px;
  right: 20px;
  width: 150px;
  display: inline-block;
}
</style>