import Servico from '../views/crud/cadastro/Servico.vue';
import FormaPagamento from '../views/crud/cadastro/FormaPagamento.vue';
import Cliente from '../views/crud/cadastro/Cliente.vue';
import Tarefa from '../views/crud/cadastro/Tarefa.vue';
import Pergunta from '../views/crud/cadastro/Pergunta.vue';
import Processo from '../views/crud/cadastro/Processo.vue';
import Orcamento from '../views/crud/cadastro/Orcamento.vue';
import PropostaManual from '../views/crud/cadastro/PropostaManual.vue';
import ImpostoRenda from '../views/crud/cadastro/ImpostoRenda.vue';
import Taxa from '../views/crud/cadastro/Taxa.vue';
import ModeloPropostaManual from '../views/crud/cadastro/ModeloPropostaManual.vue';


const routes = [
    {
        path: '/servico*',
        name: 'CadastroServico',
        component: Servico,
    },
    {
        path: '/forma-pagamento*',
        name: 'CadastroFormaPagamento',
        component: FormaPagamento,
    },
    {
        path: '/cliente*',
        name: 'CadastroCliente',
        component: Cliente,
    },
    {
        path: '/tarefa*',
        name: 'CadastroTarefa',
        component: Tarefa,
    },
    {
        path: '/pergunta*',
        name: 'CadastroPergunta',

        component: Pergunta,
    },
    {
        path: '/processo*',
        name: 'CadastroProcesso',
        component: Processo,
    },
    {
        path: '/orcamento*',
        name: 'CadastroOrcamento',
        component: Orcamento,
    },
    {
        path: '/proposta-manual*',
        name: 'PropostaManual',
        component: PropostaManual,
    },
    {
        path: '/taxa*',
        name: 'Taxa',
        component: Taxa,
    },
    {
        path: '/modelo-proposta-manual*',
        name: 'ModeloPropostaManual',
        component: ModeloPropostaManual,
    },
    {
        path: '/imposto-de-renda*',
        name: 'ImpostoRenda',
        component: ImpostoRenda,
    }
    // {
    //     path: '/cadastro/curso*',
    //     name: 'CadastroCurso',
    //     component: Curso,
    // }
]

export default routes