<template>
  <div :class="(css ? css : 'col-12 col-md-6 mb-3')">
    <div class="form-group">
      <label v-if="label">{{ label }}</label>
      <input
        :type="type ? type : 'text'"
        class="form-control"
        v-mask= "'##/##/####'"
        :placeholder="placeholder"
        v-model="value"
        :autocomplete="type == 'password' ? 'new-password' : 'off'"
        @keyup.enter="$event.target.blur()"
      />
  
      <small
        class="form-text text-danger d-block"
        v-for="(e, i) in erro"
        :key="i"
        >{{ e }}</small
      >
    </div>
  </div>
</template>

<script>

export default {
  props: ["typeini", "labelini", "valueini", "css", "placeholder", "errors"],
  data() {
    return {
      label: this.labelini,
      value: this.valueini,
      erro: this.errors,
      type: this.typeini,
    };
  },
  watch: {
    value(v) {
      if (v == "") {
        v = null;
      }
      this.$emit("change", v);
    },
    errors(v) {
      this.erro = v;
    }
  },
};
</script>