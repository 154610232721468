<template>
  <Layout
    ref="layout"
    @show="$emit('show')"
    @back="$refs.crud.acao = 'list'"
    :showback="false"
  >
    <template slot="body">
      <div class="p-4">
        <div class="card">
          <div class="p-3" style="position: inherit" v-if="!atualizando">
            <h3>Atualização do sistema</h3>
            <button
              class="sistem-btn-atualizar app-button app-button-primary"
              @click="atualizar"
              :disabled="dados.front == null && dados.back == null"
            >
              Atualizar
            </button>
            <div class="row">
              <div class="col-12 col-md-6 mt-3">
                <h5>Front</h5>
                <Drop
                  :directory="true"
                  formatos="Diretório"
                  @dados="front"
                ></Drop>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <h5>Back</h5>
                <Drop formatos=".jar" accept=".jar" @dados="back"></Drop>
              </div>
            </div>
          </div>
          <div class="p-3" v-else>
            <h3>Atualização do sistema</h3>
            <div class="text-center d-flex" style="height: 300px">
              <div class="m-auto">
                <h5>Atualizando</h5>
                <div class="load">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Notification ref="notification"></Notification>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "../../components/layout/Layout.vue";
import Drop from "../../components/crud/input/Drop.vue";
import Notification from "../../components/alert/Notification.vue";
export default {
  data() {
    return {
      dados: {
        front: null,
        back: null,
      },
      atualizando: false,
    };
  },
  methods: {
    back(e) {
      this.dados.back = e.data;
    },
    front(e) {
      this.dados.front = e[Object.keys(e)[0]];
    },
    reconnect() {
      this.atualizando = false;
      this.$refs.notification.success("Sistema atualizado com sucesso");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    },
    atualizar() {
      this.atualizando = true;
      setTimeout(() => {
        API.system.update(
          this.dados,
          (e) => {
            if (this.dados.back) {
              this.$refs.notification.success("Aguardando a reinicilização");
            } else {
              this.$refs.notification.success("Sistema atualizado com sucesso");
              this.atualizando = false;
            }
          },
          (e) => {
            this.atualizando = false;
            this.$refs.notification.erro("Falha ao atualizar");
          },
          (e) => {
            this.$refs.notification.erro("Falha ao atualizar");
          }
        );
      });
    },
  },
  mounted() {
    API.addWatch("reconnect", this.reconnect);
  },
  beforeDestroy() {
    API.removeWatch("reconnect", this.reconnect);
  },
  components: {
    Layout,
    Drop,
    Notification,
  },
};
</script>

<style>
.sistem-btn-atualizar {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
