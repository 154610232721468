<template>
  <Crud
    ref="crud"
    :path="config.path"
    :novo="config.novo"
    :list="config.list"
    :form="config.form"
    :datatable="datatable"
    :formonly="formonly"
    @closeform="$emit('closeform')"
    @list="$emit('back', false)"
    @edit="
      model = $event;
      $emit('back', true);
    "
    @novo="
      model = {};
      $emit('back', true);
    "
    @create="create"
    @save="save"
    @errors="setErrors($event)"
    :showClone="false"
  >
    <template slot="form">
      <div class="row">
        <Texto
          ref="nome"
          labelini="Nome *"
          :valueini="model.nome"
          @change="model.nome = $event"
          :columnGroup="1"
          :errors="getErrors('nome')"
        />
      </div>

      <div class="row col-12">
        <input id="input-arquivo" ref="file" type="file" @change="uploadFile" class="w-100 mb-3">
        <label
          class="error d-block"
          v-for="(e, i) in getErrors('arquivo')"
          :key="i"
        >
          {{ e }}
        </label>
      </div>

      <a :href="urlArquivo(model.arquivo)">Baixar arquivo</a>

      <div class="row">
        <div class="col-12">
          <a :href="urlExemplo()">Baixar exemplo</a>
        </div>
      </div>
    </template>
  </Crud>
</template>

<script>
import Crud from "../crud/Crud.vue";
import Texto from "../crud/input/Text.vue";
import Money from "../crud/input/Money.vue";
export default {
  props: ["formonly"],
  components: {
    Crud,
    Texto,
    Money,
  },
  data() {
    return {
      config: {
        novo: "Nova",
        list: "Modelos",
        form: "Modelo",
        path: "/modelo-proposta-manual",
      },
      list: [],
      campos: ["nome"],
      model: {},
      download: [
        {
          label: "Cód.",
          objeto: "id",
          usar: true,
        },
        {
          label: "Nome",
          objeto: "nome",
          usar: true,
        },
        
      ],
      datatable: {
        campos: [
          {
            label: "Cód.",
            objeto: "id",
            head: {
              style: {
                "width": "80px",
              },
            },
          },
          {
            label: "Nome",
            objeto: "nome",
          },
        ],
      },
    };
  },
  methods: {
    urlArquivo(arquivo) {
      return loadRecurso("/storage/modelos/" + arquivo);
    },
    urlExemplo() {
      return loadRecurso("/models/modelo_proposta_manual.docx");
    },
    setErrors(errors) {
      this.errors = errors;
      this.$forceUpdate();
    },
    getErrors(campo) {
      if (this.errors) {
        return this.errors[campo];
      } else {
        return null;
      }
    },
    uploadFile() {
      this.model.file = this.$refs.file.files[0];
    },
    create() {
      const formData = new FormData();

      if (this.model.nome) {
        formData.append('nome', this.model.nome ? this.model.nome : null);
      }

      if (this.model.file != null) {
        formData.append('file', this.model.file ? this.model.file : null);
      }

      fileUpload(
        '/modelo-proposta-manual',
        'POST',
        formData,
        (e) => {
          this.model.file = null;
          this.model.nome = null;
          this.$refs.file.value = '';

          this.$refs.crud.sucessoCriar();
        },
        (e) => {
          this.$refs.crud.fail(e);
        },
        (e) => {
          this.$refs.crud.erro(e);
        }
      );
    },
    save() {
      const formData = new FormData();

      formData.append('_method', 'PUT');

      formData.append('nome', this.model.nome ? this.model.nome : null);

      if (this.model.file) {
        formData.append('file', this.model.file ? this.model.file : null);
      }

      fileUpload(
        '/modelo-proposta-manual/' + this.model.id,
        'POST',
        formData,
        (e) => {
          this.model.file = null;
          this.model.nome = null;
          this.model.arquivo = null;
          this.$refs.file.value = '';

          this.$refs.crud.sucessoCriar();
        },
        (e) => {
          this.$refs.crud.fail(e);
        },
        (e) => {
          this.$refs.crud.erro(e);
        }
      );
    },
  },
};
</script>