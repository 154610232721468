<template>
  <div>
    <table class="table table-striped datatable-table">
      <thead>
        <tr>
          <th style="width: calc(50% - 200px);">
            Pergunta
          </th>
          <th style="width: calc(50% - 200px);">
            Tarefa
          </th>
          <th style="width: 200px;">
          </th>
        </tr>
      </thead>
      <tbody class="table-body-scroll">
        <tr v-for="(pergunta, i) in perguntas" :key="i">
          <td>
            <div class="mt-1">
              {{ pergunta.nome }}
            </div>
          </td>
          <td>
            <div class="mt-1">
              {{ pergunta.tarefa }}
            </div>
          </td>
          <td>
            <div class="form-check form-check-inline abc-radio">
              <input
                :id="'resposta_sim_' + i"
                :name="'resposta_' + i"
                v-model="pergunta.resposta"
                value="S"
                type="radio"
                class="form-check-input"
                @click="change"
              >
              <label :for="'resposta_sim_' + i" class="form-check-label">Sim</label>
            </div>
            <div class="form-check form-check-inline abc-radio">
              <input
                :id="'resposta_nao_' + i"
                :name="'resposta_' + i"
                v-model="pergunta.resposta"
                value="N"
                class="form-check-input"
                type="radio"
                @click="change"
              >
              <label :for="'resposta_nao_' + i" class="form-check-label">Não</label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Money from "../crud/input/Money.vue";
import Texto from "../crud/input/Text.vue";
import Taxa from "../cadastro/Taxa.vue";
export default {
  props: ["processo", "cloneprocesso"],
  components: {
    Money,
    Texto,
    Taxa,
  },
  data() {
    return {
      perguntas: [],
    };
  },
  created() {
    this.buscarPerguntas(this.processo.id);
  },
  methods: {
    validacao() {
      for (const [i, pergunta] of this.perguntas.entries()) {
        if (!pergunta.resposta) {
          document.getElementById('resposta_sim_' + i).setCustomValidity('Responda a pergunta');
          document.getElementById('resposta_sim_' + i).reportValidity();
          document.getElementById('resposta_sim_' + i).focus();
          return true;
        } else {
          document.getElementById('resposta_sim_' + i).setCustomValidity('');
        }
      }

      return false;
    },
    buscarPerguntas(id = null) {
      if (this.cloneprocesso) {
        callAPI(
          '/buscar-perguntas',
          'GET',
          {
            processo_id: this.cloneprocesso
          },
          (data)=>{
            this.perguntas = [];

            for (let pergunta of data) {
              this.perguntas.push({
                id: pergunta.id,
                nome: pergunta.nome,
                resposta: pergunta.resposta,
                tarefa: pergunta.tarefa,
                tarefa_id: pergunta.tareaf_id
              });
            }

            this.change();
          }
        );
      } else {
        callAPI(
          '/buscar-perguntas',
          'GET',
          {
            processo_id: id
          },
          (data)=>{
            this.perguntas = data;
            this.change();
          }
        );
      }
    },
    change() {
      this.$emit("change", this.perguntas);
    },
  },
  watch: {
    'processo.id' () {
      this.buscarPerguntas(this.processo.id);
    }
  }
};
</script>